import React from 'react';
import { useTheme } from '@mui/material';

import { Typography, Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import MultiRoundScheduleSelect from '../../../../components/Form/MultiRoundScheduleSelect';

import { MultiRoundScheduleTypes } from '../../../../utils/types';
import { TypographySizes, SharedUICategories } from '../../../../utils/constants';
import { manageSessionActionsPropTypes } from '../../../../utils/proptypes';

const SessionActions = ({
    onAddRound,
    onAddTeamClick,
    onGenerateSchedule,
    onCreateSchedule,
    onPublish,
    onUnpublishSchedule,
    onScheduleChange,
    onDeleteSchedule,
    isCreatingManualSchedule,
    hasPostedScores,
    isLoadingSchedule = false,
    isScheduleCreated = false,
    isSchedulePublished = false,
    multiRoundScheduleType,
    teams = [],
    maxTeamsCount = null,
    hasSessionEnded = false,
}) => {
    const theme = useTheme();
    const { DISPLAY, SECONDARY, PRIMARY, PRIMARY_EXPANDED } = SharedUICategories;
    const { BY_ROUND } = MultiRoundScheduleTypes;
    const { MEDIUM_BOOK, SMALL_BOOK } = TypographySizes;
    const paperStyles = { px: 2.5, pb: 2.5, pt: 2, width: '300px' };
    const displayManualScheduleSelect = isSchedulePublished && isScheduleCreated;
    const isTeamActionsDisabled = teams.length === maxTeamsCount || hasSessionEnded;

    return (
        <>
            {!isScheduleCreated && !isCreatingManualSchedule && (
                <Box display="flex" justifyContent="center" gap={3}>
                    <Paper sx={{ ...paperStyles }}>
                        <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                            Auto generate schedule?
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK} mb={2.5}>
                            Select this option to automatically create a balanced schedule. Each team will play a set number of matches,
                            with an even mix of home and away games, based on your initial settings.
                        </Typography>
                        <Button onClick={onGenerateSchedule} category={PRIMARY_EXPANDED}>
                            Auto-Generate
                        </Button>
                    </Paper>
                    <Paper sx={{ ...paperStyles }}>
                        <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                            Manually create schedule?
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK} mb={2.5}>
                            Choose this option if you prefer to build your schedule from scratch. You&apos;ll control the number of rounds,
                            team matches per round, and which teams face off in each match.
                        </Typography>
                        <Button onClick={onCreateSchedule} category={PRIMARY_EXPANDED}>
                            Create Manually
                        </Button>
                    </Paper>
                </Box>
            )}
            {isScheduleCreated && (
                <Box
                    my={4}
                    pb={3}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ borderBottom: `4px solid ${theme.appColors.white}` }}
                >
                    <Box>
                        {displayManualScheduleSelect && (
                            <MultiRoundScheduleSelect
                                scheduleType={multiRoundScheduleType}
                                disabled={isLoadingSchedule || !isScheduleCreated}
                                onChange={onScheduleChange}
                            />
                        )}
                    </Box>
                    {multiRoundScheduleType === BY_ROUND && (
                        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                            <Button category={SECONDARY} disabled={isTeamActionsDisabled} onClick={onAddTeamClick}>
                                Add Existing Team
                            </Button>
                            <Button category={SECONDARY} onClick={onAddRound}>
                                Add Round
                            </Button>
                            <Button disabled={hasPostedScores} onClick={onDeleteSchedule} category={SECONDARY}>
                                Delete
                            </Button>
                            <Box sx={{ borderRight: `1px solid ${theme.appColors.lightGrey}`, height: '40px' }} />
                            {isSchedulePublished ? (
                                <Button disabled={hasPostedScores} onClick={onUnpublishSchedule} category={PRIMARY}>
                                    Unpublish
                                </Button>
                            ) : (
                                <Button onClick={onPublish} category={PRIMARY}>
                                    Publish
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

SessionActions.propTypes = {
    ...manageSessionActionsPropTypes,
};

export default SessionActions;
