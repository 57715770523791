import { createSlice } from '@reduxjs/toolkit';
import { getActiveTeams, getUserPreferences } from './services/userService';

const initialState = {
    memberTeams: {
        tennis: [],
        pickleball: []
    },
    isLoadingMemberTeams: false,
    isMemberTeamsLoaded: false,
    preferences: {},
    isLoadingPreferences: false,
    isPreferencesLoaded: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        [getActiveTeams.pending]: (state) => {
            state.isLoadingMemberTeams = true;
            state.isMemberTeamsLoaded = false;
        },
        [getActiveTeams.fulfilled]: (state, action) => {
            state.memberTeams = action.payload.memberTeams;
            state.isLoadingMemberTeams = false;
            state.isMemberTeamsLoaded = true;
        },
        [getActiveTeams.rejected]: (state) => {
            state.memberTeams = [];
            state.isLoadingMemberTeams = false;
            state.isMemberTeamsLoaded = false;
        },
        [getUserPreferences.pending]: (state) => {
            state.isLoadingPreferences = true;
            state.isPreferencesLoaded = false;
        },
        [getUserPreferences.fulfilled]: (state, action) => {
            state.preferences = action.payload;
            state.isLoadingMemberTeams = false;
            state.isMemberTeamsLoaded = true;
        },
        [getUserPreferences.rejected]: (state) => {
            state.preferences = {};
            state.isLoadingMemberTeams = false;
            state.isMemberTeamsLoaded = false;
        },
    },
});

export default userSlice.reducer;
