import React from 'react';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { tennisScoringTypes, durationTypes, MatchFormatTypes, pickleballScoringTypes, SportTypeIds } from '../../utils/types';
import { defaultGridSpacing } from '../../utils/constants';

const SessionMatchFormat = ({ matchFormat, control, errors, isDisabled, sportTypeId }) => {
    const { SINGLES, DOUBLES, BOTH } = MatchFormatTypes;
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;
    const scoringTypes = isTennisMatch ? tennisScoringTypes : pickleballScoringTypes;

    const Singles = () => {
        return (
            <>
                <Box mt={5} mb={3}>
                    <Divider>SINGLES</Divider>
                </Box>
                <Grid sx={{ my: 2 }} container spacing={defaultGridSpacing}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            name="singlesMatchCount"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    disabled={isDisabled}
                                    {...field}
                                    label="Singles matches per team match"
                                    fullWidth
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            name="singlesPointsPerMatch"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    disabled={isDisabled}
                                    label="Points Per Match Win"
                                    fullWidth
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ my: 2 }} container spacing={defaultGridSpacing}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            name="singlesScoringFormatTypeId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="singles-scoring-format-label">Scoring Format</InputLabel>
                                    <Select
                                        {...field}
                                        label="Scoring Format"
                                        error={!!errors.singlesScoringFormatTypeId}
                                        labelId="singles-scoring-format-label"
                                        sx={{ minWidth: 300 }}
                                    >
                                        {scoringTypes.map((scoringType) => (
                                            <MenuItem key={scoringType.id} value={scoringType.id}>
                                                {scoringType.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            name="singlesTimeAllowedTypeId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="singles-time-alloted-per-match-label">Time allotted per match</InputLabel>
                                    <Select
                                        {...field}
                                        label="Time allotted per match"
                                        error={!!errors.singlesTimeAllowedTypeId}
                                        labelId="singles-time-alloted-per-match-label"
                                        sx={{ minWidth: 300 }}
                                    >
                                        {durationTypes.map((duration) => (
                                            <MenuItem key={duration.id} value={duration.id}>
                                                {duration.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    const Doubles = () => (
        <>
            <Box mt={5} mb={3}>
                <Divider>DOUBLES</Divider>
            </Box>
            <Grid sx={{ my: 2 }} container spacing={defaultGridSpacing}>
                <Grid item xs={12} lg={6}>
                    <Controller
                        name="doublesMatchCount"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                disabled={isDisabled}
                                label="Doubles matches per team match"
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Controller
                        name="doublesPointsPerMatch"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                disabled={isDisabled}
                                label="Points per match win"
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid sx={{ my: 2 }} container spacing={defaultGridSpacing}>
                <Grid item xs={12} lg={6}>
                    <Controller
                        name="doublesScoringFormatTypeId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id="doubles-scoring-format-label">Scoring Format</InputLabel>
                                <Select
                                    {...field}
                                    label="Scoring Format"
                                    error={!!errors.doublesScoringFormatTypeId}
                                    labelId="doubles-scoring-format-label"
                                >
                                    {scoringTypes.map((scoringType) => (
                                        <MenuItem key={scoringType.id} value={scoringType.id}>
                                            {scoringType.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Controller
                        name="doublesTimeAllowedTypeId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id="doubles-time-allotted-per-match-label">Time allotted per match</InputLabel>
                                <Select
                                    {...field}
                                    label="Time allotted per match"
                                    error={!!errors.doublesTimeAllowedTypeId}
                                    labelId="doubles-time-alloted-per-match-label"
                                >
                                    {durationTypes.map((duration) => (
                                        <MenuItem key={duration.id} value={duration.id}>
                                            {duration.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );

    return (
        <div>
            {matchFormat === SINGLES && <Singles />}
            {matchFormat === DOUBLES && <Doubles />}
            {matchFormat === BOTH && (
                <div>
                    <Singles />
                    <Doubles />
                </div>
            )}
        </div>
    );
};

export default SessionMatchFormat;
