import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = (props) => {
    const { control, name, label, error, isRequired = false, helperText } = props;
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => (
                <FormControl fullWidth>
                    <TextField
                        {...field}
                        error={error}
                        autoComplete="on"
                        id="outlined-adornment-password"
                        helperText={helperText}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={label}
                    />
                </FormControl>
            )}
        />
    );
};

export default PasswordInput;
