import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { bootstrapDialogTitlePropTypes, modalBaseWithHeaderPropTypes, defaultModalPropTypes } from '../../utils/proptypes';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose && (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    ...bootstrapDialogTitlePropTypes
};

const ModalBaseWithHeader = (props) => {
    const { title, onClose, open, children } = props;

    return (
        <BootstrapDialog fullWidth onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <BootstrapDialogTitle align="center" id="customized-dialog-title" onClose={onClose}>
                {title}
            </BootstrapDialogTitle>
            {children}
        </BootstrapDialog>
    );
};

ModalBaseWithHeader.propTypes = {
    ...modalBaseWithHeaderPropTypes
};

ModalBaseWithHeader.defaultProps = {
    ...defaultModalPropTypes
};

export default ModalBaseWithHeader;
