import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { PlayerKeys, findPlayerInTeam } from '../../../utils/postScoreShared';

const PlayerSelect = ({ isDoubles, player1Id = '', player2Id, playerOptions = [], side, onPlayerChange, isSmallScreen }) => {
    const { PLAYER_1, PLAYER_2 } = PlayerKeys;

    const handleOnPlayerChange = (player) => (event) => {
        const selectedPlayer = findPlayerInTeam(event.target.value, playerOptions);
        onPlayerChange(selectedPlayer, side, player);
    };

    const renderOptions = () => {
        return playerOptions?.map((player) => (
            <MenuItem
                disabled={player.playerId === player1Id || player.playerId === player2Id}
                key={player.playerId}
                value={player.playerId}
            >
                {`${player.firstName} ${player.lastName}`}
            </MenuItem>
        ));
    };

    return (
        <Box>
            {isDoubles ? (
                <Box display={!isSmallScreen && 'flex'}>
                    <FormControl fullWidth>
                        <InputLabel id="player1-select-label">Player 1</InputLabel>
                        <Select
                            labelId="player1-select-label"
                            onChange={handleOnPlayerChange(PLAYER_1)}
                            value={player1Id}
                            id="grouped-select"
                            label="Player 1"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {renderOptions()}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={!isSmallScreen ? { ml: 1 } : { mt: 2 }}>
                        <InputLabel id="player2-select-label">Player 2</InputLabel>
                        <Select
                            labelId="player2-select-label"
                            onChange={handleOnPlayerChange(PLAYER_2)}
                            value={player2Id}
                            id="grouped-select"
                            label="Player 2"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {renderOptions()}
                        </Select>
                    </FormControl>
                </Box>
            ) : (
                <FormControl fullWidth>
                    <InputLabel id="player1-select-label">Player Name</InputLabel>
                    <Select
                        labelId="player1-select-label"
                        onChange={handleOnPlayerChange(PLAYER_1)}
                        value={player1Id}
                        id="grouped-select"
                        label="Player Name"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {renderOptions()}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

export default PlayerSelect;
