const localConfig = {
    // apiHost: ' https://api.universaltennis.com',
    // apiHost: ' https://marketing-api.universaltennis.com',
    // apiHost: 'https://test-api.universaltennis.com',
    // apiHost: 'https://qa-api.universaltennis.com',
    // apiHost: 'https://ci-api.universaltennis.com',
    // apiHost: 'https://rc-api.universaltennis.com',
    // apiHost: 'https://localhost:44317',
    // apiHost: 'https://utr-api-netcore.azurewebsites.net',
    // apiHost: 'https://ut-3-api-qa.azurewebsites.net',
    // apiHost: 'https://docker-test-api.universaltennis.com',
    // apiHost: ' https://test-api-alt.universaltennis.com',
    // apiHost: 'https://utr-api-central-us-01-canary.azurewebsites.net',
    // apiHost: 'https://utr-api-central-us-01.azurewebsites.net'
    // apiHost: 'https://ut-api.ngrok.io/',
};

export default localConfig;
