import React, { useEffect, useState } from 'react';
import { SortableTable } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import UtrIcon from '../../Icons/UtrIcon';

import { StyledTableRow } from '../../Styled';
import { ageDisplay, getAgeKeys, getAdjustedDate } from '../../../utils/display';
import { genderTypes, LeagueSanctionTypes } from '../../../utils/types';
import { leagueSearchResultsTablePropTypes, sessionTableRowProps } from '../../../utils/proptypes';
import usapLogo from '../../../assets/logos/usap-sanctioned-logo.png';

const headCells = [
    { id: 'name', label: 'Name', isSortingDisabled: true },
    { id: 'conference', label: 'Conference', isSortingDisabled: true },
    { id: 'location', label: 'Location', isSortingDisabled: true },
    { id: 'age', label: 'Age' },
    { id: 'rating', label: 'Rating' },
    { id: 'gender', label: 'Gender' },
    { id: 'startDate', label: 'Start' },
    { id: 'end', label: 'End', isSortingDisabled: true },
];

const LeaguesSearchResultsTable = ({ searchResults, isLoading, onSortChange, sortByProperty, sortOrder }) => {
    const { USAP } = LeagueSanctionTypes;
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        if (searchResults?.length) {
            const formattedResults = searchResults.map((result) => {
                const { name, conferenceName, location, minAge, maxAge, utrRangeMin, utrRangeMax, genderTypeId, sportTypeId, startDateUtc, endDateUtc, sanctionTypeId } = result;
                const genderType = genderTypes.find((gender) => gender.id === genderTypeId);
                const dateFormat = 'L';
                const ageKeys = getAgeKeys([minAge, maxAge]);
                const age = ageDisplay(ageKeys);
                const startDate = getAdjustedDate({ dateUtc: startDateUtc, format: dateFormat });
                const end = getAdjustedDate({ dateUtc: endDateUtc, format: dateFormat });
                const isUSAPSanctionedSession = sanctionTypeId === USAP;
                return {
                    ...result,
                    sessionName: name,
                    conference: conferenceName,
                    sportTypeId,
                    location: location?.placeName || location?.formattedAddress,
                    rating: `${utrRangeMin}-${utrRangeMax}`,
                    age,
                    gender: genderType.description,
                    startDate,
                    end,
                    isUSAPSanctionedSession
                };
            });
            setSessions(formattedResults);
        }
    }, [searchResults]);

    const handleOnOrderPropertyChange = (property) => {
        onSortChange(property);
    };

    const SessionTableRow = ({ rowData, labelId }) => {
        const { id, name, conference, location, age, rating, gender, startDate, end, isUSAPSanctionedSession } = rowData;
        const sessionUrl = `/leagues/session/${id}`;

        SessionTableRow.propTypes = {
            ...sessionTableRowProps
        };

        return (
            <StyledTableRow hover tabIndex={-1} key={id}>
                <TableCell component="th" id={labelId} scope="row" padding="normal">
                    <Box display="flex" alignItems="center">
                        <Link sx={{ fontSize: 16, mr: isUSAPSanctionedSession ? 2 : 0 }} target="_blank" rel="noopener" href={sessionUrl}>
                            {name}
                        </Link>
                        {isUSAPSanctionedSession && (
                            <Box>
                                <UtrIcon size={30} icon={usapLogo} alt="USAP sanctioned logo" />
                            </Box>
                        )}
                    </Box>

                </TableCell>
                <TableCell>{conference}</TableCell>
                <TableCell>{location}</TableCell>
                <TableCell>{age}</TableCell>
                <TableCell>{rating}</TableCell>
                <TableCell>{gender}</TableCell>
                <TableCell>{startDate}</TableCell>
                <TableCell>{end}</TableCell>
            </StyledTableRow>
        );
    };

    return (
        <>
            {!isLoading && !searchResults.length ? (
                <Box mt={10} display="flex" justifyContent="center">
                    <Typography variant="subHeading">No results found for your search</Typography>
                </Box>
            ) : (
                <SortableTable
                    headCells={headCells}
                    tableData={sessions}
                    sortOrder={sortOrder}
                    sortByProperty={sortByProperty}
                    rowComponent={SessionTableRow}
                    onOrderPropertyChange={handleOnOrderPropertyChange}
                />
            )}
        </>
    );
};

LeaguesSearchResultsTable.propTypes = {
    ...leagueSearchResultsTablePropTypes
};

export default LeaguesSearchResultsTable;
