/* eslint-disable */
import axios from 'axios';
import config from '../../config';

const MISSING_TEAM_TIME_SLOT_ERROR_CODE = 30057;

export const axiosBaseQuery =
    ({ baseUrl }) =>
    async ({ url, method, data }) => {
        const requestUrl = `${baseUrl}${url}`;
        const isUsingProxy = config.useProxy;
        const requestBaseUrl = isUsingProxy ? `/leagues-api${requestUrl}` : `${config.leaguesApiHost}${requestUrl}`;

        try {
            const result = await axios({ url: requestBaseUrl, method, data, withCredentials: !isUsingProxy });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError;
            const errorMessage =
                err?.response?.data?.MessageStatusCode === MISSING_TEAM_TIME_SLOT_ERROR_CODE
                    ? err?.response?.data?.Message
                    : err.response?.data?.DisplayMessage;

            return {
                error: { status: err.response?.status, message: errorMessage || null },
            };
        }
    };
