import { ResultTypes, PickleballScoringFormatTypes } from './types';
import { isNumericScore, ValidationErrors, hasScores, hasPartialScores, getPositionsForMatch, isPosition1Winner, isPosition2Winner } from './postScoreShared';

const { BEST_OF_THREE_GAMES, SINGLE_GAME, TIME_BASED_GAME } = PickleballScoringFormatTypes;
const { COMPLETED, COMPLETED_WITH_PARTIAL_SCORES, DEFAULTED, WITHDREW, RETIRED } = ResultTypes;

const GAME_ONE_ID = 1;

export const DefaultPickleballGames = [
    { id: 1, position1Score: '', position2Score: '' },
    { id: 2, position1Score: '', position2Score: '' },
    { id: 3, position1Score: '', position2Score: '' },
];

export const switchGameScores = (games) => {
    const switchedGames = games.map((game) => {
        const { id, position1Score, position2Score } = game;
        return {
            id,
            position1Score: position2Score,
            position2Score: position1Score,
        };
    });

    return switchedGames;
};

export const getValidatedValueForGame = (value) => {
    const maxScoreForGames = 99;
    let score = parseInt(value);

    score %= 100;

    if (score > maxScoreForGames) {
        score = maxScoreForGames;
    } else if (score < 0) {
        score = 0;
    }

    return Number.isNaN(score) ? '' : score;
};

export const validatePickleballGame = (game) => {
    const { position1Score, position2Score } = game;
    const minPointsAllowed = 0;
    const maxPointsAllowed = 99;
    const hasTieScore = position1Score === position2Score;
    const hasOutOfRangeScore =
        (position1Score < minPointsAllowed) || (position2Score < minPointsAllowed) ||
        (position1Score > maxPointsAllowed) || (position2Score > maxPointsAllowed);

    let error = null;

    if (hasOutOfRangeScore) {
        error = `${ValidationErrors.GAME_SCORE_INVALID} ${game.id}`;
    }

    if (hasTieScore && game.id === GAME_ONE_ID) {
        error = `${ValidationErrors.GAME_SCORE_NO_TIE} ${game.id}`;
    }

    return error;
};

export const isValidCompletedGameScore = (game, scoringFormatTypeId) => {
    const hasGameScores = hasScores(game);
    const hasPartialGameScores = hasPartialScores(game);
    let error = null;

    if (hasGameScores) {
        switch (scoringFormatTypeId) {
            case SINGLE_GAME:
            case TIME_BASED_GAME:
            case BEST_OF_THREE_GAMES: {
                const validationError = validatePickleballGame(game);
                if (validationError) {
                    error = validationError;
                }
                break;
            }
            default:
                break;
        }
    } else if (hasPartialGameScores) {
        error = `${ValidationErrors.GAME_SCORE_INCOMPLETE} ${game.id}`;
    }

    return { isValidCompletedGame: !error, error };
};

export const getWinnerForGames = (games, matchOutcome, scoringFormatTypeId) => {
    let validationError = null;
    let isWinnerPosition1 = false;
    let tieScoreGameId = null;
    let position1Wins = 0;
    let position2Wins = 0;
    let positionOneTotalPoints = 0;
    let positionTwoTotalPoints = 0;

    games.forEach((game) => {
        const hasGameScores = hasScores(game);
        const hasPartialGameScores = hasPartialScores(game);
        const isTieScore = hasScores && game?.position1Score === game?.position2Score;

        switch (matchOutcome) {
            case COMPLETED_WITH_PARTIAL_SCORES:
            case COMPLETED: {
                const { error } = isValidCompletedGameScore(game, scoringFormatTypeId);
                if (error) {
                    validationError = error;
                    break;
                }

                if (isPosition1Winner(game)) {
                    position1Wins += 1;
                } else if (isPosition2Winner(game)) {
                    position2Wins += 1;
                }

                positionOneTotalPoints += parseInt(game?.position1Score);
                positionTwoTotalPoints += parseInt(game?.position2Score);

                if (isTieScore && game.id !== GAME_ONE_ID) {
                    tieScoreGameId = game.id;
                }

                if (tieScoreGameId && tieScoreGameId < game.id) {
                    validationError = `${ValidationErrors.GAME_SCORE_NO_TIE} ${tieScoreGameId}`;
                }

                break;
            }
            case WITHDREW:
            case DEFAULTED:
                if (hasGameScores || hasPartialGameScores) {
                    validationError = ValidationErrors.NO_SCORES_FOR_OUTCOME;
                    break;
                }
                break;
            case RETIRED: {
                if (!hasGameScores && game.id === GAME_ONE_ID) {
                    validationError = ValidationErrors.GAME_SCORES_REQUIRED_FOR_OUTCOME;
                    break;
                }

                break;
            }
            default:
                break;
        }
    });

    if (validationError) {
        return { validationError };
    }

    if (matchOutcome === COMPLETED_WITH_PARTIAL_SCORES || matchOutcome === COMPLETED) {
        if (position1Wins > position2Wins) {
            isWinnerPosition1 = true;
        } else if (position1Wins < position2Wins) {
            isWinnerPosition1 = false;
        } else if (position1Wins === position2Wins && positionOneTotalPoints > positionTwoTotalPoints) {
            isWinnerPosition1 = true;
        } else if (position1Wins === position2Wins && positionOneTotalPoints < positionTwoTotalPoints) {
            isWinnerPosition1 = false;
        } else if (position1Wins === position2Wins) {
            validationError = ValidationErrors.NO_WINNER_DETERMINED;
        }
    }

    return { validationError, isWinnerPosition1 };
};

export const getExistingPickleballMatchGames = (match, isSide1Position1) => {
    const { position1, position2 } = getPositionsForMatch(match, isSide1Position1);
    const position1Result = position1?.sideResult;
    const position2Result = position2?.sideResult;

    const hasSet1Position1Score = isNumericScore(position1Result?.set1Score);
    const hasSet1Position2Score = isNumericScore(position2Result?.set1Score);

    const hasSet2Position1Score = isNumericScore(position1Result?.set2Score);
    const hasSet2Position2Score = isNumericScore(position2Result?.set2Score);

    const hasSet3Position1Score = isNumericScore(position1Result?.set3Score);
    const hasSet3Position2Score = isNumericScore(position2Result?.set3Score);

    return [
        {
            id: 1,
            position1Score: hasSet1Position1Score ? position1Result?.set1Score : '',
            position2Score: hasSet1Position2Score ? position2Result?.set1Score : '',
        },
        {
            id: 2,
            position1Score: hasSet2Position1Score ? position1Result?.set2Score : '',
            position2Score: hasSet2Position2Score ? position2Result?.set2Score : '',
        },
        {
            id: 3,
            position1Score: hasSet3Position1Score ? position1Result?.set3Score : '',
            position2Score: hasSet3Position2Score ? position2Result?.set3Score : '',
        },
    ];
};
