import * as React from 'react';

const ApprovedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.931 19.7606C39.6155 19.0783 40.7235 19.08 41.4059 19.7645C42.0262 20.3868 42.0812 21.3591 41.5718 22.0434L41.402 22.2394L26.0899 37.5036L17.899 28.9C17.2326 28.2 17.2598 27.0922 17.9599 26.4258C18.5962 25.82 19.5695 25.7874 20.2419 26.3124L20.434 26.4866L26.1572 32.494L38.931 19.7606Z" fill="#008DED" />
            <circle cx="28.5" cy="28" r="27.5" stroke="#008DED" />
        </svg>
    );
};
export default ApprovedIcon;
