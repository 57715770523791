import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Popup from '../../components/Popups/Popup';
import LoadingIndicator from '../../components/LoadingIndicator';
import SessionPageHeader from './SessionPageHeader';
import OverviewTab from './OverviewTab';
import PlayersTab from '../../components/PlayerTab';
import ScheduleTab from './ScheduleTab';
import StandingsTab from './StandingsTab';
import RegistrationDrawer from '../../components/RegistrationDrawer';
import { SessionTabs } from '../../utils/constants';
import { LeagueSessionTypes, SportTypeIds, LeagueSanctionTypes } from '../../utils/types';
import { mapSession } from '../../utils/sessions';
import { gtag } from '../../utils/analytics';
import { showPopup } from '../../redux/genericPopupSlice';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';
import {
    useGetSessionSummaryQuery,
    useRequestToCaptainMutation,
    useGetSessionStandingsQuery,
    useGetTeamSummaryQuery,
    useGetRegistrationFeesQuery,
    useGetSessionTeamsQuery,
    useRequestToJoinSessionMutation,
} from '../../api/leaguesApi';
import { sessionPagePropTypes } from '../../utils/proptypes';

const SessionPage = () => {
    const { OVERVIEW, PLAYERS, SCHEDULE, DRAW, STANDINGS } = SessionTabs;
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { sessionId } = useParams();
    const { MULTI_ROUND, TOURNAMENT } = LeagueSessionTypes;
    const [activeTab, setActiveTab] = useState(OVERVIEW);
    const [teamId, setTeamId] = useState(null);
    const [currentTeamData, setCurrentTeamData] = useState(null);
    const [showRegistrationDrawer, setShowRegistrationDrawer] = useState(false);
    const [showRegistrationSuccessPopup, setShowRegistrationSuccessPopup] = useState(false);
    const [showNotifyOrganizerSuccessPopup, setShowNotifyOrganizerSuccessPopup] = useState(false);
    const [showNotifyOrganizerFailurePopup, setShowNotifyOrganizerFailurePopup] = useState(false);
    const isUserLoggedIn = !!user;

    const { data: teamsAvailableData } = useGetSessionTeamsQuery(sessionId);
    const { data: teamData } = useGetTeamSummaryQuery(teamId, { skip: !teamId });
    const { data: registrationFees } = useGetRegistrationFeesQuery(teamId, { skip: !teamId });
    const { data: sessionData, isLoading, error: sessionSummaryError, refetch: refetchSummary } = useGetSessionSummaryQuery(sessionId);
    const {
        data: sessionStandings,
        isLoading: isLoadingStandings,
        error: standingsError,
        refetch: refetchStandings,
    } = useGetSessionStandingsQuery(sessionId);

    const [requestToCaptain, { isSuccess: isSuccessfulRequest }] = useRequestToCaptainMutation();
    const [requestToJoinSession] = useRequestToJoinSessionMutation();

    if (sessionSummaryError) {
        dispatch(showErrorPopup(sessionSummaryError));
    }

    if (standingsError) {
        dispatch(showErrorPopup(standingsError));
    }

    useEffect(() => {
        if (isSuccessfulRequest) {
            dispatch(
                showPopup({
                    isError: false,
                    title: 'Success',
                    message: 'Your Captain request has been submitted! You will be contacted by the conference organizer shortly.',
                })
            );
        }
    }, [isSuccessfulRequest]);

    useEffect(() => {
        if (teamData) {
            setCurrentTeamData(teamData);
        }
    }, [teamData]);

    useEffect(() => {
        if (sessionData?.session?.sportTypeId) {
            dispatch(setSportTypeId(sessionData?.session?.sportTypeId));
        }
    }, [sessionData]);

    const handleRemoveTeamData = () => {
        setTeamId(null);
        setCurrentTeamData(null);
    };

    const handleRestAPIState = () => {
        refetchSummary();
        refetchStandings();
    };

    const handleOnTabChanged = (_, newValue) => {
        setActiveTab(newValue);
    };

    const handleOnRequestToCaptain = async () => {
        try {
            await requestToCaptain(sessionId).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnTeamSelectionClick = (id) => {
        setTeamId(id);
        if (teamData?.teamId === id) {
            setCurrentTeamData(teamData);
        }
    };

    const handleOnNotifyOrgClick = async () => {
        try {
            await requestToJoinSession(sessionId).unwrap();
            setShowNotifyOrganizerSuccessPopup(true);
        } catch (apiError) {
            setShowNotifyOrganizerFailurePopup(true);
        }
    };

    const handleOnRegisterClick = () => {
        gtag('event', {
            category: 'Checkout Started',
            context: `Leagues Session Page - sessionId: ${sessionId}`,
        });
        setShowRegistrationDrawer(true);
    };

    const handleOnRegistrationSuccess = () => {
        const { sessionEntryFee, leagueFee } = registrationFees || '';
        const totalFee = sessionEntryFee + leagueFee;
        const isTennis = sessionData?.session?.sportTypeId === SportTypeIds.TENNIS;
        gtag('purchase', {
            category: 'Event Registration',
            context: `Team ${isTennis ? 'Tennis' : 'Pickleball'}`,
            value: totalFee,
        });
        setShowRegistrationDrawer(false);
        setShowRegistrationSuccessPopup(true);
    };

    const handleOnCloseRegistrationSuccessPopup = async () => {
        await setShowRegistrationDrawer(false);
        await setShowRegistrationSuccessPopup(false);
        await handleRestAPIState();
        await handleRemoveTeamData();
    };

    const handleOnCloseNotificationSuccessPopup = () => {
        setShowRegistrationDrawer(false);
        setShowNotifyOrganizerSuccessPopup(false);
    };

    const handleOnCloseNotificationFailurePopup = () => {
        setShowNotifyOrganizerFailurePopup(false);
    };

    const handleOnCloseDrawer = () => {
        handleRemoveTeamData();
        setShowRegistrationDrawer(false);
    };

    const handleOnFreeSessionRegistration = () => {
        setShowRegistrationDrawer(false);
        setShowRegistrationSuccessPopup(true);
    };

    const isMultiRoundSession = sessionData?.session?.sessionTypeId === MULTI_ROUND;

    const renderSessionContent = (data) => {
        const { session: currentSession, conferenceOrganizers } = data;
        const mappedSession = mapSession(currentSession);
        const {
            description,
            doublesMatchCount,
            registrationFee,
            registrationVerificationFee,
            sessionDates,
            sportTypeId,
            singlesMatchCount,
        } = mappedSession;
        const sessionDescription = description || 'No description was provided for this session.';
        const cost = `$${registrationFee} - $${registrationFee + registrationVerificationFee}`;
        const singlesFormat = `${singlesMatchCount} Singles`;
        const doublesFormat = `${doublesMatchCount} Doubles`;
        const isTournament = currentSession?.sessionTypeId === TOURNAMENT;
        const MATCH_TAB = isTournament ? DRAW : SCHEDULE;
        const teamStandings = [...sessionStandings.teamStandings];
        const noSidePadding = { px: 0 };
        let format;
        if (singlesMatchCount !== 0 && doublesMatchCount !== 0) {
            format = `${doublesFormat} / ${singlesFormat}`;
        } else if (doublesMatchCount === 0) {
            format = singlesFormat;
        } else if (singlesMatchCount === 0) {
            format = doublesFormat;
        }

        return (
            <TabContext value={activeTab}>
                <TabPanel sx={{ ...noSidePadding }} value={OVERVIEW}>
                    <OverviewTab
                        description={sessionDescription}
                        cost={cost}
                        sessionDates={sessionDates}
                        matchFormat={format}
                        sessionData={sessionData}
                        onRequestToCaptain={handleOnRequestToCaptain}
                        onRegisterButtonClick={handleOnRegisterClick}
                        isUserLoggedIn={isUserLoggedIn}
                        managers={conferenceOrganizers}
                    />
                </TabPanel>
                <TabPanel sx={{ ...noSidePadding }} value={MATCH_TAB}>
                    <ScheduleTab session={mappedSession} isLoadingSession={isLoading} />
                </TabPanel>
                <TabPanel sx={{ ...noSidePadding }} value={PLAYERS}>
                    <PlayersTab sessionId={sessionId} displaySinglesAndDoublesRecord />
                </TabPanel>
                <TabPanel sx={{ ...noSidePadding }} value={STANDINGS}>
                    <StandingsTab sportTypeId={sportTypeId} teamStandings={teamStandings} />
                </TabPanel>
            </TabContext>
        );
    };

    return (
        <Container disableGutters maxWidth={false}>
            {(isLoading || isLoadingStandings) && <LoadingIndicator />}
            {sessionData && sessionStandings && (
                <>
                    <SessionPageHeader
                        tab={activeTab}
                        sessionName={sessionData?.session?.name}
                        conferenceName={sessionData?.conference?.name}
                        onTabChange={handleOnTabChanged}
                        isMultiRoundSession={isMultiRoundSession}
                    />
                    <Divider />
                    <Container fixed>{renderSessionContent(sessionData)}</Container>
                </>
            )}
            {showRegistrationDrawer && (
                <RegistrationDrawer
                    isSessionPage
                    canSendSessionJoinRequest={sessionData?.sessionJoinRequest?.canSendSessionJoinRequest}
                    isOpen={showRegistrationDrawer}
                    isUsapSanctionedSession={sessionData?.session?.sanctionTypeId === LeagueSanctionTypes?.USAP}
                    onClose={handleOnCloseDrawer}
                    onNotifyOrgClick={handleOnNotifyOrgClick}
                    onRegistrationSuccess={handleOnRegistrationSuccess}
                    onTeamSelectionClick={handleOnTeamSelectionClick}
                    registrationFees={registrationFees}
                    teamData={currentTeamData}
                    teams={teamsAvailableData?.teams}
                    onFreeSessionRegistration={handleOnFreeSessionRegistration}
                />
            )}
            <Popup
                onAction={handleOnCloseRegistrationSuccessPopup}
                actionLabel="Ok"
                open={showRegistrationSuccessPopup}
                onClose={handleOnCloseRegistrationSuccessPopup}
                title="Congratulations"
                message={`${user?.firstName} ${user?.lastName} is now registered to play for ${currentTeamData?.teamName}`}
                isSuccessful
            />
            <Popup
                onAction={handleOnCloseNotificationSuccessPopup}
                actionLabel="Ok"
                open={showNotifyOrganizerSuccessPopup}
                onClose={handleOnCloseNotificationSuccessPopup}
                title="Organizers notified"
                message="Organizers have been sent a message that you’re interested in playing and are looking for a team to join."
                isSuccessful
            />
            <Popup
                onAction={handleOnCloseNotificationFailurePopup}
                actionLabel="Ok"
                open={showNotifyOrganizerFailurePopup}
                onClose={handleOnCloseNotificationFailurePopup}
                title="Error"
                message="There was a problem submitting your request to captain."
            />
        </Container>
    );
};

SessionPage.propTypes = {
    ...sessionPagePropTypes,
};

export default SessionPage;
