/* eslint-disable no-underscore-dangle */
const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
let _rox;

if (canUseDOM) {
    // eslint-disable-next-line global-require
    _rox = require('rox-browser');
} else {
    // eslint-disable-next-line no-eval
    _rox = eval(`require('rox-node')`);
    if (_rox) {
        // nice to have
        _rox.setContext({ server: true });
    }
}

export const Rox = _rox;

export const setContextProps = () => {
    Rox.setCustomStringProperty('email', (context) => {
        // debugger;
        // return context.user.email;
        return context.user && context.user.email;
    });
    Rox.setCustomNumberProperty('memberId', (context) => {
        // return context.user.id;
        return context.user && context.user.id;
    });
    Rox.setCustomBooleanProperty('isClubOrganizer', (context) => {
        // return context.user.isClubOrganizer;
        return context.user && context.user.isClubOrganizer;
    });
    Rox.setCustomBooleanProperty('isEnterpriseClubOrganizer', (context) => {
        // return context.user.isEnterpriseClubOrganizer;
        return context.user && context.user.isEnterpriseClubOrganizer;
    });
};

export const getFlagsContext = (user) => {
    const isValidUser = user && Object.keys(user).length > 0 && user.email && user.email.length > 0 && user.id > 0;

    if (isValidUser) {
        return {
            user: {
                email: user?.email?.toLowerCase(),
                memberId: user.id,
                isClubOrganizer: user.isClubOrganizer,
                isEnterpriseClubOrganizer: user.isEnterpriseClubOrganizer,
            },
        };
    }
    return {
        user: {},
    };
};
