import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useGate } from 'statsig-react';

import { featureGates } from '../utils/statsig';
import { statsigContextProviderPropTypes } from '../utils/proptypes';

const StatsigContext = React.createContext();

export function useStatsig() {
    return useContext(StatsigContext);
}

export function StatsigContextProvider({ children }) {
    const isAdultTeamPickleballEnabled = useGate(featureGates.ADULT_TEAM_PICKLEBALL).value;
    const isJuniorTeamPickleballEnabled = useGate(featureGates.JUNIOR_TEAM_PICKLEBALL).value;
    const isGlobalDropdownV2Enabled = useGate(featureGates.GLOBAL_DROPDOWN_V2).value;
    const [state, setState] = useState({});

    useEffect(() => {
        setState({
            featureGates: {
                adultTeamPickleball: isAdultTeamPickleballEnabled,
                juniorTeamPickleball: isJuniorTeamPickleballEnabled,
                globalDropdownV2: isGlobalDropdownV2Enabled,
            },
        });
    }, []);

    const memoizedState = useMemo(() => ({
        statsig: state,
    }), [state]);

    StatsigContextProvider.propTypes = {
        ...statsigContextProviderPropTypes
    };

    return (
        <StatsigContext.Provider value={memoizedState}>
            {children}
        </StatsigContext.Provider>
    );
}
