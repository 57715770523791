import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { PostScoreInput, PostScoreTieBreakInput, TiebreakerText } from '../../../../components/Styled';
import { ScorePositionKeys } from '../../../../utils/constants';
import { isSetTied } from '../../../../utils/postScore';

const UTSet = ({ onScoreChange, onFocus, set, currentSetId, scoringFormatTypeId, isDisabled }) => {
    const { id, position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const { POSITION_ONE_SCORE, POSITION_ONE_TB_SCORE, POSITION_TWO_SCORE, POSITION_TWO_TB_SCORE } = ScorePositionKeys;

    const [isTie, setIsTie] = useState(false);
    const [showTiebreakerInput, setShowTiebreakerInput] = useState(false);

    const showPosition1Tiebreaker = isTie && !showTiebreakerInput && !!position1Tiebreaker;
    const showPosition2Tiebreaker = isTie && !showTiebreakerInput && !!position2Tiebreaker;

    useEffect(() => {
        const isSetTie = isSetTied(set, scoringFormatTypeId);
        setIsTie(isSetTie);
        setShowTiebreakerInput(isSetTie && currentSetId === set.id);
    }, [set, currentSetId]);

    const handleOnScoreChange = (changedSet, positionKey, event, isTiebreaker = false) => {
        const { value } = event.target;
        onScoreChange(changedSet, positionKey, value, isTiebreaker);
    };

    const handleOnFocus = () => {
        onFocus(set);
    };

    return (
        <div>
            <Box mb={3} position="relative">
                <PostScoreInput
                    label={`S${id}`}
                    disabled={isDisabled}
                    setid={id}
                    type="number"
                    onChange={(event) => handleOnScoreChange(set, POSITION_TWO_SCORE, event)}
                    onFocus={handleOnFocus}
                    value={position2Score}
                    showTiebreaker={showTiebreakerInput}
                />
                {showPosition2Tiebreaker && <TiebreakerText>{position2Tiebreaker}</TiebreakerText>}
                {showTiebreakerInput && (
                    <PostScoreTieBreakInput
                        setid={id}
                        label="TB"
                        type="number"
                        onChange={(event) => handleOnScoreChange(set, POSITION_TWO_TB_SCORE, event, true)}
                        onFocus={handleOnFocus}
                        placeholder="0"
                        value={position2Tiebreaker}
                    />
                )}
            </Box>
            <Box mt={3} position="relative">
                <PostScoreInput
                    label={`S${id}`}
                    disabled={isDisabled}
                    setid={id}
                    type="number"
                    onChange={(event) => handleOnScoreChange(set, POSITION_ONE_SCORE, event)}
                    onFocus={handleOnFocus}
                    placeholder="0"
                    value={position1Score}
                    showTiebreaker={showTiebreakerInput}
                />
                {showPosition1Tiebreaker && <TiebreakerText>{position1Tiebreaker}</TiebreakerText>}
                {showTiebreakerInput && (
                    <PostScoreTieBreakInput
                        setid={id}
                        label="TB"
                        type="number"
                        onFocus={handleOnFocus}
                        onChange={(event) => handleOnScoreChange(set, POSITION_ONE_TB_SCORE, event, true)}
                        placeholder="0"
                        value={position1Tiebreaker}
                    />
                )}
            </Box>
        </div>
    );
};

export default UTSet;
