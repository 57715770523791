import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUser } from '../../api/leaguesApi';
import config from '../../config';

export const getAuthUser = createAsyncThunk('auth/getAuthUser', async () => {
    try {
        const res = await getUser();
        return res.data;
    } catch (err) {
        window.open(`${config.appHost}/login?redirect=${window.location.href}`, '_self');
        const customError = {
            name: 'Axios Auth Error',
            message: err.response.data?.message || err.response.statusText,
        };
        throw customError;
    }
});
