import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CompletedSessionModal from './CompletedSessionModal';
import LineupControls from './LineupControls';
import LocationTab from './LocationTab';
import LoadingIndicator from '../../components/LoadingIndicator';
import MatchEditModal from './MatchEditModal';
import PlayerAvailabilityResponse from '../../components/PlayerAvailabilityResponse';
import Popup from '../../components/Popups/Popup';
import PostTennisScoreModal from './PostTennisScoreModal';
import PostPickleballScoreModal from './PostPickleballScoreModal';
import TeamMatchScoreCard from '../../components/Cards/TeamMatchScoreCard';
import TeamMatchAlternatesCard from '../../components/Cards/TeamMatchAlternatesCard';
import TeamMatchSummary from './TeamMatchSummary';
import TeamTab from './TeamTab';
import SetLineupModal from '../../components/Modals/SetLineupModal';
import SuccessSnackbar from '../../components/SuccessSnackbar';

import { showErrorPopup } from '../../redux/errorPopupSlice';
import { showPopup } from '../../redux/genericPopupSlice';
import { SportTypeIds } from '../../utils/types';
import { LeagueTabs, LineupStatusType } from '../../utils/constants';
import {
    useGetLatestTeamMatchUpdateQuery,
    useGetMatchSummaryQuery,
    useUpdateTeamMatchSummaryMutation,
    usePostPlayerMatchResultMutation,
    useUpdatePlayerMatchResultMutation,
    useUpdatePlayerAvailabilityStatusMutation,
    useUpdatePlayerReminderStatusMutation,
    useClearTeamMatchMutation,
} from '../../api/leaguesApi';
import { useGetTeamMatchRosters } from '../../hooks/useGetTeamMatchRosters';
import { usePlayerMatches } from '../../hooks/usePlayerMatches';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';

const TeamMatchPage = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { LINES, LOCATION, AWAY_TEAM, HOME_TEAM } = LeagueTabs;
    const { teamMatchId } = useParams();
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);

    const [activeTab, setActiveTab] = useState(LINES);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPostScoreModal, setShowPostScoreModal] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState();
    const [teamScores, setTeamScores] = useState({ position1: 0, position2: 0 });
    const [pollingInterval, setPollingInterval] = useState(0);
    const [isPostingScore, setIsPostingScore] = useState(false);
    const [winningTeamId, setWinningTeamId] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [showCompletedSessionModal, setShowCompletedSessionModal] = useState(false);
    const [showLineupModal, setShowLineupModal] = useState(false);
    const [showClearMatchModal, setShowClearMatchModal] = useState(false);
    const [showAvailabilityReminderSnackbar, setShowAvailabilityReminderSnackbar] = useState(false);
    const [matchToBeCleared, setMatchToBeCleared] = useState(null);
    const [showLineupNotificationModal, setShowLineupNotificationModal] = useState(false);
    const [updatedLineupStatus, setUpdatedLineupStatus] = useState(null);
    const [hasMatchDatePassed, setHasMatchDatePassed] = useState(false);
    const [isAttemptingToPostScore, setIsAttemptingToPostScore] = useState(false);
    const [teamMatchDate, setTeamMatchDate] = useState(null);

    const { position1Team, position2Team, userAvailabilityData, userTeamMemberIds, isLoadingRosters, isUserOnBothTeams } =
        useGetTeamMatchRosters(teamMatchId);
    const { data, isFetching, error, refetch: refetchMatchSummary } = useGetMatchSummaryQuery(teamMatchId);
    const { data: latestResultData, error: latestResultError } = useGetLatestTeamMatchUpdateQuery(
        { teamMatchId, timestamp },
        { pollingInterval, skip: pollingInterval === 0 }
    );
    const {
        playerMatches,
        showCompletedSessionWarning,
        isLoadingPlayerMatches,
        lineupData,
        handleOnLineupStatusChange,
        lineupStatusConfirmation,
        handleOnCloseStatusConfirmation,
        canUserViewLineups,
    } = usePlayerMatches(teamMatchId);

    const [postPlayerMatchResult] = usePostPlayerMatchResultMutation();
    const [updatePlayerMatchResult] = useUpdatePlayerMatchResultMutation();
    const [updateTeamMatchSummary, { isLoading: isUpdatingSummary }] = useUpdateTeamMatchSummaryMutation();
    const [updatePlayerAvailability, { error: playerAvailabilityError }] = useUpdatePlayerAvailabilityStatusMutation();
    const [updatePlayerReminder] = useUpdatePlayerReminderStatusMutation();
    const [clearTeamMatch, { isLoading: isClearingTeamMatch }] = useClearTeamMatchMutation();

    if (error) {
        dispatch(showErrorPopup(error));
    }

    if (latestResultError) {
        dispatch(showErrorPopup(latestResultError));
    }

    const sortMatchesByLineupPosition = (matches) => {
        return [...matches].sort((a, b) => (a.lineupPosition > b.lineupPosition ? 1 : -1));
    };

    useEffect(() => {
        if (data) {
            if (data.position1Team?.isWinner) {
                setWinningTeamId(data.position1Team?.teamId);
            } else if (data.position2Team?.isWinner) {
                setWinningTeamId(data.position2Team?.teamId);
            } else {
                setWinningTeamId(null);
            }

            if (data?.resultsLastUpdatedMs) {
                setTimestamp(data?.resultsLastUpdatedMs);
            }

            if (data?.matchDate) {
                const isNowAfterMatchDate = moment.utc().isAfter(data.matchDate);
                setHasMatchDatePassed(isNowAfterMatchDate);
                setTeamMatchDate(data.matchDate);
            }

            const position1 = data?.position1Team?.playerMatchPoints;
            const position2 = data?.position2Team?.playerMatchPoints;
            setTeamScores({ position1, position2 });
            dispatch(setSportTypeId(data?.sportTypeId));
        }
    }, [data]);

    useEffect(() => {
        if (latestResultData) {
            const isUpdated = latestResultData?.update;
            if (isUpdated) {
                setPollingInterval(0);
                setIsPostingScore(false);
                refetchMatchSummary();
            }
        }
    }, [latestResultData]);

    const handleOnEditClicked = () => {
        setShowEditModal(true);
    };

    const handleOnCloseEditModal = () => {
        setShowEditModal(false);
    };

    const handleOnEditSubmit = async (teamMatch) => {
        setShowEditModal(false);

        try {
            await updateTeamMatchSummary({ teamMatchId: data?.teamMatchId, teamMatch }).unwrap();
            if (isAttemptingToPostScore) {
                setIsAttemptingToPostScore(false);
            }
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const showDuplicatePlayersError = () => {
        dispatch(showPopup({ title: 'Match Error', message: 'A player can only be listed once in an individual match' }));
    };

    const showInvalidPlayerCountError = () => {
        dispatch(showPopup({ title: 'Match Error', message: 'The amount of players set for this outcome is invalid.' }));
    };

    const handleOnCloseScoreModal = () => {
        setShowPostScoreModal(false);
    };

    const handleOnSaveMatchScore = async (playerMatchId, results, isUpdating) => {
        if (isPostingScore) {
            setPollingInterval(0);
        }

        setShowPostScoreModal(false);
        setIsPostingScore(true);

        try {
            if (isUpdating) {
                await updatePlayerMatchResult({ playerMatchId, playerMatch: results, teamMatchId }).unwrap();
            } else {
                await postPlayerMatchResult({ playerMatchId, playerMatch: results, teamMatchId }).unwrap();
            }
            setPollingInterval(3000);
        } catch (apiError) {
            setPollingInterval(0);
            dispatch(showErrorPopup(apiError));
            setIsPostingScore(false);
        }
    };

    const handleOnEditScoreClicked = (match, isDoubles) => {
        if (showCompletedSessionWarning) {
            setShowCompletedSessionModal(true);
            return;
        }

        if (!teamMatchDate) {
            setIsAttemptingToPostScore(true);
            setShowEditModal(true);
            return;
        }

        const singlesMatches = playerMatches?.singlePlayerMatches.length ? playerMatches?.singlePlayerMatches : [];
        const doublesMatches = playerMatches?.doublesPlayerMatches.length ? playerMatches?.doublesPlayerMatches : [];
        const allMatches = [...singlesMatches, ...doublesMatches];
        const matchToBeUpdated = allMatches?.find((playerMatch) => playerMatch.id === match.id);
        const selectedMatchToBeUpdated = {
            ...matchToBeUpdated,
            isDoubles,
            scoringFormatTypeId: isDoubles ? playerMatches?.doublesScoringFormatTypeId : playerMatches?.singlesScoringFormatTypeId,
        };

        setSelectedMatch(selectedMatchToBeUpdated);
        setShowPostScoreModal(true);
    };

    const handleOnCloseCompletedSessionModal = () => {
        setShowCompletedSessionModal(false);
    };

    const handleOnCloseSuccessSnackbar = () => {
        setShowAvailabilityReminderSnackbar(false);
    };

    const handleOnTabChange = (_, value) => {
        setActiveTab(value);
    };

    const handleOnPlayerAvailabilityChange = async ({ teamMemberId, availabilityStatus }) => {
        let teamMemberIds = [teamMemberId];

        if (isUserOnBothTeams) {
            teamMemberIds = userTeamMemberIds;
        }

        try {
            await updatePlayerAvailability({
                teamMemberIds,
                playerAvailabilityStatusId: availabilityStatus,
                teamMatchId: parseInt(teamMatchId),
            }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnShowLineupModal = () => {
        setShowLineupModal(true);
    };

    const handleOnCloseLineupModal = () => {
        setShowLineupModal(false);
    };

    const handleOnReminderChange = async ({ teamMemberIds, teamId }) => {
        try {
            await updatePlayerReminder({
                teamId: parseInt(teamId),
                teamMemberIds,
                teamMatchId: parseInt(teamMatchId),
            }).unwrap();
            setShowAvailabilityReminderSnackbar(true);
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnClearMatchClick = (match) => {
        setMatchToBeCleared(match);
        setShowClearMatchModal(true);
    };

    const handleOnConfirmClearMatch = async () => {
        if (isPostingScore) {
            setPollingInterval(0);
        }

        setShowClearMatchModal(false);
        setIsPostingScore(true);

        try {
            await clearTeamMatch({ teamMatchId: matchToBeCleared?.id }).unwrap();
            setPollingInterval(3000);
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
            setIsPostingScore(false);
            setPollingInterval(0);
        }
    };

    const handleOnLineupStatusClick = (status) => {
        setUpdatedLineupStatus(status);

        if (status === LineupStatusType.LOCKED && lineupData.statusId !== LineupStatusType.PUBLISHED) {
            setShowLineupNotificationModal(true);
        } else {
            handleOnLineupStatusChange({ status, isNotifyingPlayers: false });
        }
    };

    const handleOnLineupModalAction = (isNotifyingPlayers) => {
        setShowLineupNotificationModal(false);
        handleOnLineupStatusChange({ status: updatedLineupStatus, isNotifyingPlayers });
    };

    const handleOnCloseClearMatchModal = () => {
        setShowClearMatchModal(false);
    };

    const singleMatchesToDisplay = playerMatches?.singlePlayerMatches.length
        ? sortMatchesByLineupPosition([...(playerMatches?.singlePlayerMatches || {})])
        : [];
    const doubleMatchesToDisplay = playerMatches?.doublesPlayerMatches.length
        ? sortMatchesByLineupPosition([...(playerMatches?.doublesPlayerMatches || {})])
        : [];
    const showPosition2Alternates = !!playerMatches?.position2TeamAlternates?.alternatePlayers?.length;
    const showPosition1Alternates = !!playerMatches?.position1TeamAlternates?.alternatePlayers?.length;
    const tabs = [LINES, AWAY_TEAM, HOME_TEAM, LOCATION];

    return (
        <Container sx={{ backgroundColor: 'white' }} disableGutters maxWidth={false}>
            <TeamMatchSummary
                isLoading={(isFetching || isUpdatingSummary) && !isPostingScore}
                isUpdating={isPostingScore}
                winningTeamId={winningTeamId}
                summary={data}
                onEditClicked={handleOnEditClicked}
                scores={teamScores}
            />
            <TabContext value={activeTab}>
                <Container>
                    {userAvailabilityData && (
                        <Box my={2}>
                            <PlayerAvailabilityResponse
                                onAvailabilityChange={handleOnPlayerAvailabilityChange}
                                currentUserData={userAvailabilityData}
                                error={playerAvailabilityError}
                            />
                        </Box>
                    )}
                    {data && (
                        <Box>
                            <TabList onChange={handleOnTabChange} aria-label="team match tabs">
                                {tabs.map((tab) => (
                                    <Tab key={tab} label={tab} value={tab} />
                                ))}
                            </TabList>
                        </Box>
                    )}
                </Container>
                <Divider />
                <TabPanel sx={{ backgroundColor: theme.appColors.coolGrey }} value={LINES}>
                    <Box>
                        {isLoadingPlayerMatches || isClearingTeamMatch ? (
                            <LoadingIndicator />
                        ) : (
                            <Box>
                                {playerMatches && data && (
                                    <Container maxWidth="lg">
                                        <LineupControls
                                            lineupData={lineupData}
                                            canUserViewLineups={canUserViewLineups}
                                            hasMatchDatePassed={hasMatchDatePassed}
                                            onLineupStatusChange={handleOnLineupStatusClick}
                                            onShowLineupModal={handleOnShowLineupModal}
                                        />
                                        {(canUserViewLineups || hasMatchDatePassed) && (
                                            <>
                                                {!!singleMatchesToDisplay.length &&
                                                    singleMatchesToDisplay.map((match) => (
                                                        <Box key={match.id} my={3}>
                                                            <TeamMatchScoreCard
                                                                match={match}
                                                                position1Team={data?.position1Team}
                                                                position2Team={data?.position2Team}
                                                                isManaging={playerMatches?.canPostScores}
                                                                onEditClicked={handleOnEditScoreClicked}
                                                                onClearMatchClick={handleOnClearMatchClick}
                                                            />
                                                        </Box>
                                                    ))}
                                                {!!doubleMatchesToDisplay.length &&
                                                    doubleMatchesToDisplay.map((match) => (
                                                        <Box key={match.id} my={3}>
                                                            <TeamMatchScoreCard
                                                                isDoubles
                                                                match={match}
                                                                position1Team={data?.position1Team}
                                                                position2Team={data?.position2Team}
                                                                isManaging={playerMatches?.canPostScores}
                                                                onEditClicked={handleOnEditScoreClicked}
                                                                onClearMatchClick={handleOnClearMatchClick}
                                                            />
                                                        </Box>
                                                    ))}
                                                {showPosition2Alternates && (
                                                    <Box my={3}>
                                                        <TeamMatchAlternatesCard
                                                            positionAlternates={playerMatches?.position2TeamAlternates}
                                                        />
                                                    </Box>
                                                )}
                                                {showPosition1Alternates && (
                                                    <Box my={3}>
                                                        <TeamMatchAlternatesCard
                                                            positionAlternates={playerMatches?.position1TeamAlternates}
                                                        />
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Container>
                                )}
                            </Box>
                        )}
                    </Box>
                </TabPanel>
                <TabPanel sx={{ backgroundColor: theme.appColors.coolGrey }} value={AWAY_TEAM}>
                    <TeamTab
                        teamPlayers={position2Team?.teamMembers}
                        teamName={position2Team?.name}
                        isLoading={isLoadingRosters}
                        onAvailabilityChange={handleOnPlayerAvailabilityChange}
                        onReminderChange={({ teamMemberIds }) => handleOnReminderChange({ teamMemberIds, teamId: position2Team?.teamId })}
                        displaySuccessSnackbar={showAvailabilityReminderSnackbar}
                        onCloseSuccessSnackbar={handleOnCloseSuccessSnackbar}
                        isUserCaptain={position2Team?.requestingMember?.isCaptain}
                        isUserTeamMember={!!position2Team?.requestingMember?.teamMemberId}
                    />
                </TabPanel>
                <TabPanel sx={{ backgroundColor: theme.appColors.coolGrey }} value={HOME_TEAM}>
                    <TeamTab
                        teamPlayers={position1Team?.teamMembers}
                        teamName={position1Team?.name}
                        isLoading={isLoadingRosters}
                        onAvailabilityChange={handleOnPlayerAvailabilityChange}
                        onReminderChange={({ teamMemberIds }) => handleOnReminderChange({ teamMemberIds, teamId: position1Team?.teamId })}
                        displaySuccessSnackbar={showAvailabilityReminderSnackbar}
                        onCloseSuccessSnackbar={handleOnCloseSuccessSnackbar}
                        isUserCaptain={position1Team?.requestingMember?.isCaptain}
                        isUserTeamMember={!!position1Team?.requestingMember?.teamMemberId}
                    />
                </TabPanel>
                <TabPanel sx={{ backgroundColor: theme.appColors.coolGrey }} value={LOCATION}>
                    <LocationTab location={data?.location} description={data?.description} />
                </TabPanel>
            </TabContext>
            <MatchEditModal
                isOpen={showEditModal}
                onClose={handleOnCloseEditModal}
                onSubmit={handleOnEditSubmit}
                match={data}
                isAttemptingToPostScore={isAttemptingToPostScore}
            />
            {sportTypeId === SportTypeIds.TENNIS ? (
                <PostTennisScoreModal
                    position1Team={data?.position1Team}
                    position2Team={data?.position2Team}
                    position1Players={position1Team?.teamMembers}
                    position2Players={position2Team?.teamMembers}
                    singlesScoringFormatType={playerMatches?.singlesScoringFormatTypeId}
                    doublesScoringFormatType={playerMatches?.doublesScoringFormatTypeId}
                    selectedMatch={selectedMatch}
                    isOpen={showPostScoreModal}
                    onClose={handleOnCloseScoreModal}
                    onSaveMatchScore={handleOnSaveMatchScore}
                    onDuplicatePlayersInMatch={showDuplicatePlayersError}
                    onInvalidPlayerCount={showInvalidPlayerCountError}
                />
            ) : (
                <PostPickleballScoreModal
                    position1Team={data?.position1Team}
                    position2Team={data?.position2Team}
                    position1Players={position1Team?.teamMembers}
                    position2Players={position2Team?.teamMembers}
                    singlesScoringFormatType={playerMatches?.singlesScoringFormatTypeId}
                    doublesScoringFormatType={playerMatches?.doublesScoringFormatTypeId}
                    selectedMatch={selectedMatch}
                    isOpen={showPostScoreModal}
                    onClose={handleOnCloseScoreModal}
                    onSaveMatchScore={handleOnSaveMatchScore}
                    onDuplicatePlayersInMatch={showDuplicatePlayersError}
                    onInvalidPlayerCount={showInvalidPlayerCountError}
                />
            )}
            <CompletedSessionModal isOpen={showCompletedSessionModal} onClose={handleOnCloseCompletedSessionModal} />
            {showLineupModal && (
                <SetLineupModal
                    onClose={handleOnCloseLineupModal}
                    isOpen={showLineupModal}
                    position1Team={position1Team}
                    position2Team={position2Team}
                />
            )}
            <Popup
                onAction={handleOnConfirmClearMatch}
                actionLabel="Yes"
                buttonLabel="No"
                open={showClearMatchModal}
                onClose={handleOnCloseClearMatchModal}
                title="Are you sure you want to clear this line?"
                message="Clearing this line will remove all existing scores."
            />
            <Popup
                onAction={() => handleOnLineupModalAction(true)}
                actionLabel="Yes"
                buttonLabel="No"
                open={showLineupNotificationModal}
                onClose={() => handleOnLineupModalAction(false)}
                title="Notify players?"
                message="Would you like to notify players that the lines have been locked?"
            />
            <SuccessSnackbar
                isOpen={lineupStatusConfirmation.showConfirmation}
                onClose={handleOnCloseStatusConfirmation}
                message={lineupStatusConfirmation.message}
                title={lineupStatusConfirmation.title}
            />
        </Container>
    );
};

export default TeamMatchPage;
