import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Typography } from '@universal-tennis/ui-shared';

import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide ref={ref} {...props} direction="right" />;
});

const SuccessSnackbar = ({
    isOpen,
    onClose,
    title,
    message
}) => {
    const theme = useTheme();
    return (
        <Snackbar
            autoHideDuration={3000}
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
            key={message}
            ContentProps={{
                sx: {
                    background: theme.appColors.black
                }
            }}
        >
            <Box sx={{
                width: '390px',
                minHeight: '100px',
                padding: '15px',
                backgroundColor: theme.appColors.black,
                borderRadius: '4px'
            }}
            >
                <Typography mb={1} color={theme.appColors.white} category="secondary" size="small-medium-cap">{title}</Typography>
                <Typography color={theme.appColors.white} category="secondary" size="small-medium">{message}</Typography>
            </Box>
        </Snackbar>
    );
};

export default SuccessSnackbar;
