import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sideMenuOpen: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setSideMenu(state, action) {
            state.sideMenuOpen = action.payload;
        },
        toggleSideMenu(state) {
            state.sideMenuOpen = !state.sideMenuOpen;
        },
    },
});

export const { setSideMenu, toggleSideMenu } = uiSlice.actions;
export default uiSlice.reducer;
