import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StatsigProvider } from 'statsig-react';
import Box from '@mui/material/Box';

import segmentAnalytics from 'react-segment';
import { Footer } from '@universal-tennis/ui-shared';
import { StatsigContextProvider } from './hooks/StatsigContextProvider';

import { getAuthUser } from './redux/services/authServices';
import { getActiveTeams, getUserPreferences } from './redux/services/userService';

import Header from './components/Header';
import Routes from './routes';
import Menu from './components/Menu';
import Loading from './components/Loading';
import GenericPopup from './components/Popups/GenericPopup';
import ErrorPopup from './components/Popups/ErrorPopup';

import config from './config';

import { FooterExploreItems, FooterMoreItems, FooterProviderItems, FooterSupportItems, FooterSocialMedia } from './utils/constants';
import { Rox, setContextProps as RoxSetCustomProperties } from './utils/rollout';
import flagContainer from './flagContainer';

const App = () => {
    const { user, loading: isLoadingUser, isUserLoaded } = useSelector((state) => state.auth);
    const { isMemberTeamsLoaded, isLoadingMemberTeams, isLoadingPreferences, isPreferencesLoaded } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [roxInit, setRoxInit] = useState(false);
    segmentAnalytics.default.load(config.segmentKey);
    const env = process.env.CONFIG_SOURCE ||
    process.env.REACT_APP_CONFIG_SOURCE ||
    process.env.NODE_ENV ||
    'development';
    const footerPlacementMinPageHeight = '80vh';

    useEffect(() => {
        if (!user && !isLoadingUser && !isUserLoaded) {
            dispatch(getAuthUser());
        }
        if (!roxInit) {
            try {
                Rox.register('featureFlags', flagContainer);
                Rox.setup(config.roxKey, {}).then(() => {
                    RoxSetCustomProperties();
                    setRoxInit(true);
                });
            } catch (err) {
                console.log('client re-register of rox');
            }
        }

        if (!isMemberTeamsLoaded && !isLoadingMemberTeams) {
            dispatch(getActiveTeams());
        }

        if (!isPreferencesLoaded && !isLoadingPreferences) {
            dispatch(getUserPreferences());
        }
    }, []);

    const showExpandedNav = !!user;

    const handleOnSocialMediaClick = (id) => {
        const socialMediaItem = FooterSocialMedia.find((item) => item?.id === id);
        window.open(socialMediaItem.href, '_blank', 'noopener');
    };

    return (
        <StatsigProvider
            sdkKey={config.statsigKey}
            user={{
                statsigEnvironment: {
                    tier: env === 'production' ? env : 'development',
                },
                userID: user?.id || null,
                email: user?.email || null,
            }}
            waitForInitialization
        >
            <StatsigContextProvider>
                <div
                    id="myutr-app-wrapper"
                    className={showExpandedNav ? 'expanded-nav' : ''}
                >
                    {isLoadingUser ? (
                        <Loading />
                    ) : (
                        <>
                            <Box sx={{ minHeight: footerPlacementMinPageHeight }}>
                                <Header />
                                <Menu />
                                <Routes />
                            </Box>
                            <Footer
                                exploreItems={FooterExploreItems}
                                providerItems={FooterProviderItems}
                                moreItems={FooterMoreItems}
                                supportItems={FooterSupportItems}
                                onSocialMediaClick={handleOnSocialMediaClick}
                                sx={{ mt: '64px' }}
                            />
                            <GenericPopup />
                            <ErrorPopup />
                        </>
                    )}
                </div>
            </StatsigContextProvider>
        </StatsigProvider>

    );
};

App.propTypes = {};

export default App;
