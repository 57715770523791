import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';

import VerifiedIcon from '../../assets/icons/icon-verified-blue.svg';
import UtrIcon from '../Icons/UtrIcon';
import { SharedUICategories, TypographySizes } from '../../utils/constants';

const PlayerUtr = ({ utr, isVerified, isAppended = false, isUpdatedDesign = false }) => {
    const theme = useTheme();
    const displayComponent = isUpdatedDesign ? <Typography color={theme.appColors.black} category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_BOOK}>{utr}</Typography> : <MuiTypography variant={isAppended ? 'cardSubtitle' : 'body1'}>{utr}</MuiTypography>;

    return isVerified ? (
        <Box display="flex" alignItems="center" justifyContent={isUpdatedDesign ? 'center' : null}>
            {displayComponent}
            <UtrIcon icon={VerifiedIcon} size="16px" alt="verified" style={{ marginLeft: 5, marginBottom: 2 }} />
        </Box>
    ) : (
        displayComponent
    );
};

export default PlayerUtr;
