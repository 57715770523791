import React from 'react';
import config from '../../config';
import { formatUrlForCdn, updateSrcSetForCdn } from '../../utils/url';

const Img = ({ srcSet, defaultImage, src, alt, useRawSrcSet, onImgLoad, ...rest }) => {
    const { cdnUrl } = config;
    const useCdn = cdnUrl;
    let cdnSrcSet;
    let cdnSrc;
    if (useCdn) {
        if (srcSet) {
            cdnSrcSet = useRawSrcSet ? srcSet : updateSrcSetForCdn(srcSet, cdnUrl);
        }
        cdnSrc = formatUrlForCdn(cdnUrl, src);
    }

    const handleSetImg = (img) => {
        if (img && onImgLoad) {
            onImgLoad(img);
        }
    };

    return (
        <img
            {...rest}
            alt={alt}
            src={cdnSrc || ''}
            srcSet={cdnSrcSet || ''}
            ref={(el) => handleSetImg(el)}
            onError={(e) => {
                if (defaultImage) {
                    e.target.src = `${defaultImage}`;
                    e.target.srcset = `${defaultImage}`;
                }
            }}
        />
    );
};

export default Img;
