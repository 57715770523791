import { createSlice } from '@reduxjs/toolkit';
import { getBreadcrumbData } from './services/breadcrumbService';

const initialState = {
    data: {},
    loading: false,
};

const breadcrumbSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    extraReducers: {
        [getBreadcrumbData.pending]: (state) => {
            state.data = {};
            state.loading = true;
        },
        [getBreadcrumbData.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getBreadcrumbData.rejected]: (state) => {
            state.data = {};
            state.loading = false;
        },
    },
});

export default breadcrumbSlice.reducer;
