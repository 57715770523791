import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';

import Breadcrumbs from '../../components/Breadcrumbs';
import CreateConferenceForm from '../../components/Forms/CreateConferenceForm';
import MemberRoleManager from '../../components/MemberRoleManager';
import SessionsTab from './SessionsTab';
import Popup from '../../components/Popups/Popup';

import { LeagueTabs, LeagueRoles, BreadcrumbContexts } from '../../utils/constants';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import {
    useGetConferenceQuery,
    useAddOrganizerMutation,
    useRemoveOrganizerMutation,
    useRemoveSessionMutation,
} from '../../api/leaguesApi';
import { useGetConferenceSessions } from '../../hooks/useGetConferenceSessions';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';

const ManageConferencePage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { conferenceId } = useParams();
    const { CONFERENCE } = BreadcrumbContexts;
    const { CONFERENCE_ORGANIZER } = LeagueRoles;
    const { DETAILS, SESSIONS, ORGANIZERS } = LeagueTabs;
    const [activeTab, setActiveTab] = useState(SESSIONS);
    const [shouldUpdateBreadcrumb, setShouldUpdateBreadcrumb] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteError, setShowDeleteError] = useState(false);
    const [sessionIdToDelete, setSessionIdToDelete] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const unableToDeleteMessage =
        'There are players registered to teams. To delete the session, players must first be removed from the team.';
    const deleteSessionMessage =
        'Deleting this session will remove it from the conference. Any teams associated with the session will be removed too. Are you sure you want to delete?';

    const {
        data: conferenceDetails,
        isLoading: isLoadingConference,
        error: conferenceError,
        isFetchingConference,
    } = useGetConferenceQuery(parseInt(conferenceId));

    const { sessionResults, isLoadingSessions, totalPages } = useGetConferenceSessions(parseInt(conferenceId), pageNumber);

    const [addOrganizer, { isLoading: isAddingOrganizer }] = useAddOrganizerMutation();
    const [removeOrganizer, { isLoading: isRemovingOrganizer }] = useRemoveOrganizerMutation();
    const [removeSession, { isLoading: isRemovingSession }] = useRemoveSessionMutation();
    const isUpdatingOrganizers = isAddingOrganizer || isRemovingOrganizer || isFetchingConference;
    const isSessionsLoading = isLoadingSessions || isRemovingSession;

    if (conferenceError) {
        dispatch(showErrorPopup(conferenceError));
    }

    useEffect(() => {
        if (location?.state?.activeTab) {
            setActiveTab(location?.state?.activeTab);
        }
    }, []);

    useEffect(() => {
        if (conferenceDetails) {
            dispatch(setSportTypeId(conferenceDetails?.sportTypeId));
        }
    }, [conferenceDetails]);

    const handleTabChange = (_, value) => {
        setActiveTab(value);
    };

    const handleOnBreadcrumbUpdated = () => {
        setShouldUpdateBreadcrumb(false);
    };

    const handleOnConferenceUpdated = () => {
        setShouldUpdateBreadcrumb(true);
        setActiveTab(SESSIONS);
    };

    const handleOnRemoveConferenceOrganizer = async (removedOrganizer) => {
        try {
            await removeOrganizer({ conferenceId, organizerId: removedOrganizer.id });
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnAddConferenceOrganizer = async (addedOrganizer) => {
        try {
            await addOrganizer({ conferenceId, organizer: { memberId: addedOrganizer.memberId } }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnRemoveSession = async (sessionId, hasRegisteredPlayers) => {
        if (hasRegisteredPlayers) {
            setShowDeleteError(true);
        } else {
            setSessionIdToDelete(sessionId);
            setShowDeleteConfirmation(true);
        }
    };

    const handleOnDeleteConfirmed = async () => {
        setShowDeleteConfirmation(false);

        try {
            await removeSession({ sessionId: sessionIdToDelete, conferenceId }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnCloseDeletePopup = () => {
        setShowDeleteConfirmation(false);
    };

    const handleOnCloseDeleteErrorPopup = () => {
        setShowDeleteError(false);
    };

    const handleOnPageChange = (page) => {
        setPageNumber(page);
    };

    const tabs = [SESSIONS, ORGANIZERS, DETAILS];
    return (
        <Container fixed>
            <Box mt={5}>
                <Breadcrumbs
                    isAdmin
                    context={CONFERENCE}
                    shouldUpdateBreadcrumb={shouldUpdateBreadcrumb}
                    onBreadcrumbUpdated={handleOnBreadcrumbUpdated}
                    contextId={conferenceId}
                />
                <Typography my={1} variant="h4">
                    Manage Conference
                </Typography>
                <Divider />
                <TabContext value={activeTab}>
                    <Box>
                        <TabList onChange={handleTabChange} aria-label="conference tabs">
                            {tabs.map((tab) => (
                                <Tab key={tab} label={tab} value={tab} />
                            ))}
                        </TabList>
                    </Box>
                    <Divider />
                    <TabPanel key={SESSIONS} value={SESSIONS} sx={{ p: 0 }}>
                        <SessionsTab
                            sessions={sessionResults}
                            conferenceId={conferenceId}
                            isLoading={isSessionsLoading}
                            onRemoveSession={handleOnRemoveSession}
                            onPageChange={handleOnPageChange}
                            pageCount={totalPages}
                            currentPage={pageNumber}
                        />
                    </TabPanel>
                    <TabPanel key={ORGANIZERS} value={ORGANIZERS} sx={{ p: 0 }}>
                        <MemberRoleManager
                            memberRole={CONFERENCE_ORGANIZER}
                            isLoading={isLoadingConference}
                            members={conferenceDetails?.conferenceOrganizers}
                            isUpdatingRole={isUpdatingOrganizers}
                            onAddMemberRole={handleOnAddConferenceOrganizer}
                            onRemoveMemberRole={handleOnRemoveConferenceOrganizer}
                        />
                    </TabPanel>
                    <TabPanel key={DETAILS} value={DETAILS} sx={{ p: 0 }}>
                        <CreateConferenceForm
                            isManaging
                            conferenceDetails={conferenceDetails}
                            isLoadingConference={isLoadingConference}
                            onConferenceUpdated={handleOnConferenceUpdated}
                        />
                    </TabPanel>
                </TabContext>
                <Popup
                    open={showDeleteConfirmation}
                    title="Delete Session"
                    message={deleteSessionMessage}
                    buttonLabel="Cancel"
                    onAction={handleOnDeleteConfirmed}
                    actionLabel="Delete"
                    onClose={handleOnCloseDeletePopup}
                />
                <Popup
                    open={showDeleteError}
                    title="Unable to Delete "
                    message={unableToDeleteMessage}
                    actionLabel="Okay"
                    onClose={handleOnCloseDeleteErrorPopup}
                    onAction={handleOnCloseDeleteErrorPopup}
                />
            </Box>
        </Container>
    );
};

export default ManageConferencePage;
