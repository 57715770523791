import React from 'react';

import UtrIcon from '../UtrIcon';
import ChatIcon from '../../../assets/icons/icon-chat-blue.svg';
import { messageIconPropTypes, defaultMessageIconPropTypes } from '../../../utils/proptypes';

import { messageUser } from '../../../utils/helpers';

const MessageIcon = (props) => {
    const { memberId, displayName, style, size = '24px' } = props;

    const handleClick = () => {
        messageUser(memberId, displayName);
    };

    if (memberId && displayName) {
        return <UtrIcon size={size} icon={ChatIcon} alt={`Message ${displayName}`} style={style} clickable onClick={handleClick} />;
    }
    return null;
};

MessageIcon.propTypes = {
    ...messageIconPropTypes
};

MessageIcon.defaultProps = {
    ...defaultMessageIconPropTypes
};

export default MessageIcon;
