import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { getLeaguesApiAxiosInstance, getApiAxiosInstance } from '../utils/network';
import { leaguesApi } from '../api/leaguesApi';
import rootReducer from './rootReducer';

const apiInstances = { main: getApiAxiosInstance(), leagues: getLeaguesApiAxiosInstance() };

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['breadcrumbs', 'conferenceDetails'],
    blacklist: [leaguesApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: apiInstances,
            },
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(leaguesApi.middleware),
});
export const persistor = persistStore(store);

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}
