import React, { useEffect, useRef, useState } from 'react';
import { CancelToken, isCancel } from 'axios';

import { Autocomplete, TextField, Grid, Typography } from '@mui/material';

import BackgroundImage from '../../BackgroundImage';
import { getApiAxiosInstance } from '../../../utils/network';
import { playerSearchPropTypes, defaultPlayerSearchPropTypes } from '../../../utils/proptypes';
import { useDebounce } from '../../../hooks/useDebounce';

const PlayerSearch = ({ isProPlayerSearch = false, searchMembersOnly = false, showPickleballContent = false, showTennisContent = false, onPlayerSelected, selectedPlayers = [], label, isRequired = false }) => {
    const cancelRef = useRef(null);
    const resultLimit = 10;
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const searchQuery = useDebounce(query, 500);
    const axiosInstance = getApiAxiosInstance();

    const clearState = () => {
        setIsLoading(false);
        setSearchResults([]);
        setQuery('');
    };

    useEffect(() => {
        const getSearchResults = async () => {
            setIsLoading(true);
            const params = new URLSearchParams();
            params.append('query', searchQuery);
            params.append('top', resultLimit);
            params.append('isDemoPlayer', false);
            params.append('showPickleballContent', false);
            params.append('showTennisContent', false);

            if (isProPlayerSearch) {
                params.append('filter', 'isPro:true');
            }

            if (searchMembersOnly) {
                params.append('exists', 'memberId');
            }

            if (showPickleballContent) {
                params.delete('showPickleballContent', false);
            }

            if (showTennisContent) {
                params.delete('showTennisContent', false);
            }

            const cancel = cancelRef.current;
            if (cancel) {
                cancel();
            }

            const response = await axiosInstance
                .get('v2/search/players', {
                    cancelToken: new CancelToken((c) => {
                        cancelRef.current = c;
                    }),
                    params,
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        setIsLoading(false);
                    }
                });

            if (response?.data) {
                const { hits } = response.data;
                const filteredHits = hits.filter((hit) => {
                    return selectedPlayers.every((player) => player.memberId !== hit.source.memberId);
                });
                const results = filteredHits;
                setSearchResults(results);
                setIsLoading(false);
            }
        };

        if (searchQuery.length > 0) {
            getSearchResults();
        }
    }, [searchQuery]);

    const updateStateForQuery = (queryString) => {
        if (queryString?.length > 0) {
            setQuery(queryString);
            setSearchResults([]);
        } else {
            setQuery('');
            setSearchResults([]);
        }
    };

    const handleOnInputChange = (value) => {
        updateStateForQuery(value);
    };

    const handleOnInputPaste = (event) => {
        const { target } = event;
        setTimeout(() => {
            const queryString = target.value.trim();
            updateStateForQuery(queryString);
        }, 200);
    };

    const handleOnChange = (player) => {
        onPlayerSelected(player);
        clearState();
    };

    return (
        <Autocomplete
            id="player-search"
            filterOptions={(x) => x}
            onPaste={handleOnInputPaste}
            options={searchResults}
            loading={isLoading}
            getOptionLabel={(option) => (option?.source?.displayName ? option.source.displayName : '')}
            isOptionEqualToValue={(option, value) => option?.source?.id === value.source.id}
            onInputChange={(_, value, reason) => {
                if ((value || value === '') && reason === 'input') {
                    handleOnInputChange(value);
                }
            }}
            onChange={(_, newValue) => {
                if (newValue) {
                    handleOnChange(newValue.source);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    required={isRequired}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                />
            )}
            renderOption={(optionProps, option) => {
                const { playerProfileImages, displayName, location } = option?.source || {};
                return (
                    <li {...optionProps} key={option?.source.id}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <BackgroundImage
                                    baseUrl="/v1/player/"
                                    defaultImage="/img/v2/icons/icon-utr-logo.svg"
                                    images={playerProfileImages}
                                    imageType="profile"
                                    extraCls="playerThumbnailImage"
                                />
                            </Grid>
                            <Grid item>
                                <Typography>{displayName}</Typography>
                                <Typography sx={{ color: 'gray', size: 12 }}>{location?.display}</Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

PlayerSearch.propTypes = {
    ...playerSearchPropTypes
};

PlayerSearch.defaultProps = {
    ...defaultPlayerSearchPropTypes
};

export default PlayerSearch;
