import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { SquareAvatarWithName, AvatarWithName, Typography, SortableTable, EditIcon, DeleteIcon } from '@universal-tennis/ui-shared';

import { Box } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import MessageIcon from '../../Icons/MessageIcon';
import { getComparator, getPowerIndex } from '../../../utils/helpers';
import { SortType, TypographySizes, SharedUICategories } from '../../../utils/constants';
import { teamsTablePropTypes, teamsTableRowProps } from '../../../utils/proptypes';

const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'location', label: 'Location' },
    { id: 'captainName', label: 'Captain' },
    { id: 'teamPowerIndexSingles', label: 'TPI S', isSortingDisabled: true },
    { id: 'teamPowerIndexDoubles', label: 'TPI D', isSortingDisabled: true },
    { id: 'numberOfPlayers', label: '# players' },
    { id: 'actions' },
];

const TeamsTable = ({ sessionTeams = [], onManageClicked, onRemoveClicked, hasSessionStarted = false }) => {
    const theme = useTheme();
    const [teams, setTeams] = useState([]);
    const { MEDIUM_MEDIUM, SMALL_MEDIUM, SMALL_BOOK } = TypographySizes;
    const { SECONDARY } = SharedUICategories;
    const [sortOrder, setSortOrder] = useState(SortType.ASC);
    const [orderProperty, setOrderProperty] = useState('name');
    const tableData = teams?.sort(getComparator(sortOrder, orderProperty));

    const handleRequestSort = (property) => {
        const isAscending = orderProperty === property && sortOrder === SortType.ASC;
        setSortOrder(isAscending ? SortType.DESC : SortType.ASC);
        setOrderProperty(property);
    };

    useEffect(() => {
        const formattedTeams = sessionTeams.map((team) => {
            const { captains } = team;
            return {
                ...team,
                captainName: `${captains[0]?.firstName} ${captains[0]?.lastName}`,
            };
        });
        setTeams(formattedTeams);
    }, [sessionTeams]);

    const TeamTableRow = ({ rowData, labelId }) => {
        const { id, name, homeCourtLocation, teamMemberCount, captains, captainName, teamPowerIndex } = rowData;
        const { memberId } = captains?.[0] || [];
        const isRemoveDisabled = teamMemberCount > 0 || hasSessionStarted;
        const teamUrl = `/teams/${id}`;
        const tableCellStyles = { borderBottom: 'none', backgroundColor: theme.appColors.white };

        const singlesPowerRating = getPowerIndex(teamPowerIndex?.singlesRating, teamPowerIndex?.isSinglesEstimated);
        const doublesPowerRating = getPowerIndex(teamPowerIndex?.doublesRating, teamPowerIndex?.isDoublesEstimated);

        const handleOnManageClicked = () => {
            onManageClicked(id);
        };

        const handleOnRemoveClicked = () => {
            const teamData = { id, teamMemberCount, name };
            onRemoveClicked(teamData);
        };

        TeamTableRow.propTypes = {
            ...teamsTableRowProps,
        };

        return (
            <TableRow tabIndex={-1} key={id}>
                <TableCell sx={{ ...tableCellStyles }} component="th" id={labelId} scope="row" padding="normal" width="30%">
                    <SquareAvatarWithName
                        fontSize={SMALL_MEDIUM}
                        name={name}
                        href={teamUrl}
                        avatarWidth="36px"
                        avatarHeight="36px"
                        avatarFontSize={MEDIUM_MEDIUM}
                        maxLinkWidth="200px"
                    />
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="25%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {homeCourtLocation?.formattedAddress}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="25%">
                    <AvatarWithName avatarWidth="36px" avatarHeight="36px" playerName={captainName} />
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {singlesPowerRating}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {doublesPowerRating}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {teamMemberCount}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="5%">
                    <Box display="flex">
                        <IconButton onClick={handleOnManageClicked} aria-label="Manage team details">
                            <EditIcon color={theme.appColors.interactionBlue} size={18} />
                        </IconButton>
                        <IconButton title="Message Captain" aria-label="message captain">
                            <MessageIcon size={18} memberId={memberId} displayName={captainName} />
                        </IconButton>
                        <IconButton disabled={isRemoveDisabled} onClick={handleOnRemoveClicked}>
                            <DeleteIcon color={theme.appColors.interactionBlue} size={18} />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <SortableTable
            headCells={headCells}
            tableData={tableData}
            sortOrder={sortOrder}
            sortByProperty={orderProperty}
            rowComponent={TeamTableRow}
            onOrderPropertyChange={handleRequestSort}
        />
    );
};

TeamsTable.propTypes = {
    ...teamsTablePropTypes,
};

export default TeamsTable;
