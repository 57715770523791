import React from 'react';
import { Typography } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';

import { SharedUICategories, TypographySizes } from '../../utils/constants';

import { tennisScoringTypes, pickleballScoringTypes, durationTypes, SportTypeIds, getTypeForId } from '../../utils/types';

import { matchFormatPropTypes } from '../../utils/proptypes';

const MatchFormat = ({
    matchCount = '',
    pointsPerMatch = '',
    scoringFormatTypeId = '',
    timeAllowedTypeId = '',
    isDoubles = false,
    sportTypeId = '',
}) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { SMALL_MEDIUM, SMALL_BOOK } = TypographySizes;
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;
    const scoringTypes = isTennisMatch ? tennisScoringTypes : pickleballScoringTypes;
    const scoringFormatDescription = getTypeForId(scoringFormatTypeId, scoringTypes)?.description;
    const timeAllottedPerMatch = getTypeForId(timeAllowedTypeId, durationTypes)?.description;

    const formatType = isDoubles ? 'Doubles' : 'Singles';

    return (
        <>
            <Box mb={1.5} display="flex" justifyContent="space-between">
                <Box flex={3}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        {formatType} matches per team
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {matchCount}
                    </Typography>
                </Box>
                <Box flex={2}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Points per match win
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {pointsPerMatch}
                    </Typography>
                </Box>
            </Box>
            <Box mb={1.5} display="flex" justifyContent="space-between">
                <Box flex={3}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Scoring Format
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {scoringFormatDescription}
                    </Typography>
                </Box>
                <Box flex={2}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Time allotted per match
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {timeAllottedPerMatch}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

MatchFormat.propTypes = {
    ...matchFormatPropTypes,
};

export default MatchFormat;
