import { useEffect, useState } from 'react';

const MOBILE_SIZE = 768;
const DESKTOP_SIZE = 1200;

const useIsMobile = () => {
    const [width, setWidth] = useState();
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width <= MOBILE_SIZE;
};

export const useIsTablet = () => {
    const [width, setWidth] = useState();
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width >= MOBILE_SIZE && width <= DESKTOP_SIZE;
};

export default useIsMobile;
