import React from 'react';

import { Grid, Typography, Box, Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BackgroundImage from '../../BackgroundImage';

const PlayerSearchResultCard = ({ player, onRemovePlayer, showRemoveButton, ...rest }) => {
    const { displayName, location, playerProfileImages } = player;

    const handleOnRemovePlayer = () => {
        onRemovePlayer(player);
    };

    return (
        <Paper {...rest} sx={{ px: 2, py: 1, mb: 1 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item display={{ xs: 'none', md: 'block' }}>
                    <BackgroundImage
                        baseUrl="/v1/player/"
                        defaultImage="/img/v2/icons/icon-utr-logo.svg"
                        images={playerProfileImages}
                        imageType="profile"
                        extraCls="playerThumbnailImage"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1">{displayName}</Typography>
                    <Typography variant="cardSubtitle">{location?.display}</Typography>
                </Grid>
                {showRemoveButton && (
                    <Grid item ml="auto">
                        <Box className="close-icon" onClick={handleOnRemovePlayer}>
                            <CloseRoundedIcon />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default PlayerSearchResultCard;
