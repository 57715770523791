import React from 'react';

import Box from '@mui/material/Box';

import ScheduleMatchCard from '../Cards/ScheduleMatchCard';

import { multiWeekByDatePropTypes } from '../../utils/proptypes';

const MultiWeekByDate = ({ scheduleByDate, onEditMatch, isLeagueDeskSchedule }) => {
    const { teamMatches } = scheduleByDate || [];

    const handleOnEditClick = (match) => {
        onEditMatch(match);
    };

    return (
        <Box>
            {teamMatches?.map((match) => {
                return (
                    <ScheduleMatchCard
                        key={match.teamMatchId}
                        match={match}
                        isLeagueDeskSchedule={isLeagueDeskSchedule}
                        isMultiWeekByDate
                        onEditMatch={handleOnEditClick}
                    />
                );
            })}
        </Box>
    );
};

MultiWeekByDate.propTypes = {
    ...multiWeekByDatePropTypes,
};

export default MultiWeekByDate;
