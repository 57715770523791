import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showErrorPopup } from '../redux/errorPopupSlice';
import { useGetConferenceSessionsQuery } from '../api/leaguesApi';

export const useGetConferenceSessions = (conferenceId, pageNumber) => {
    const pageSize = 25;
    const dispatch = useDispatch();
    const [sessionResults, setSessionResults] = useState([]);
    const [totalPages, setTotalPages] = useState(null);

    const {
        data: sessions,
        isLoading: isLoadingSessions,
        error: sessionError,
    } = useGetConferenceSessionsQuery({ conferenceId, pageNumber, pageSize });

    if (sessionError) {
        dispatch(showErrorPopup(sessionError));
    }

    useEffect(() => {
        if (sessions) {
            const { results, totalCount } = sessions || {};
            const pages = Math.ceil(totalCount / pageSize);
            setTotalPages(pages);
            setSessionResults(results);
        }
    }, [sessions]);

    return { sessionResults, totalPages, isLoadingSessions };
};
