import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

import { playerWithdrawTypes } from '../../utils/types';
import { playerWithdrawModalPropTypes, defaultModalPropTypes } from '../../utils/proptypes';
import ModalBaseWithHeader from './ModalBaseWithHeader';
import Select from '../Form/Select';

const PlayerWithdrawModal = (props) => {
    const { onClose, open, onSubmit, canBeRefunded } = props;
    const defaultValues = { withdrawType: '' };
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    const handleFormSubmit = (values) => {
        onSubmit(values.withdrawType);
        reset();
    };

    const selectOptions = canBeRefunded ? playerWithdrawTypes : [playerWithdrawTypes[0]];
    const message = canBeRefunded ? 'You can withdraw and/or refund a player from a session.' : 'You can withdraw a player from a session.';

    return (
        <ModalBaseWithHeader title="Withdraw player" onClose={onClose} open={open} onSubmit={onSubmit}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent dividers>
                    <Typography gutterBottom marginBottom="24px">
                        {message}
                    </Typography>
                    <Select
                        name="withdrawType"
                        label="Action"
                        options={selectOptions}
                        labelId="withdraw-label"
                        isFullWidth
                        control={control}
                        hasError={!!errors.withdrawType}
                        isRequired
                        helperText="Required"
                    />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="dark" autoFocus type="submit">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </ModalBaseWithHeader>
    );
};

PlayerWithdrawModal.propTypes = {
    ...playerWithdrawModalPropTypes
};

PlayerWithdrawModal.defaultProps = {
    ...defaultModalPropTypes
};

export default PlayerWithdrawModal;
