import React from 'react';

import { AvatarWithName, DeleteIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { getPlayerAvatarImage, getPlayerProfileUrl } from '../../../utils/helpers';
import { manageSessionCaptainCardPropTypes } from '../../../utils/proptypes';

const ManageSessionCaptainCard = ({ captain, onRemoveClick }) => {
    const theme = useTheme();
    const { displayName, memberId, playerId, profileImage, location } = captain;
    const href = getPlayerProfileUrl(playerId);

    const handleOnRemoveClick = () => {
        onRemoveClick(captain);
    };

    return (
        <Box mb={1} key={memberId} backgroundColor={theme.appColors.white} p={1.5} borderRadius={1}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" position="relative">
                <Grid item xs={12} md={5}>
                    <AvatarWithName
                        avatarHeight="36px"
                        avatarWidth="36px"
                        href={href}
                        playerName={displayName}
                        imgSrc={getPlayerAvatarImage(profileImage)}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    {location?.display}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{ position: { xs: 'absolute', md: 'static' }, top: { xs: 0 }, right: { xs: 0 } }}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconButton onClick={handleOnRemoveClick}>
                        <DeleteIcon size={18} color={theme.appColors.red} />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};

ManageSessionCaptainCard.propTypes = {
    ...manageSessionCaptainCardPropTypes,
};

export default ManageSessionCaptainCard;
