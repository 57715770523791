import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import LoadingIndicator from '../../../components/LoadingIndicator';
import MultiRoundScheduleSelect from '../../../components/Form/MultiRoundScheduleSelect';
import Schedule from '../../../components/Schedule';

import useSessionSchedule from '../../../hooks/useSessionSchedule';
import { LeagueSessionTypes } from '../../../utils/types';

const ScheduleTab = ({ session, isLoadingSession }) => {
    const { id, sessionTypeId } = session;
    const { MULTI_ROUND } = LeagueSessionTypes;
    const isMultiRoundSession = sessionTypeId === MULTI_ROUND;
    const {
        scheduleRounds,
        scheduleByDate,
        drawSchedule,
        isSchedulePublished,
        isLoadingSchedule,
        onGetScheduleByDate,
        multiRoundScheduleType,
    } = useSessionSchedule({ sessionId: id, showDraftSchedule: false, isMultiRoundSession });

    const handleOnScheduleChange = (event) => {
        onGetScheduleByDate(event?.target?.value);
    };

    const displayMultiRoundScheduleSelect = scheduleRounds && isSchedulePublished && isMultiRoundSession;

    return (
        <Container>
            {isLoadingSession || isLoadingSchedule ? (
                <LoadingIndicator />
            ) : (
                <>
                    {displayMultiRoundScheduleSelect && (
                        <Box my={4}>
                            <MultiRoundScheduleSelect
                                scheduleType={multiRoundScheduleType}
                                disabled={isLoadingSchedule}
                                onChange={handleOnScheduleChange}
                            />
                        </Box>
                    )}
                    <Schedule
                        isTournament={!isMultiRoundSession}
                        seasonRounds={scheduleRounds}
                        drawSchedule={drawSchedule}
                        scheduleByDate={scheduleByDate}
                        multiRoundScheduleType={multiRoundScheduleType}
                        isSchedulePublished={isSchedulePublished}
                        sessionId={id}
                        displayTournamentBracketStyle
                    />
                </>
            )}
        </Container>
    );
};

export default ScheduleTab;
