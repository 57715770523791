import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { PlayerAvailabilityStatusTypes } from '../../../../utils/types';
import { CustomSwitch } from '../../../../components/Styled';

const PlayerAvailabilityReminderModal = ({ isOpen, onClose, players, onReminderChange }) => {
    const theme = useTheme();
    const { YES, NO, MAYBE } = PlayerAvailabilityStatusTypes;

    const [playersToRemind, setPlayersToRemind] = useState([]);

    const handleAvailabilityReminderSwitch = (playerIds) => {
        const index = playersToRemind?.findIndex((selectedPlayerIds) =>
            selectedPlayerIds?.every((id) => playerIds.includes(id)));
        const removePlayerIds = index !== -1;
        if (removePlayerIds) {
            const updatedPlayersToRemind = [...playersToRemind];
            updatedPlayersToRemind?.splice(index, 1);
            setPlayersToRemind(updatedPlayersToRemind);
        } else {
            setPlayersToRemind([...playersToRemind, playerIds]);
        }
    };

    const isReminderSwitchChecked = (playerIds) => {
        return playersToRemind?.some((selectedPlayerIds) =>
            selectedPlayerIds?.every((id) => playerIds.includes(id)));
    };

    const handleOnClose = () => {
        onClose();
        setPlayersToRemind([]);
    };

    const handleSendNotification = () => {
        onReminderChange({ teamMemberIds: playersToRemind?.flat() });
        onClose();
        setPlayersToRemind([]);
    };

    const getLabelForAvailabilityStatus = (status) => {
        switch (status) {
            case YES:
                return 'Yes';
            case MAYBE:
                return 'Maybe';
            case NO:
                return 'No';
            default:
                return 'No response';
        }
    };

    const groupedPlayersByAvailability = players.reduce((result, player) => {
        const statusTypeId = player.playerAvailabilityStatusTypeId;
        if (!result[statusTypeId]) {
            result[statusTypeId] = [];
        }
        result[statusTypeId].push(player.teamMemberId);
        return result;
    }, {});

    return (
        <Dialog fullWidth maxWidth="xs" open={isOpen}>
            <DialogTitle sx={{ pb: 0 }} align="left">
                <Typography sx={{ textTransform: 'none', borderBottom: `3px solid ${theme.appColors.coolGrey}` }} size="medium-book" category="display">Send reminders to</Typography>
            </DialogTitle>
            <DialogContent>
                {Object.entries(groupedPlayersByAvailability).map(([status, ids]) => {
                    return (
                        <Box key={status} display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: `3px solid ${theme.appColors.coolGrey}`, height: '64px' }}>
                            <Box display="flex" gap={1}>
                                <Typography size="small-medium" category="secondary" color={theme.appColors.black}>{getLabelForAvailabilityStatus(parseInt(status))}</Typography>
                                <Typography size="small-medium" category="secondary" color={theme.appColors.grey}>{ids.length}</Typography>
                            </Box>
                            <CustomSwitch checked={isReminderSwitchChecked(ids)} inputProps={{ 'aria-label': `Switch ${getLabelForAvailabilityStatus(status)}` }} id={status} onChange={() => handleAvailabilityReminderSwitch(ids)} />
                        </Box>
                    );
                })}
            </DialogContent>
            <DialogActions sx={{ mb: 3, px: 3 }}>
                <Button category="secondary" sx={{ width: '50%' }} onClick={handleOnClose} variant="light">
                    Cancel
                </Button>
                <Button category="primary" sx={{ width: '50%' }} onClick={handleSendNotification} variant="light">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlayerAvailabilityReminderModal;
