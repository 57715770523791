import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import FilterButton from '../../../components/FilterButton';

import { ageSliderDictionary, utrpSliderDictionary } from '../../../utils/constants';
import { ageDisplay, utrpSliderDisplay } from '../../../utils/display';
import { SliderTypes } from '../../../utils/types';
import { getRangeForSlider } from '../../../utils/helpers';
import { sliderFilterPropTypes } from '../../../utils/proptypes';

const _ = { isEqual };

const SliderFilter = ({ onApplyFilter, hasResetFilters, sliderType }) => {
    const { AGE, UTR, UTRP } = SliderTypes;
    const rangeValue = getRangeForSlider(sliderType);
    const title = SliderTypes[sliderType.toUpperCase()];
    const defaultFilterData = {
        value: rangeValue,
        label: title,
    };
    const [filterData, setFilterData] = useState(defaultFilterData);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const getSliderData = (value) => {
        let sliderLength;
        let display;
        let step = 1;

        switch (sliderType) {
            case AGE:
                sliderLength = Object.keys(ageSliderDictionary).length;
                display = ageDisplay(value);
                break;
            case UTR:
                sliderLength = 16;
                display = `${value[0]?.toFixed(1)} - ${value[1]?.toFixed(1)}`;
                step = 0.5;
                break;
            case UTRP:
                sliderLength = Object.keys(utrpSliderDictionary).length;
                display = utrpSliderDisplay(value);
                break;
            default:
                sliderLength = '';
                display = '';
        }
        return { sliderLength, display, step };
    };

    const { value, label } = filterData;
    const { sliderLength, display, step } = getSliderData(value);

    useEffect(() => {
        if (hasResetFilters) {
            setFilterData(defaultFilterData);
            setIsFilterApplied(false);
        }
    }, [hasResetFilters]);

    const handleOnSliderChange = (event) => {
        setFilterData({ ...filterData, value: event.target.value });
    };

    const handleOnApplyClicked = () => {
        onApplyFilter(value);

        const isEqualToDefaultValue = _.isEqual(value, defaultFilterData.value);
        if (isEqualToDefaultValue) {
            setFilterData(defaultFilterData);
            setIsFilterApplied(false);
        } else {
            setFilterData({ ...filterData, label: display });
            setIsFilterApplied(true);
        }

        setIsOpen(false);
    };

    const handleOnOpenLocation = () => {
        setIsOpen(true);
    };

    const handleOnCloseLocation = () => {
        setIsOpen(false);
    };

    return (
        <FilterButton
            isOpen={isOpen}
            onOpen={handleOnOpenLocation}
            onClose={handleOnCloseLocation}
            isFilterApplied={isFilterApplied}
            buttonTitle={label}
        >
            <Box p={4} width={450}>
                <Box mb={3} display="flex" justifyContent="space-between">
                    <Typography>{title}</Typography>
                    <Typography>{display}</Typography>
                </Box>
                <Slider value={value} onChange={handleOnSliderChange} max={sliderLength} min={1} step={step} />
                <Box mt={3} display="flex" justifyContent="center">
                    <Button onClick={handleOnApplyClicked} category="primary">
                        Apply
                    </Button>
                </Box>
            </Box>
        </FilterButton>
    );
};

SliderFilter.propTypes = {
    ...sliderFilterPropTypes
};

export default SliderFilter;
