import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

import { SortableTable, SquareAvatarWithName, Typography, Button } from '@universal-tennis/ui-shared';

import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { getAdjustedDate } from '../../../utils/display';
import { OrderByTypes } from '../../../utils/types';
import { getPowerIndex } from '../../../utils/helpers';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { addTeamsTablePropTypes, defaultAddTeamsTablePropTypes, teamTableRowProps } from '../../../utils/proptypes';

const headCells = [
    { id: OrderByTypes.TEAM_NAME, label: 'Team Name' },
    { id: 'captainNames', label: 'Captains', isSortingDisabled: true },
    { id: OrderByTypes.SESSION_NAME, label: 'Session' },
    { id: 'teamPowerIndexSingle', label: 'TPI S', isSortingDisabled: true },
    { id: 'teamPowerIndexDouble', label: 'TPI D', isSortingDisabled: true },
    { id: 'record', label: 'Record', isSortingDisabled: true },
    { id: OrderByTypes.RANK, label: 'Rank' },
    { id: 'checkbox' },
];

const MAX_CAPTAINS_TO_SHOW = 2;

const AddTeamsTable = ({
    sessionTeams = [],
    isLoading,
    sortByProperty,
    sortOrder,
    selectedTeamIds,
    onTeamSelected,
    onSortChange,
    maxTeamsReached,
}) => {
    const [teams, setTeams] = useState([]);
    const theme = useTheme();
    const { MEDIUM_MEDIUM, SMALL_MEDIUM, SMALL_BOOK, X_SMALL_MEDIUM } = TypographySizes;
    const { SECONDARY } = SharedUICategories;
    const tableCellStyles = { borderBottom: 'none', maxWidth: '200px' };
    const smallTableCellStyles = { borderBottom: 'none', maxWidth: '150px' };

    useEffect(() => {
        const formattedTeams = sessionTeams.map((team) => {
            const { captains, sessionEndDateUtc, sessionStartDateUtc, wins, losses, ...rest } = team;
            const captainNames = captains.slice(0, MAX_CAPTAINS_TO_SHOW).map((captain) => `${captain?.firstName} ${captain?.lastName}`);
            const dateFormat = 'll';
            const startDate = getAdjustedDate({ dateUtc: sessionStartDateUtc, format: dateFormat });
            const endDate = getAdjustedDate({ dateUtc: sessionEndDateUtc, format: dateFormat });
            return {
                ...rest,
                captainNames,
                sessionDates: `${startDate} - ${endDate}`,
                record: `${wins} - ${losses}`,
                isSelected: selectedTeamIds?.includes(team.id),
            };
        });
        setTeams(formattedTeams);
    }, [sessionTeams]);

    const handleOnOrderPropertyChange = (property) => {
        onSortChange(property);
    };

    const TeamTableRow = ({ rowData, labelId }) => {
        const { id, name, teamMemberCount, sessionName, captainNames, sessionDates, record, rank, teamPowerIndex } = rowData;

        const teamUrl = `/teams/${id}`;

        const handleOnItemSelected = () => {
            const selectedTeam = teams.find((team) => team.id === id);
            onTeamSelected(id, selectedTeam);
        };

        const isItemSelected = selectedTeamIds.includes(id);

        TeamTableRow.propTypes = {
            ...teamTableRowProps,
        };

        return (
            <TableRow tabIndex={-1} key={id} sx={{ backgroundColor: theme.appColors.white }}>
                <TableCell sx={{ ...tableCellStyles }} component="th" id={labelId} scope="row" width="30%">
                    <SquareAvatarWithName
                        avatarFontCategory="primary"
                        avatarFontSize={MEDIUM_MEDIUM}
                        avatarHeight="36px"
                        avatarWidth="36px"
                        href={teamUrl}
                        maxLinkWidth="150px"
                        name={name}
                        fontSize={SMALL_MEDIUM}
                        subtitle={`${teamMemberCount} players`}
                    />
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="25%">
                    {captainNames?.map((captain) => (
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} key={captain}>
                            {captain}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }} align="left" width="20%">
                    <Stack>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM}>
                            {sessionName}
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {sessionDates}
                        </Typography>
                    </Stack>
                </TableCell>
                <TableCell sx={{ ...smallTableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {getPowerIndex(teamPowerIndex?.singlesRating, teamPowerIndex?.isSinglesEstimated)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...smallTableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {getPowerIndex(teamPowerIndex?.doublesRating, teamPowerIndex?.isDoublesEstimated)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...smallTableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {record}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...smallTableCellStyles }} align="left" width="5%">
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {rank}
                    </Typography>
                </TableCell>
                <TableCell sx={{ ...smallTableCellStyles }} width="5%">
                    <Button onClick={handleOnItemSelected} disabled={maxTeamsReached || isItemSelected} category={SECONDARY}>
                        Add
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    return sessionTeams?.length && !isLoading ? (
        <SortableTable
            headCells={headCells}
            tableData={teams}
            sortOrder={sortOrder}
            sortByProperty={sortByProperty}
            rowComponent={TeamTableRow}
            onOrderPropertyChange={handleOnOrderPropertyChange}
        />
    ) : (
        <>
            <Typography category={SECONDARY} size={X_SMALL_MEDIUM} mb={2}>
                Teams.
            </Typography>
            <Typography category={SECONDARY} size={SMALL_BOOK}>
                Select a conference and session to see teams
            </Typography>
        </>
    );
};

AddTeamsTable.propTypes = {
    ...addTeamsTablePropTypes,
};

AddTeamsTable.defaultProps = {
    ...defaultAddTeamsTablePropTypes,
};

export default AddTeamsTable;
