import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import LeaguesSearchResultsTable from '../../components/Tables/LeagueSearchResultsTable';
import LocationFilter from './LocationFilter';
import SliderFilter from './SliderFilter';
import MultiSelectFilter from '../../components/MultiSelectFilter';
import usePostLeaguesSearch from '../../hooks/usePostLeaguesSearch';

import { genderTypes, SportTypeIds, tierTypes, OrderByRuleTypes, searchDateFilterTypes, SessionDateStatuses, SliderTypes, SearchSortTypes } from '../../utils/types';
import { ageSliderDictionary, utrpSliderDictionary } from '../../utils/constants';
import { showPopup } from '../../redux/genericPopupSlice';
import { getLeagueTypeIdForTier, getSportTypeForLeagueId } from '../../utils/helpers';
import { searchPagePropTypes } from '../../utils/proptypes';

const defaultDistance = 50;

const SearchPage = ({ userLocation, leagueTypeId, sportType, leagueTierType }) => {
    const dispatch = useDispatch();
    const { TENNIS } = SportTypeIds;
    const { START_DATE, UTR: UTR_SORT, UTRP: UTRP_SORT } = SearchSortTypes;
    const { ASCENDING, DESCENDING } = OrderByRuleTypes;
    const { AGE, UTR, UTRP } = SliderTypes;
    const dateStatus = searchDateFilterTypes[0].id;
    const defaultDateStatus = SessionDateStatuses[dateStatus];

    const defaultSearchFilters = {
        leagueTypeIds: [parseInt(leagueTypeId)],
        distanceFilter: {
            distance: defaultDistance,
            lat: userLocation?.lat || null,
            long: userLocation?.lng || null,
        },
        ageFilter: null,
        utrFilter: null,
        utrPFilter: null,
        sessionDateStatuses: [defaultDateStatus],
        genderTypeIds: [],
        sportTypeIds: [sportType],
        sortBy: SearchSortTypes.START_DATE,
        orderByRule: ASCENDING.id
    };

    const [searchFilters, setSearchFilters] = useState(defaultSearchFilters);
    const [hasResetSearchFilters, setHasResetSearchFilters] = useState(false);
    const [currentLocation, setCurrentLocation] = useState();
    const [currentLeagueTierType, setCurrentLeagueTierType] = useState(leagueTierType);
    const [currentDateStatus, setCurrentDateStatus] = useState(dateStatus);
    const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] = useState();
    const [sortOrder, setSortOrder] = useState(ASCENDING);
    const [sortByProperty, setSortByProperty] = useState(START_DATE);

    const { searchResults, isFetchingResults, isEndOfResults, handleOnLoadMoreClick, handleRefetch, clearSearchResults } = usePostLeaguesSearch({ searchFilters });

    const handleOnUrlChange = async () => {
        clearSearchResults();
    };

    useEffect(() => {
        const resetSearchOnUrlChange = async () => {
            const { lat, lng } = userLocation || {};
            const leagueId = parseInt(leagueTypeId);
            const sportTypeId = getSportTypeForLeagueId(leagueId);

            await handleOnUrlChange();

            setSearchFilters({
                ...defaultSearchFilters,
                sportTypeIds: [sportTypeId],
                leagueTypeIds: [leagueId],
            });
            setHasResetSearchFilters(true);
            setCurrentLeagueTierType(leagueTierType);
            setCurrentLocation({ lat, lng });
            handleRefetch();
        };
        if (leagueTypeId && userLocation) {
            resetSearchOnUrlChange();
        }
    }, [leagueTypeId, userLocation, leagueTierType]);

    const resetSearch = () => {
        handleRefetch();
        setHasResetSearchFilters(false);
    };

    const handleOnApplyDateFilter = (dateStatuses) => {
        resetSearch();

        if (dateStatuses) {
            const sessionDateStatuses = dateStatuses?.map((status) => SessionDateStatuses[status]);
            setSearchFilters({ ...searchFilters, sessionDateStatuses });
        } else {
            setCurrentDateStatus(null);
            setSearchFilters({ ...searchFilters, sessionDateStatuses: null });
        }
    };

    const handleOnApplyTypeFilter = (tierTypeId) => {
        resetSearch();
        const leagueTypeIds = getLeagueTypeIdForTier(tierTypeId, sportType);

        if (!tierTypeId) {
            setCurrentLeagueTierType(null);
        }

        setSearchFilters({ ...searchFilters, leagueTypeIds });
    };

    const handleOnApplyAgeFilter = (ages) => {
        const [min, max] = ages;
        const minAge = ageSliderDictionary[min];
        const maxAge = ageSliderDictionary[max];

        resetSearch();
        setSearchFilters({ ...searchFilters, ageFilter: { minAge, maxAge } });
    };

    const handleOnApplyTennisRatingFilter = (utrs) => {
        const [utrRangeMin, utrRangeMax] = utrs;
        resetSearch();
        setSearchFilters({ ...searchFilters, utrFilter: { utrRangeMin, utrRangeMax } });
    };

    const handleOnApplyPickleballRatingFilter = (utrps) => {
        const [utrPMin, utrPRMax] = utrps;
        const utrPRangeMin = utrpSliderDictionary[utrPMin];
        const utrPRangeMax = utrpSliderDictionary[utrPRMax];

        resetSearch();
        setSearchFilters({ ...searchFilters, utrPFilter: { utrPRangeMin, utrPRangeMax } });
    };

    const handleOnApplyGenderFilter = (genderTypeIds) => {
        resetSearch();
        setSearchFilters({ ...searchFilters, genderTypeIds });
    };

    const handleOnClearFilters = () => {
        resetSearch();

        const leagueTypeIds = getLeagueTypeIdForTier(null, sportType);
        setSearchFilters({ ...defaultSearchFilters, leagueTypeIds, distanceFilter: null, sessionDateStatuses: [], sportTypeIds: [] });

        setHasResetSearchFilters(true);
        setCurrentLocation(null);
        setCurrentDateStatus(null);
        setCurrentLeagueTierType(null);
    };

    const handleOnApplyLocationFilter = async (sliderValue, lat, lng) => {
        if (lat && lng) {
            setSearchFilters({ ...searchFilters, distanceFilter: { lat, long: lng, distance: sliderValue } });
            setCurrentLocation({ lat, lng });
        } else {
            setSearchFilters({ ...searchFilters, distanceFilter: { ...searchFilters.distanceFilter, distance: sliderValue } });
        }

        resetSearch();
    };

    const handleOnClearSearchLocationFilters = () => {
        resetSearch();
        setSearchFilters({ ...searchFilters, distanceFilter: null });
    };

    const handleOnCurrentLocationClick = async (lat, lng, distance, error) => {
        if (navigator.geolocation) {
            if (lat && lng) {
                setSearchFilters({ ...searchFilters, distanceFilter: { lat, long: lng, distance } });
                resetSearch();
                setIsLoadingCurrentLocation(false);
            }
            if (error) {
                dispatch(showPopup({ title: 'Location Service Error', message: error?.message }));
                setIsLoadingCurrentLocation(false);
            }
        } else {
            dispatch(showPopup({ title: 'Location Service', message: 'Geolocation is not available' }));
        }
    };

    const handleOnIsLoadingCurrentLocation = (isLoadingLocation) => {
        setIsLoadingCurrentLocation(isLoadingLocation);
    };

    const handleOnSortChange = (property) => {
        resetSearch();

        if (property === sortByProperty) {
            const sortDirection = sortOrder === ASCENDING ? DESCENDING : ASCENDING;
            setSortOrder(sortDirection);
            setSearchFilters({ ...searchFilters, orderByRule: sortDirection.id });
        } else {
            setSortOrder(ASCENDING);
            let sortBy = property.toLowerCase();
            if (property === 'rating') {
                sortBy = sportType === TENNIS ? UTR_SORT : UTRP_SORT;
            }

            setSearchFilters({ ...searchFilters, orderByRule: ASCENDING.id, sortBy });
        }
        setSortByProperty(property);
    };

    return (
        <Container sx={{ mt: 10 }} disableGutters maxWidth="lg">
            <Typography variant="subHeading">{`Search team ${sportType === TENNIS ? 'tennis' : 'pickleball'} leagues`}</Typography>
            <Box mt={4}>
                <Stack spacing={4} direction="row">
                    <LocationFilter
                        hasResetFilters={hasResetSearchFilters}
                        onApplyFilter={handleOnApplyLocationFilter}
                        onIsLoadingCurrentLocation={handleOnIsLoadingCurrentLocation}
                        onClearLocation={handleOnClearSearchLocationFilters}
                        onCurrentLocationClick={handleOnCurrentLocationClick}
                        currentLocation={currentLocation}
                    />
                    <MultiSelectFilter
                        label="Date"
                        selectOptions={searchDateFilterTypes}
                        hasResetFilters={hasResetSearchFilters}
                        onApplyFilter={handleOnApplyDateFilter}
                        currentValue={currentDateStatus}
                    />
                    <MultiSelectFilter
                        label="Type"
                        selectOptions={tierTypes}
                        hasResetFilters={hasResetSearchFilters}
                        onApplyFilter={handleOnApplyTypeFilter}
                        currentValue={currentLeagueTierType}
                    />
                    <SliderFilter sliderType={AGE} hasResetFilters={hasResetSearchFilters} onApplyFilter={handleOnApplyAgeFilter} />
                    <SliderFilter
                        sliderType={sportType === TENNIS ? UTR : UTRP}
                        hasResetFilters={hasResetSearchFilters}
                        onApplyFilter={sportType === TENNIS ? handleOnApplyTennisRatingFilter : handleOnApplyPickleballRatingFilter}
                    />
                    <MultiSelectFilter
                        label="Gender"
                        selectOptions={genderTypes}
                        hasResetFilters={hasResetSearchFilters}
                        onApplyFilter={handleOnApplyGenderFilter}
                    />

                    <Button onClick={handleOnClearFilters}>Reset Filters</Button>
                </Stack>
            </Box>
            <Box mt={5}>
                <LeaguesSearchResultsTable
                    isLoading={isLoadingCurrentLocation}
                    searchResults={searchResults}
                    onSortChange={handleOnSortChange}
                    sortByProperty={sortByProperty}
                    sortOrder={sortOrder.value}
                />
            </Box>
            {!isEndOfResults && !!searchResults?.length && (
                <Box display="flex" justifyContent="center">
                    <LoadingButton
                        onClick={handleOnLoadMoreClick}
                        loading={isFetchingResults}
                        variant="dark"
                        loadingIndicator={<CircularProgress color="primary" size={20} />}
                    >
                        Load More
                    </LoadingButton>
                </Box>
            )}
        </Container>
    );
};

SearchPage.propTypes = {
    ...searchPagePropTypes.propTypes
};

SearchPage.defaultProps = {
    ...searchPagePropTypes.defaultProps
};

export default SearchPage;
