import { createAsyncThunk } from '@reduxjs/toolkit';

import { getActiveLeagueTeams, getPreferences } from '../../api/leaguesApi';

export const getActiveTeams = createAsyncThunk('user/getActiveTeams', async () => {
    try {
        const response = await getActiveLeagueTeams();
        return response.data;
    } catch (err) {
        const customError = {
            name: 'Axios Types Error',
            message: err.response.data?.message || err.response.statusText,
        };
        throw customError;
    }
});

export const getUserPreferences = createAsyncThunk('user/getUserPreferences', async () => {
    try {
        const response = await getPreferences();
        return response.data;
    } catch (err) {
        const customError = {
            name: 'Axios Types Error',
            message: err.response.data?.message || err.response.statusText,
        };
        throw customError;
    }
});
