import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import { hidePopup } from '../../redux/genericPopupSlice';

const GenericPopup = () => {
    const dispatch = useDispatch();
    const { isOpen, message, title } = useSelector((state) => state.genericPopup);

    const handleOnClose = () => {
        dispatch(hidePopup());
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 2 }}
        >
            <DialogTitle align="center" color="inherit" id="alert-dialog-title">
                {title}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText align="center" id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                <Button variant="dark" onClick={handleOnClose}>
                    ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GenericPopup;
