import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { Button, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';

import ManageTeamModal from '../ManageTeamModal';
import CreateTeamModal from '../CreateTeamModal';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Popup from '../../../components/Popups/Popup';
import TeamsTable from '../../../components/Tables/TeamsTable';
import { showPopup } from '../../../redux/genericPopupSlice';
import { TypographySizes, SharedUICategories } from '../../../utils/constants';
import { manageSessionTeamsTabPropTypes } from '../../../utils/proptypes';

const TeamsTab = ({
    teams = [],
    maxTeamsCount,
    sessionTypeId,
    isLoading,
    onRemoveTeam,
    hasSessionStarted,
    hasSessionEnded,
    onAddTeamClick,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { MEDIUM_MEDIUM } = TypographySizes;
    const { SECONDARY } = SharedUICategories;
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [teamToRemove, setTeamToRemove] = useState(null);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [showManageTeamModal, setShowManageTeamModal] = useState(false);
    const isTeamActionsDisabled = teams.length === maxTeamsCount || hasSessionEnded;
    const [selectedTeamId, setSelectedTeamId] = useState(null);

    const handleOnAddTeamClick = () => {
        onAddTeamClick();
    };

    const handleOnCreateTeamClick = () => {
        setShowCreateTeamModal(true);
    };

    const handleOnManageClick = (teamId) => {
        setSelectedTeamId(teamId);
        setShowManageTeamModal(true);
    };

    const handleOnCloseManageTeamModal = () => {
        setShowManageTeamModal(false);
    };

    const handleOnCloseCreateTeamModal = () => {
        setShowCreateTeamModal(false);
    };

    const handleOnRemoveClick = (teamData) => {
        const { teamMemberCount, ...rest } = teamData;
        if (teamMemberCount > 0) {
            dispatch(showPopup({ title: 'Error', message: 'You cannot remove a team that has registered players' }));
        } else {
            setTeamToRemove({ ...rest });
            setShowConfirmationPopup(true);
        }
    };

    const handleOnConfirmRemoval = () => {
        setShowConfirmationPopup(false);
        onRemoveTeam(teamToRemove);
    };

    const handleOnClosePopup = () => {
        setShowConfirmationPopup(false);
    };

    return (
        <>
            <Box
                pb={3}
                display="flex"
                justifyContent="flex-end"
                alignContent="center"
                sx={{ borderBottom: `4px solid ${theme.appColors.white}` }}
            >
                <Button sx={{ mr: 1 }} disabled={isTeamActionsDisabled} onClick={handleOnAddTeamClick} category="secondary">
                    Add Existing Team
                </Button>
                <Button disabled={isTeamActionsDisabled} onClick={handleOnCreateTeamClick} category="secondary">
                    Create New Team
                </Button>
            </Box>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Box>
                    {!teams.length ? (
                        <Box py={8} textAlign="center">
                            <Typography category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                                No teams have been created for this session.
                            </Typography>
                        </Box>
                    ) : (
                        <TeamsTable
                            hasSessionStarted={hasSessionStarted}
                            sessionTeams={teams}
                            onRemoveClicked={handleOnRemoveClick}
                            onManageClicked={handleOnManageClick}
                        />
                    )}
                </Box>
            )}
            <ManageTeamModal
                isOpen={showManageTeamModal}
                onClose={handleOnCloseManageTeamModal}
                onSaveClick={handleOnCloseManageTeamModal}
                isManaging
                teamId={selectedTeamId}
            />
            <CreateTeamModal
                isOpen={showCreateTeamModal}
                onClose={handleOnCloseCreateTeamModal}
                onSaveClick={handleOnCloseCreateTeamModal}
                sessionTypeId={sessionTypeId}
            />
            <Popup
                onAction={handleOnConfirmRemoval}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showConfirmationPopup}
                onClose={handleOnClosePopup}
                title="Confirm Team Removal"
                message={`Are you sure you want to remove ${teamToRemove?.name}?`}
            />
        </>
    );
};

TeamsTab.propTypes = {
    ...manageSessionTeamsTabPropTypes,
};

export default TeamsTab;
