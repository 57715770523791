import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { UTMap, Typography } from '@universal-tennis/ui-shared';

const LocationTab = ({ description = '', location }) => {
    const { placeId, placeName, formattedAddress } = location || {};

    return (
        <Container>
            {location ? (
                <Box maxWidth="md" color="black">
                    <Typography category="secondary" size="small-medium">
                        {placeName}
                    </Typography>
                    <Typography category="secondary" size="small-book">
                        {formattedAddress}
                    </Typography>
                    <Box py={2}>
                        <UTMap placeId={placeId} height={320} />
                    </Box>
                    <Typography category="secondary" size="small-book">
                        {description}
                    </Typography>
                </Box>
            ) : (
                <Typography category="secondary" color="black" size="small-medium">
                    TBD
                </Typography>
            )}
        </Container>
    );
};

export default LocationTab;
