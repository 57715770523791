import React, { useEffect, useState } from 'react';

import { TeamDrawCard } from '@universal-tennis/ui-shared';
import {
    FULL_PAGE_ROUNDS,
    ONE_ROUND,
    TBD_ROUND,
    getFormattedTeamPageDrawFields,
    getTeamPageDataFormattedAndPublishedTeams,
} from './utils';
import { useIsTablet } from '../../hooks/useIsMobile';

const TBDLabel = {
    name: TBD_ROUND,
    points: 0,
};

export const useGetTeamPageTableRowsData = ({
    selectableTeams,
    draw,
    totalRounds,
    hasPublishedSchedule,
    isMainDraw,
}) => {
    const { rounds } = draw;
    const isTablet = useIsTablet();
    const [initialRoundIndex, setInitialRoundIndex] = useState(0);
    const [selectedHeaderRows, setSelectedHeaderRows] = useState([]);
    const [selectedTableRows, setSelectedTableRows] = useState([]);

    const getPreviousRoundName = (drawRounds, roundId) => {
        const roundIndex = drawRounds.findIndex((round) => round.id === roundId);

        if (roundIndex === -1 || roundIndex === 0) {
            return null;
        }

        const previousRound = drawRounds[roundIndex - 1];
        return previousRound.name;
    };

    const getTableRows = () =>
        rounds?.map((round) => {
            const { number: roundNumber } = round;

            return round.teamMatches?.map((teamMatch, index) => {
                const { matchDate = null, location = null, position1Team = null, position2Team = null, teamMatchId = '' } = teamMatch;
                const { formattedDateLabel = null, formattedLocationLabel = null, formattedTimeLabel = null } = getFormattedTeamPageDrawFields({ teamMatch, location, matchDate });

                const isNotFirstRound = roundNumber !== 1;
                const isDisabled = !hasPublishedSchedule && isNotFirstRound;
                const isScorelessMatch = !position1Team?.points && !position2Team?.points;
                const previousRoundName = getPreviousRoundName(rounds, round.id);
                const currentMatchIndex = index;
                const teamMatchLink = `/leagues/match/${teamMatchId}`;
                const showTeamMatchLink = position1Team !== null && position2Team !== null;

                const { dataFormatted = {}, publishedPosition1Team = {}, publishedPosition2Team = {} } = getTeamPageDataFormattedAndPublishedTeams({ dateLabel: formattedDateLabel, locationLabel: formattedLocationLabel, timeLabel: formattedTimeLabel, roundNumber, hasPublishedSchedule, isMainDraw, totalRounds, teamMatch, previousRoundName, currentMatchIndex });

                if (hasPublishedSchedule) {
                    return (
                        <TeamDrawCard
                            {...dataFormatted}
                            isScorelessMatch={isScorelessMatch}
                            position1Team={publishedPosition1Team}
                            position2Team={publishedPosition2Team}
                            teamMatchLink={teamMatchLink}
                            showTeamMatchLink={showTeamMatchLink}
                        />
                    );
                }

                if (isDisabled) {
                    return (
                        <TeamDrawCard
                            isScorelessMatch
                            position1Team={TBDLabel}
                            position2Team={TBDLabel}
                            locationLabel={location?.placeName}
                            dateLabel={formattedDateLabel}
                            timeLabel={formattedDateLabel}
                            teamMatchLink={teamMatchLink}
                            showTeamMatchLink={showTeamMatchLink}
                        />
                    );
                }

                return (
                    <TeamDrawCard
                        {...dataFormatted}
                        teamMatchLink={teamMatchLink}
                        showTeamMatchLink={showTeamMatchLink}
                    />
                );
            });
        });

    useEffect(() => {
        const tableRows = getTableRows();
        const initialRounds = rounds
            ?.map((round) => ({
                name: round.name,
            }))
            ?.slice(initialRoundIndex, initialRoundIndex + FULL_PAGE_ROUNDS);
        const takeFirstThree = tableRows?.slice(initialRoundIndex, FULL_PAGE_ROUNDS + initialRoundIndex);

        setSelectedHeaderRows(initialRounds);
        setSelectedTableRows(takeFirstThree);
    }, [selectableTeams, draw, isTablet, hasPublishedSchedule, rounds, initialRoundIndex]);

    const handleOnForwardRoundClick = () => setInitialRoundIndex(initialRoundIndex + ONE_ROUND);
    const handleOnBackwardRoundClick = () => setInitialRoundIndex(initialRoundIndex - ONE_ROUND);

    const isShowingForward =
        !(initialRoundIndex + FULL_PAGE_ROUNDS === rounds?.length) &&
        initialRoundIndex !== rounds?.length - ONE_ROUND &&
        rounds?.length > FULL_PAGE_ROUNDS;

    const isShowingBackward = initialRoundIndex !== 0;

    return {
        tableRows: selectedTableRows,
        headerRows: selectedHeaderRows,
        handleOnForwardRoundClick,
        handleOnBackwardRoundClick,
        isShowingForward,
        isShowingBackward,
    };
};
