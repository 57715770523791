import React from 'react';

const Description = ({ descriptionItems, noEllipsis }) => {
    let description;
    const createDescriptionFromArray = (descriptionArray) => {
        return descriptionArray.filter(Boolean).join(' \u2022 ');
    };

    if (typeof descriptionItems === 'string' || typeof descriptionItems === 'number') {
        description = descriptionItems;
    } else if (typeof descriptionItems === 'object') {
        description = createDescriptionFromArray(descriptionItems);
    }

    return noEllipsis ? (
        <span>{description}</span>
    ) : (
        <span className="show-ellipsis">{description}</span>
    );
};

export default Description;
