import React from 'react';
import { Controller } from 'react-hook-form';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';

import { dateTimeFormat } from '../../../utils/constants';

const DateTimePicker = ({ control, name, label, isRequired = false, minutesStep, minDate, onDateChange }) => {
    const handleOnDateChange = (date) => {
        if (onDateChange) {
            onDateChange(date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                    <MuiDateTimePicker
                        label={label}
                        inputFormat={dateTimeFormat}
                        minutesStep={minutesStep}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                            handleOnDateChange(e);
                        }}
                        minDateTime={minDate}
                        renderInput={(inputProps) => <TextField {...inputProps} fullWidth />}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
