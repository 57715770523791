export const ClubTierTypes = {
    HIGH_SCHOOL: 5,
    PERFORMANCE_HIGH_SCHOOL: 17,
    PAID_HIT: 16,
    CREATE_PLAY: 11,
};

export const ClubRoles = {
    ADMIN: 'Admin',
    OWNER: 'Owner',
    MEMBER: 'Member',
};

export const clubTiers = ['Pro', 'Special', 'Free College', 'High School', 'Pro College'];

export const getClubTypeString = (tierTypeId) => {
    switch (tierTypeId) {
        case 1:
            return 'groups';
        case 4:
        case 6:
            return 'colleges';
        case 5:
            return 'schools';
        default:
            return 'clubs';
    }
};

export const getClubsVisibleToUser = (clubs) => {
    const { CREATE_PLAY } = ClubTierTypes;
    const hiddenClubTypes = [CREATE_PLAY];
    return clubs.filter((club) => !hiddenClubTypes.includes(club.tierTypeId));
};

export const sortClubMembershipsByPaidHit = (clubs = []) => {
    const { PAID_HIT } = ClubTierTypes;
    const paidHitClub = clubs?.find((club) => club.tierTypeId === PAID_HIT);
    const filteredClubs = clubs?.filter((club) => club.tierTypeId !== PAID_HIT);

    if (paidHitClub) {
        return [paidHitClub, ...filteredClubs];
    }
    return clubs;
};
