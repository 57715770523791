import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FormSubmitButton from '../../Form/FormSubmitButton';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import Select from '../../Form/Select';
import StripeInput from '../../Form/StripeInput';
import config from '../../../config';
import { useGetBillingCountriesQuery } from '../../../api/leaguesApi';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { submitPaymentFormPropTypes } from '../../../utils/proptypes';

const { marketingAppHost } = config;

const registrationTerms = [
    `By registering, I agree to UTR Sports'`,
    `any additional terms provided by the event organizer in the event description. I also agree to provide my contact info to the event organizer solely for the purpose of participating in this event.`,
];

const SubmitPaymentForm = ({ clientSecret, onRegistrationSuccessful, segmentTrackingBody }) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [stripeError, setStripeError] = useState('');

    const { data: billingCountries, isFetching, error } = useGetBillingCountriesQuery();

    if (error) {
        dispatch(showErrorPopup(error));
    }

    const defaultValues = {
        name: '',
        email: '',
        address: {
            country: '',
        },
    };

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        register,
    } = useForm({ defaultValues, mode: 'all' });

    const handleFormSubmit = async (formValues, event) => {
        if (window?.analytics) {
            window.analytics.track('Payment Info Entered', segmentTrackingBody);
        }
        event?.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const { error: stripeCardError } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: formValues,
            },
        });

        if (stripeCardError) {
            setStripeError(stripeCardError.message);
        } else {
            onRegistrationSuccessful();
            if (window?.analytics) {
                window.analytics.track('Order Completed', segmentTrackingBody);
            }
        }
    };

    const handleInputOnFocus = () => {
        if (stripeError) {
            setStripeError(null);
        }
    };

    return (
        <Box mt={4}>
            {isFetching ? (
                <LoadingIndicator />
            ) : (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Stack spacing={2}>
                        <Input
                            onFocus={handleInputOnFocus}
                            disabled={isSubmitting}
                            name="name"
                            label="Cardholder Name"
                            placeholder="ex. Serena Williams"
                            hasError={!!errors.name}
                            isRequired
                            control={control}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            onFocus={handleInputOnFocus}
                            variant="outlined"
                            disabled={isSubmitting}
                            label="Card Number"
                            required
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardElement,
                                },
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Input
                            onFocus={handleInputOnFocus}
                            disabled={isSubmitting}
                            name="email"
                            label="Email"
                            hasError={!!errors.email}
                            isRequired
                            control={control}
                            register={register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        />
                        {errors.email && (
                            <Typography category="secondary" size="x-small-book" style={{ color: '#e0004f', marginTop: '8px' }}>
                                Must be in email format
                            </Typography>
                        )}
                        <Select
                            disabled={isSubmitting}
                            name="address.country"
                            label="Billing Country"
                            options={billingCountries}
                            hasError={!!errors.address?.country}
                            labelId="country-label"
                            isFullWidth
                            isRequired
                            control={control}
                        />
                        <Typography category="secondary" size="x-small-book">
                            {registrationTerms[0]}
                            <a href={`${marketingAppHost}/tos`}>&nbsp;terms of service&nbsp;</a>
                            {registrationTerms[1]}
                        </Typography>
                        <FormSubmitButton style={{ width: '100%' }} label="Register" isSubmitting={isSubmitting} />
                    </Stack>
                </form>
            )}
        </Box>
    );
};

SubmitPaymentForm.propTypes = {
    ...submitPaymentFormPropTypes
};

export default SubmitPaymentForm;
