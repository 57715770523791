import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AvatarWithName, Typography } from '@universal-tennis/ui-shared';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { SharedUICategories, TypographySizes } from '../../../../utils/constants';
import { SportTypeIds } from '../../../../utils/types';
import { getPlayerAvatarImage } from '../../../../utils/helpers';

import LoadingIndicator from '../../../../components/LoadingIndicator';
import PlayerAvailabilityIndicator from '../../../../components/PlayerAvailabilityIndicator';

const PlayerTableRow = ({ rowData, labelId, onAvailabilityClick, isUserCaptain, isUserTeamMember }) => {
    const {
        doublesUtr,
        id,
        teamMemberId,
        name,
        playerAvailabilityStatusTypeId,
        profileImage,
        singlesRecord,
        doublesRecord,
        singlesUtr,
        utrp
    } = rowData;
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_BOOK } = TypographySizes;
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;
    const playerImage = getPlayerAvatarImage(profileImage);

    const handleOnAvailabilityClick = () => {
        onAvailabilityClick(teamMemberId);
    };

    return (
        <>
            <TableRow tabIndex={-1} key={id} sx={{ backgroundColor: 'white' }}>
                <TableCell sx={{ maxWidth: 200, color: 'black', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', }} component="th" id={labelId} scope="row" padding="normal">
                    <AvatarWithName imgSrc={playerImage} playerName={name} />
                </TableCell>
                <TableCell sx={{ border: 'none' }} component="th" id={labelId} scope="row" padding="normal">
                    {(isUserCaptain || isUserTeamMember) && (
                        <PlayerAvailabilityIndicator onAvailabilityClick={handleOnAvailabilityClick} availabilityStatus={playerAvailabilityStatusTypeId} isIconOnly={!isUserCaptain} />
                    )}
                </TableCell>
                {isTennisMatch ? (
                    <>
                        <TableCell sx={{ border: 'none' }} align="center">
                            <Typography color={theme.appColors.grey} category={SECONDARY} size={X_SMALL_BOOK}>{singlesUtr}</Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }} align="center">
                            <Typography color={theme.appColors.grey} category={SECONDARY} size={X_SMALL_BOOK}>{doublesUtr}</Typography>
                        </TableCell>
                    </>
                ) : (
                    <TableCell sx={{ border: 'none' }} align="center">
                        <Typography color={theme.appColors.grey} category={SECONDARY} size={X_SMALL_BOOK}>{utrp}</Typography>
                    </TableCell>
                )}
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.grey} category={SECONDARY} size={X_SMALL_BOOK}>{singlesRecord}</Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.grey} category={SECONDARY} size={X_SMALL_BOOK}>{doublesRecord}</Typography>
                </TableCell>
            </TableRow>

        </>
    );
};

const TeamTabTable = ({ headCells = [], tableData = [], isLoading, onAvailabilityClick, isUserCaptain, isUserTeamMember }) => {
    const { SECONDARY } = SharedUICategories;
    const { XX_SMALL_MEDIUM_CAP } = TypographySizes;

    return (
        <>
            <Paper sx={{ mb: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
                <TableContainer>
                    {isLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <Table sx={{ minWidth: 750, borderCollapse: 'separate', borderSpacing: '0px 4px' }} aria-labelledby="tableTitle" size="large">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'transparent' }}>
                                    {headCells.map((headCell, index) => (
                                        <TableCell
                                            sx={{ border: 'none', py: '6px' }}
                                            key={headCell.id}
                                            align={index === 0 ? 'left' : 'center'}
                                            padding="normal"
                                        >
                                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP}>{headCell.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData &&
                                    tableData
                                        .map((data, index) => {
                                            const labelId = `table-${index}`;
                                            return (
                                                <PlayerTableRow
                                                    onAvailabilityClick={onAvailabilityClick}
                                                    key={data.id}
                                                    rowData={data}
                                                    labelId={labelId}
                                                    isUserCaptain={isUserCaptain}
                                                    isUserTeamMember={isUserTeamMember}
                                                />
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
            </Paper>
        </>
    );
};

export default TeamTabTable;
