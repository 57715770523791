import React from 'react';

import { useTheme, Container } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { SharedUICategories, TypographySizes, LeagueTabs } from '../../../utils/constants';
import { getPowerIndex } from '../../../utils/helpers';
import useIsMobile from '../../../hooks/useIsMobile';
import { teamPageHeaderPropTypes } from '../../../utils/proptypes';

const TeamPageHeader = ({
    tab,
    teamName,
    wins,
    losses,
    sessionId,
    sessionName,
    isCaptainView,
    onTabChange,
    isTournament,
    teamPowerIndex,
}) => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const { DISPLAY, SECONDARY } = SharedUICategories;
    const { LARGE_LIGHT, X_SMALL_MEDIUM_CAP } = TypographySizes;
    const { OVERVIEW, PLAYERS, DETAILS, SCHEDULE, DRAW } = LeagueTabs;
    const mobileAndDesktopCategory = isMobile ? SECONDARY : DISPLAY;
    const mobileAndDesktopSize = isMobile ? X_SMALL_MEDIUM_CAP : LARGE_LIGHT;
    const mobileDisplay = isMobile && 'flex';
    const mobileSpacing = isMobile && 0.5;
    const mobileColon = isMobile && ':';
    const record = `${wins || 0} - ${losses || 0}`;
    const MATCH_TAB = isTournament ? DRAW : SCHEDULE;
    const sessionLink = `/leagues/session/${sessionId}`;
    const singlesPowerRating = getPowerIndex(teamPowerIndex?.singlesRating, teamPowerIndex?.isSinglesEstimated);
    const doublesPowerRating = getPowerIndex(teamPowerIndex?.doublesRating, teamPowerIndex?.isDoublesEstimated);

    return (
        <>
            <Box bgcolor="black">
                <Container maxWidth="lg">
                    <Box py={2} flexWrap="wrap" display="flex" justifyContent="space-between">
                        <Box maxWidth="80%">
                            <Link href={sessionLink}>
                                <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                    {sessionName}
                                </Typography>
                            </Link>
                            <Typography color={theme.appColors.white} category={DISPLAY} size={LARGE_LIGHT} lineHeight={1}>
                                {teamName}
                            </Typography>
                        </Box>
                        <Box display="flex" gap={4}>
                            <Box display={mobileDisplay} gap={mobileSpacing} alignItems="center">
                                <Typography color={theme.appColors.lightBlue} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                    TPI S{mobileColon}
                                </Typography>
                                <Typography
                                    color={teamPowerIndex?.singlesRating ? theme.appColors.white : theme.appColors.grey}
                                    category={mobileAndDesktopCategory}
                                    size={mobileAndDesktopSize}
                                    lineHeight={1}
                                >
                                    {singlesPowerRating}
                                </Typography>
                            </Box>
                            <Box display={mobileDisplay} gap={mobileSpacing} alignItems="center">
                                <Typography color={theme.appColors.lightBlue} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                    TPI D{mobileColon}
                                </Typography>
                                <Typography
                                    color={teamPowerIndex?.doublesRating ? theme.appColors.white : theme.appColors.grey}
                                    category={mobileAndDesktopCategory}
                                    size={mobileAndDesktopSize}
                                    lineHeight={1}
                                >
                                    {doublesPowerRating}
                                </Typography>
                            </Box>
                            <Box display={mobileDisplay} gap={mobileSpacing} alignItems="center">
                                <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                    Record{mobileColon}
                                </Typography>
                                <Typography
                                    color={theme.appColors.white}
                                    category={mobileAndDesktopCategory}
                                    size={mobileAndDesktopSize}
                                    lineHeight={1}
                                >
                                    {record}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box bgcolor={theme.appColors.white}>
                <Container maxWidth="lg">
                    <Tabs variant="scrollable" value={tab} onChange={onTabChange} aria-label="team profile tabs">
                        <Tab label={OVERVIEW} value={OVERVIEW} />
                        <Tab label={MATCH_TAB} value={MATCH_TAB} />
                        <Tab label={PLAYERS} value={PLAYERS} />
                        {isCaptainView && <Tab label={DETAILS} value={DETAILS} />}
                    </Tabs>
                </Container>
            </Box>
        </>
    );
};

TeamPageHeader.propTypes = {
    ...teamPageHeaderPropTypes,
};

export default TeamPageHeader;
