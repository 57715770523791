import React from 'react';

import Box from '@mui/material/Box';

import { PostScoreInput } from '../../../../components/Styled';
import { ScorePositionKeys } from '../../../../utils/constants';

const PickleballGame = ({ onScoreChange, game, isDisabled }) => {
    const { id, position1Score, position2Score } = game;
    const { POSITION_ONE_SCORE, POSITION_TWO_SCORE } = ScorePositionKeys;

    const handleOnScoreChange = (changedGame, positionKey, event, isTiebreaker = false) => {
        const { value } = event.target;
        onScoreChange(changedGame, positionKey, value, isTiebreaker);
    };

    return (
        <div>
            <Box mb={3} position="relative">
                <PostScoreInput
                    label={`G${id}`}
                    disabled={isDisabled}
                    setid={id}
                    type="number"
                    onChange={(event) => handleOnScoreChange(game, POSITION_TWO_SCORE, event)}
                    placeholder="0"
                    value={position2Score}
                />
            </Box>
            <Box mt={3} position="relative">
                <PostScoreInput
                    label={`G${id}`}
                    disabled={isDisabled}
                    setid={id}
                    type="number"
                    onChange={(event) => handleOnScoreChange(game, POSITION_ONE_SCORE, event)}
                    placeholder="0"
                    value={position1Score}
                />
            </Box>
        </div>
    );
};

export default PickleballGame;
