import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PlayerActionTypes } from '../../utils/types';

const ITEM_HEIGHT = 48;

const MoreMenu = ({ options, onOptionSelected, disableMove, disableCopy, isSubstitute }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { MOVE_TO_TEAM, COPY_TO_TEAM, MARK_AS_ROSTERED, MARK_AS_SUBSTITUTE } = PlayerActionTypes;
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOnItemSelected = (event) => {
        onOptionSelected(event.target.value);
        setAnchorEl(null);
    };

    const handleOnClose = () => {
        setAnchorEl(null);
    };

    const filteredOptions = isSubstitute
        ? options.filter((option) => option.id !== MARK_AS_SUBSTITUTE)
        : options.filter((option) => option.id !== MARK_AS_ROSTERED);

    return (
        <div>
            <IconButton
                aria-label="more"
                id="more-menu-button"
                aria-controls={open ? 'more-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="more-menu"
                MenuListProps={{
                    'aria-labelledby': 'more-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleOnClose}
                PaperProps={{
                    style: { maxHeight: ITEM_HEIGHT * 4.5 },
                }}
            >
                {filteredOptions.map((option) => (
                    <MenuItem
                        disabled={(option.id === MOVE_TO_TEAM && disableMove) || (option.id === COPY_TO_TEAM && disableCopy)}
                        key={option.id}
                        value={option.id}
                        onClick={handleOnItemSelected}
                    >
                        {option.description}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default MoreMenu;
