import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSessionRounds } from '../utils/sessions';
import { showErrorPopup } from '../redux/errorPopupSlice';
import { showPopup } from '../redux/genericPopupSlice';
import {
    useGetSessionScheduleQuery,
    useLazyGetSessionScheduleByDateQuery,
    useCreateScheduleMutation,
    useRemoveScheduleMutation,
    useUpdateUnpublishScheduleMutation,
    usePublishScheduleMutation
} from '../api/leaguesApi';
import { ResponseStatuses } from '../utils/constants';
import { ScheduleStatuses, MultiRoundScheduleTypes } from '../utils/types';

const useSessionSchedule = ({ sessionId, showDraftSchedule, isMultiRoundSession, skipFetch = false }) => {
    const dispatch = useDispatch();
    const { NOT_FOUND } = ResponseStatuses;
    const { PUBLISHED } = ScheduleStatuses;
    const { BY_ROUND, BY_DATE } = MultiRoundScheduleTypes;
    const [drawSchedule, setDrawSchedule] = useState();
    const [hasPostedScores, setHasPostedScores] = useState(false);
    const [isScheduleCreated, setIsScheduleCreated] = useState(false);
    const [isSchedulePublished, setIsSchedulePublished] = useState(false);
    const [multiRoundScheduleType, setMultiRoundScheduleType] = useState(BY_ROUND);
    const [scheduleByDate, setScheduleByDate] = useState();
    const [scheduleId, setScheduleId] = useState();
    const [scheduleRounds, setScheduleRounds] = useState();

    const {
        data: schedule,
        isFetching: isFetchingSchedule,
        refetch: refetchSessionSchedule,
        error: scheduleError,
    } = useGetSessionScheduleQuery({ sessionId, showDraftSchedule }, { skip: isMultiRoundSession === null || skipFetch });
    const [getScheduleByDate, { data: dateSchedule, isFetching: isFetchingScheduleByDate, error: dateScheduleError, }] = useLazyGetSessionScheduleByDateQuery();
    const [createSchedule, { isLoading: isCreatingSchedule }] = useCreateScheduleMutation();
    const [removeSchedule] = useRemoveScheduleMutation();
    const [unpublishSchedule] = useUpdateUnpublishScheduleMutation();
    const [publishSchedule, { isLoading: isPublishingSchedule }] = usePublishScheduleMutation();
    const isLoadingSchedule = isFetchingSchedule || isFetchingScheduleByDate || isPublishingSchedule || isCreatingSchedule;

    const getMappedSchedule = (data) => {
        if (isMultiRoundSession) {
            setScheduleRounds(getSessionRounds(data));
        } else {
            setDrawSchedule(data);
        }
    };

    const resetSchedule = () => {
        setIsScheduleCreated(false);
        setDrawSchedule(null);
        setScheduleRounds(null);
        setScheduleByDate(null);
        setScheduleId(null);
        setMultiRoundScheduleType(BY_ROUND);
    };

    const onCreateSchedule = async () => {
        try {
            const scheduleData = await createSchedule({ sessionId }).unwrap();
            if (scheduleData) {
                getMappedSchedule(scheduleData);
                setScheduleId(scheduleData?.scheduleId);
                setIsScheduleCreated(true);
                setIsSchedulePublished(false);
            }
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const onDeleteSchedule = async () => {
        try {
            await removeSchedule({ scheduleId, sessionId });
            resetSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const onPublishSchedule = async (scheduleData) => {
        const scheduleType = isMultiRoundSession ? 'schedule' : 'draw';
        try {
            await publishSchedule({ scheduleId, sessionId, data: scheduleData }).unwrap();
            setIsSchedulePublished(true);
            dispatch(showPopup({ title: 'Success!', message: `The session ${scheduleType} has been published!` }));
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const onUnpublishSchedule = async () => {
        try {
            await unpublishSchedule({ scheduleId, sessionId });
            setIsSchedulePublished(false);
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const onGetScheduleByDate = async (scheduleType) => {
        if (scheduleType === BY_DATE) {
            try {
                const response = await getScheduleByDate({ sessionId, showDraftSchedule });
                if (response) {
                    setScheduleByDate(response?.data);
                }
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }

        setMultiRoundScheduleType(scheduleType);
    };

    if (dateScheduleError) {
        const hasError = dateScheduleError?.status !== NOT_FOUND;
        if (hasError) {
            dispatch(showErrorPopup(dateScheduleError));
        }
    }

    useEffect(() => {
        if (schedule) {
            const { scheduleId: id, hasPostedScores: hasScoresPosted, statusId } = schedule || {};
            setHasPostedScores(hasScoresPosted);
            setScheduleId(id);
            setIsSchedulePublished(statusId === PUBLISHED);
            setIsScheduleCreated(true);
            getMappedSchedule(schedule);
        }

        if (dateSchedule) {
            const { scheduleId: id, hasPostedScores: hasScoresPosted, statusId } = dateSchedule || {};
            setScheduleByDate(dateSchedule);
            setHasPostedScores(hasScoresPosted);
            setScheduleId(id);
            setIsSchedulePublished(statusId === PUBLISHED);
        }
    }, [schedule, dateSchedule]);

    useEffect(() => {
        if (scheduleError) {
            if (scheduleError?.status === NOT_FOUND) {
                resetSchedule();
            } else {
                dispatch(showErrorPopup(scheduleError));
            }
        }
    }, [scheduleError]);

    return {
        scheduleId,
        scheduleRounds,
        scheduleByDate,
        drawSchedule,
        hasPostedScores,
        isCreatingSchedule,
        isLoadingSchedule,
        isScheduleCreated,
        isSchedulePublished,
        isPublishingSchedule,
        onCreateSchedule,
        onDeleteSchedule,
        onPublishSchedule,
        onUnpublishSchedule,
        onGetScheduleByDate,
        refetchSessionSchedule,
        multiRoundScheduleType
    };
};

export default useSessionSchedule;
