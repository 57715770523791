import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Typography } from '@universal-tennis/ui-shared';

import { useSelector } from 'react-redux';
import SubmitPaymentForm from '../SubmitPaymentForm';
import { registrationConfirmationPropTypes } from '../../../utils/proptypes';

const RegistrationConfirmation = ({ registrationFees, clientSecret, onRegistrationSuccess, sessionId }) => {
    const { user } = useSelector((state) => state.auth);
    const { sessionEntryFee, leagueFee, serviceFee = 0, currencySymbol, total } = registrationFees || '';
    const formattedServiceFee = serviceFee?.toFixed(2);
    const formattedTotalFee = total?.toFixed(2);
    const finalCurrencySymbol = currencySymbol ?? '$';
    const PAID_LEAGUE = 'paid_league';
    const FREE_LEAGUE = 'free-league';
    const isPaidLeague = leagueFee + sessionEntryFee > 0;
    const segmentTrackingBody = {
        league_session_id: sessionId,
        member_id: user?.id,
        order_type: isPaidLeague ? PAID_LEAGUE : FREE_LEAGUE,
        currency: finalCurrencySymbol,
        session_fee: sessionEntryFee,
        league_fee: leagueFee,
        service_fee: serviceFee,
        amount_paid: total,
        revenue: total,
    };

    useEffect(() => {
        if (window?.analytics) {
            window.analytics.track('Checkout Started', segmentTrackingBody);
        }
    }, []);

    return (
        <Box mt={4}>
            <Box pt={2} pb={4}>
                <Box display="flex" justifyContent="space-between">
                    <Typography category="secondary" size="small-book">Session Fee</Typography>
                    <Typography category="secondary" size="small-book">{finalCurrencySymbol}{sessionEntryFee}</Typography>
                </Box>
                <Box mb={serviceFee > 0 ? 0 : 2} display="flex" justifyContent="space-between">
                    <Typography category="secondary" size="small-book">League Fee</Typography>
                    <Typography category="secondary" size="small-book">{finalCurrencySymbol}{leagueFee}</Typography>
                </Box>
                {serviceFee > 0 && (
                    <Box mb={2} display="flex" justifyContent="space-between">
                        <Typography category="secondary" size="small-book">Service Fee</Typography>
                        <Typography category="secondary" size="small-book">{finalCurrencySymbol}{formattedServiceFee}</Typography>
                    </Box>
                )}
                <Divider />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Typography category="secondary" size="small-medium">Total</Typography>
                    <Typography category="secondary" size="small-medium">{finalCurrencySymbol}{formattedTotalFee}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography category="secondary" size="large-medium">Payment Information</Typography>
                <SubmitPaymentForm clientSecret={clientSecret} onRegistrationSuccessful={onRegistrationSuccess} segmentTrackingBody={segmentTrackingBody} />
            </Box>
        </Box>
    );
};

RegistrationConfirmation.propTypes = {
    ...registrationConfirmationPropTypes,
};

export default RegistrationConfirmation;
