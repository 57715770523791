/* eslint-disable global-require */
/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
import Rox from 'rox-browser';
import { getFlagsContext } from './utils/rollout';

class UtrRoxFlag extends Rox.Flag {
    isEnabled(user) {
        return super.isEnabled(getFlagsContext(user));
    }
}

export default {
    messaging: new UtrRoxFlag(),
    personalClub: new UtrRoxFlag(),
    createPlayerGroupMainMenu: new UtrRoxFlag(),
    ratingsListFlag: new UtrRoxFlag(),
};
