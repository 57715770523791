import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@universal-tennis/ui-shared';

import UIButton from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { PlayerAvailabilityStatusTypes, PlayerAvailabilityStatus, PlayerAvailabilityColors } from '../../utils/types';
import { SharedUICategories, TypographySizes } from '../../utils/constants';

const PlayerAvailabilityResponse = ({ onAvailabilityChange, currentUserData, error }) => {
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP } = TypographySizes;
    const { YES, MAYBE, NO } = PlayerAvailabilityStatusTypes;
    const [anchorEl, setAnchorEl] = useState(null);
    const [availabilityStatus, setAvailabilityStatus] = useState();
    const [userTeamMemberId, setUserTeamMemberId] = useState();
    const [showAvailabilityOptions, setShowAvailabilityOptions] = useState(false);
    const [showRsvpStatus, setShowRsvpStatus] = useState(false);
    const [buttonColor, setButtonColor] = useState('');
    const open = Boolean(anchorEl);

    const getColorForAvailabilityStatus = (status) => {
        switch (status) {
            case YES:
                return PlayerAvailabilityColors.GREEN;
            case MAYBE:
                return PlayerAvailabilityColors.ORANGE;
            case NO:
                return PlayerAvailabilityColors.RED;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (currentUserData) {
            const { playerAvailabilityStatusTypeId, teamMemberId } = currentUserData || {};
            const isUserMemberOfTeam = !!teamMemberId;

            if (isUserMemberOfTeam && playerAvailabilityStatusTypeId) {
                setShowRsvpStatus(true);
                setShowAvailabilityOptions(false);
                setAvailabilityStatus(playerAvailabilityStatusTypeId);
                const color = getColorForAvailabilityStatus(playerAvailabilityStatusTypeId);
                setButtonColor(color);
            } else if (isUserMemberOfTeam && !playerAvailabilityStatusTypeId) {
                setShowAvailabilityOptions(true);
            }
            setUserTeamMemberId(teamMemberId);
        }
    }, [currentUserData]);

    useEffect(() => {
        if (error) {
            const { playerAvailabilityStatusTypeId } = currentUserData || {};
            if (currentUserData?.playerAvailabilityStatusTypeId) {
                setAvailabilityStatus(playerAvailabilityStatusTypeId);
                const color = getColorForAvailabilityStatus(playerAvailabilityStatusTypeId);
                setButtonColor(color);
            } else {
                setShowAvailabilityOptions(true);
                setShowRsvpStatus(false);
            }
        }
    }, [error]);

    const handleOnRsvpClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOnAvailabilityChange = (status) => {
        const color = getColorForAvailabilityStatus(status);

        setButtonColor(color);
        setAvailabilityStatus(status);

        if (showAvailabilityOptions) {
            setShowAvailabilityOptions(false);
            setShowRsvpStatus(true);
        }

        if (anchorEl) {
            setAnchorEl(null);
        }

        onAvailabilityChange({ teamMemberId: userTeamMemberId, availabilityStatus: status });
    };

    const handleOnClose = () => {
        setAnchorEl(null);
    };
    const rsvpStatus = PlayerAvailabilityStatus[availabilityStatus];
    return (
        <>
            {showRsvpStatus && (
                <>
                    <UIButton
                        id="rsvp-button"
                        aria-controls={open ? 'rsvp-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{ borderRadius: 5 }}
                        color={buttonColor}
                        variant="outlined"
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={handleOnRsvpClick}
                    >
                        <Typography size={X_SMALL_MEDIUM_CAP} category={SECONDARY}>{`RSVP:${rsvpStatus}`}</Typography>
                    </UIButton>
                    <Menu
                        id="rsvp-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleOnClose}
                        MenuListProps={{ 'aria-labelledby': 'rsvp-button' }}
                    >
                        <MenuItem onClick={() => handleOnAvailabilityChange(YES)}>
                            <Typography size={X_SMALL_MEDIUM_CAP} category={SECONDARY}>Yes</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => handleOnAvailabilityChange(MAYBE)}>
                            <Typography size={X_SMALL_MEDIUM_CAP} category={SECONDARY}>Maybe</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => handleOnAvailabilityChange(NO)}>
                            <Typography size={X_SMALL_MEDIUM_CAP} category={SECONDARY}>No</Typography>
                        </MenuItem>
                    </Menu>
                </>

            )}
            {showAvailabilityOptions && (
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => handleOnAvailabilityChange(YES)} category={SECONDARY}>
                        Yes
                    </Button>
                    <Button onClick={() => handleOnAvailabilityChange(MAYBE)} category={SECONDARY}>
                        Maybe
                    </Button>
                    <Button onClick={() => handleOnAvailabilityChange(NO)} category={SECONDARY}>
                        No
                    </Button>
                </Stack>
            )}
        </>
    );
};

export default PlayerAvailabilityResponse;
