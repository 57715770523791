import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button, Typography } from '@universal-tennis/ui-shared';
import Loading from '../LoadingIndicator';
import DrawTable from './DrawTable';
import useIsMobile from '../../hooks/useIsMobile';
import { MAIN_DRAW_TABLE_NUMBER, MANAGE_SESSION_PAGE_TYPE, getRoundOneMainDrawTeamMatches, getTeamIDsFromTeamMatches } from './utils';
import { drawTypes, getTypeForId } from '../../utils/types';

const getHasAllTeamsSelected = ({ selectableTeams, schedule }) => {
    if (!schedule) return false;
    const { draws } = schedule;

    const teamMatches = getRoundOneMainDrawTeamMatches({ draws });
    const teamMatchesIDs = getTeamIDsFromTeamMatches({ teamMatches });
    const selectedTeams = selectableTeams?.filter((team) => teamMatchesIDs?.includes(team.id));

    return selectedTeams?.length === selectableTeams?.length;
};

const getHasATeamInAllMatches = ({ schedule }) => {
    if (!schedule) return false;
    const { draws } = schedule;

    const teamMatches = getRoundOneMainDrawTeamMatches({ draws });
    const matchesWithoutTeams = teamMatches?.filter((match) => !match.position1Team?.teamId && !match.position2Team?.teamId);

    return matchesWithoutTeams?.length === 0;
};

const DrawTab = ({
    onDrawCreateClick,
    onDrawEditClick,
    onDrawPublishClick,
    onDrawUnpublishClick,
    onDrawDeleteClick,
    onEditMatch,
    onViewMatch,
    isLoading,
    isDrawDisabled,
    hasPublishPrivileges,
    hasPostedResults,
    selectableTeams,
    descriptionText,
    schedule,
    hasPublishedSchedule,
    onChangeClick,
    pageType,
}) => {
    const isLoadingInitalState = isLoading && !schedule;
    const isDrawTable = !!schedule;
    const drawType = getTypeForId(schedule?.drawTypeId, drawTypes);
    const isMobile = useIsMobile();
    const hasAllTeamsSelected = useMemo(() => getHasAllTeamsSelected({ selectableTeams, schedule }), [schedule]);
    const hasATeamInAllMatches = useMemo(() => getHasATeamInAllMatches({ schedule }), [schedule]);
    const totalRounds = schedule?.draws?.reduce((acc, cur) => {
        if (!cur.rounds) return acc;
        return [...acc, ...cur.rounds];
    }, []);

    let isPublishDisabled;
    switch (true) {
        case !hasPublishPrivileges:
            isPublishDisabled = true;
            break;

        case !hasPublishedSchedule && !(hasAllTeamsSelected && hasATeamInAllMatches):
            isPublishDisabled = true;
            break;

        case hasPublishedSchedule && hasPostedResults:
            isPublishDisabled = true;
            break;

        default:
            isPublishDisabled = false;
            break;
    }

    const InitialState = isDrawTable ? (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Grid container>
                    <Grid item xs={5} md={10}>
                        <Typography sx={{ color: '#000' }} category="secondary" size="large-book">
                            {drawType?.description}
                        </Typography>
                        <Typography sx={{ color: '#000' }} category="secondary" size="medium-book">
                            {descriptionText}
                        </Typography>
                        <Button disabled={hasPublishedSchedule || hasPostedResults} onClick={onDrawEditClick} category="link">
                            Edit seeds
                        </Button>
                    </Grid>
                    <Grid sx={{ display: 'flex', justifyContent: isMobile ? 'start' : 'end' }} item xs={5} md={2}>
                        <Button disabled={hasPublishedSchedule} sx={{ marginRight: isMobile ? '4px' : '16px' }} onClick={onDrawDeleteClick} category="secondary">
                            Delete
                        </Button>
                        <Button sx={{ minWidth: isMobile ? '70%' : 'auto' }} disabled={isPublishDisabled} onClick={hasPublishedSchedule ? onDrawUnpublishClick : onDrawPublishClick} category="primary">
                            {hasPublishedSchedule ? 'Unpublish' : 'Publish'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            {schedule?.draws?.map((draw) => {
                if (pageType === MANAGE_SESSION_PAGE_TYPE && draw.number !== 1 && !hasPublishedSchedule) return;
                if (pageType !== MANAGE_SESSION_PAGE_TYPE && !hasPublishedSchedule) return;

                return (
                    <DrawTable
                        key={draw.id}
                        draw={draw}
                        selectableTeams={selectableTeams}
                        schedule={schedule}
                        onEditMatch={onEditMatch}
                        onViewMatch={onViewMatch}
                        onChangeClick={onChangeClick}
                        hasPublishedSchedule={hasPublishedSchedule}
                        isManaging
                        isMainDraw={draw.number === MAIN_DRAW_TABLE_NUMBER}
                        isLoading={isLoading}
                        isMobile={isMobile}
                        totalRounds={totalRounds}
                    />
                );
            })}
        </Box>
    ) : (
        <Box mt={4} sx={{ textAlign: 'center', color: '#000', width: '600px' }}>
            <Typography category="secondary" size="large-medium">
                Create a tournament draw
            </Typography>

            <Typography pt={2} pb={4} category="secondary" size="medium-book">
                A draw structures how your tournament is played. A draw can be created once team registration has closed.
            </Typography>

            <Button disabled={isDrawDisabled} onClick={onDrawCreateClick} sx={{ width: '50%' }} category="primary">
                Create Draw
            </Button>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {isLoadingInitalState ? <Loading /> : InitialState}
        </Box>

    );
};

export default DrawTab;
