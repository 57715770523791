import React from 'react';

import { useTheme } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TypographySizes, SharedUICategories } from '../../../../utils/constants';

const TeamPageDrawTableSelect = ({ selectedValue = true, onChange }) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { SMALL_BOOK } = TypographySizes;
    return (
        <Box pb="24px" mb="34px" borderBottom={`solid 4px ${theme.appColors.white}`}>
            <FormControl>
                <Select
                    labelId="team-page-draw-select-label"
                    id="team-page-draw-select"
                    value={selectedValue}
                    onChange={onChange}
                    sx={{ backgroundColor: theme.appColors.white, width: '200px' }}
                >
                    <MenuItem value><Typography category={SECONDARY} size={SMALL_BOOK}>Main Draw</Typography></MenuItem>
                    <MenuItem value={false}><Typography category={SECONDARY} size={SMALL_BOOK}>Back Draw</Typography></MenuItem>
                </Select>
            </FormControl>
        </Box>

    );
};

export default TeamPageDrawTableSelect;
