import { createTheme } from '@mui/material';

const appColors = {
    black: '#000',
    white: '#fff',
    blue: '#008ded',
    lightBlue: '#62E6FC',
    blueGrey: '#778DA9',
    blueGrey100: '#D6DDE5',
    lighterGrey: '#e8eff7',
    lightGrey: '#d7e0ea',
    grey: '#828B96',
    darkGrey: '#5a7290',
    darkestGrey: '#3b4859',
    gradientBlue: '#00c0f7',
    secondaryButtonDisabled: '#bac6d6',
    gradientTurquoise: '#01dfcb',
    main: '#008ded',
    darkText: '#2b3339',
    coolGrey: '#F0F4F9',
    coolGrey500: '#C1CCDA',
    coolGrey600: '#A7B2C1',
    interactionBlue: '#007BCE',
    red: '#E0004F',
    green: '#108B15',
    lighterGreen: '#16BA1C',
    orange: '#FB7800',
    gradient: 'linear-gradient(to right, #00c0f7, #01dfcb)',

};

const secondaryFont = 'Gotham SSm A, Gotham SSm B, Helvetica, Arial, sans-serif';
const primaryFont = 'Tungsten A, Tungsten B, Helvetica, Arial, sans-serif';
const appGradient = `linear-gradient(to right, ${appColors.gradientBlue}, ${appColors.gradientTurquoise})`;
const tabButtonSidePadding = '16px';

const defaultButtonStyle = {
    borderRadius: 22,
    fontSize: 12,
    padding: '0 22px',
    height: '40px',
    whiteSpace: 'nowrap',
    lineHeight: '39px',
    border: '1px solid transparent',
};

const darkButtonStyle = {
    ...defaultButtonStyle,
    backgroundColor: appColors.black,
    color: appColors.white,
    '&:hover': {
        backgroundColor: appColors.darkestGrey,
        borderColor: appColors.darkestGrey,
    },
    '&:disabled': {
        backgroundColor: appColors.lightGrey,
        borderColor: appColors.lightGrey,
    },
};

const lightButtonStyle = {
    ...defaultButtonStyle,
    borderColor: appColors.black,
    backgroundColor: 'transparent',
    color: appColors.black,
    '&:hover': {
        borderColor: appColors.darkestGrey,
    },
    '&:disabled': {
        borderColor: appColors.secondaryButtonDisabled,
        color: appColors.secondaryButtonDisabled,
    },
};

const whiteButtonStyle = {
    ...defaultButtonStyle,
    borderColor: appColors.white,
    borderWidth: 2,
    backgroundColor: 'transparent',
    color: appColors.white,
    '&:disabled': {
        backgroundColor: appColors.lightGrey,
        borderColor: appColors.lightGrey,
    },
};

const filterButtonStyle = {
    ...defaultButtonStyle,
    backgroundColor: 'transparent',
    color: appColors.blue,
    borderColor: appColors.blue,
    '&:disabled': {
        backgroundColor: appColors.lightGrey,
        borderColor: appColors.lightGrey,
    },
};

const filterSelectedButtonStyle = {
    ...defaultButtonStyle,
    backgroundColor: appColors.blue,
    color: appColors.white,
    '&:disabled': {
        backgroundColor: appColors.lightGrey,
        borderColor: appColors.lightGrey,
    },
};

const smallButtonStyle = {
    ...defaultButtonStyle,
    fontSize: 11,
    height: 35,
    lineHeight: '34px',
};

const largeButtonStyle = {
    ...defaultButtonStyle,
    fontSize: 12,
    lineHeight: '43px',
    borderRadius: 24,
    padding: '0 50px',
};

let theme = createTheme({
    appColors,
    typography: {
        primary: {
            fontFamily: primaryFont
        },
        secondary: {
            fontFamily: secondaryFont
        },
        fontFamily: secondaryFont,
    },
    palette: {
        primary: {
            main: appColors.main,
        },
    },
});

theme = createTheme(theme, {
    palette: {
        rsvpGreen: {
            main: appColors.green
        },
        rsvpOrange: {
            main: appColors.orange
        },
        rsvpRed: {
            main: appColors.red
        },
    },
    typography: {
        subtitle2: {
            lineHeight: '1.71',
            letterSpacing: '0.8px',
            color: appColors.blue,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: theme.typography.fontWeightLight,
            lineHeight: '0.9',
            letterSpacing: '1px',
        },
        h3: {
            fontSize: '2.5rem',
            fontWeight: theme.typography.fontWeightLight,
            lineHeight: '0.9',
            letterSpacing: '1px',
        },
        h3_grey: {
            fontSize: '2.5rem',
            fontWeight: theme.typography.fontWeightLight,
            lineHeight: '0.9',
            letterSpacing: '1px',
            color: appColors.darkGrey,
        },
        h4_grey: {
            fontSize: '2.125rem',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '1.235',
            letterSpacing: '1px',
            color: appColors.grey,
        },
        h5_grey: {
            fontSize: '1.5rem',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '1.35',
            letterSpacing: '1px',
            color: appColors.grey,
        },
        subHeading: {
            fontSize: '1.25rem',
            fontWeight: theme.typography.fontWeightLight,
            lineHeight: '1',
            letterSpacing: '1px',
            color: appColors.darkText,
        },
        caption: {
            fontSize: '0.9rem',
        },
        caption_blue: {
            fontSize: '1.5rem',
            color: appColors.gradientBlue,
        },
        body1: {
            fontWeight: theme.typography.fontWeightLight,
        },
        details: {
            fontSize: '0.875rem',
            lineHeight: '1.43',
        },
        details_grey: {
            fontSize: '0.875rem',
            lineHeight: '1.43',
            color: appColors.darkGrey,
        },
        cardSubtitle: {
            fontSize: '0.8rem',
            color: appColors.grey,
        },
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiTab-root': {
                        color: appColors.blue,
                        textTransform: 'capitalize',
                        '&.Mui-selected': {
                            color: theme.palette.common.black,
                        },
                    },
                },
            },
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: { ...darkButtonStyle },
                },
                {
                    props: { variant: 'dark', size: 'large' },
                    style: { ...darkButtonStyle, ...largeButtonStyle },
                },
                {
                    props: { variant: 'dark', size: 'small' },
                    style: { ...darkButtonStyle, ...smallButtonStyle },
                },
                {
                    props: { variant: 'light' },
                    style: { ...lightButtonStyle },
                },
                {
                    props: { variant: 'light', size: 'large' },
                    style: { ...lightButtonStyle, ...largeButtonStyle },
                },
                {
                    props: { variant: 'light', size: 'small' },
                    style: { ...lightButtonStyle, ...smallButtonStyle },
                },
                {
                    props: { variant: 'contained' },
                    style: { color: theme.palette.common.white },
                },
                {
                    props: { variant: 'filter' },
                    style: { ...filterButtonStyle },
                },
                {
                    props: { variant: 'filter-selected' },
                    style: { ...filterSelectedButtonStyle },
                },
                {
                    props: { variant: 'white' },
                    style: { ...whiteButtonStyle },
                },
                {
                    props: { variant: 'white', size: 'large' },
                    style: { ...whiteButtonStyle, ...largeButtonStyle },
                },
                {
                    props: { variant: 'white', size: 'small' },
                    style: { ...whiteButtonStyle, ...smallButtonStyle },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: appColors.grey,
                    '&.MuiInputLabel-shrink': {
                        color: appColors.grey,
                        fontSize: 16,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    color: theme.palette.common.white,
                    border: `1px solid ${appColors.grey}`,
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                },
                track: {
                    background: appGradient,
                    borderRadius: 4,
                    height: theme.spacing(),
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'transparent',
                    color: theme.palette.common.black,
                    border: 0,
                    borderBottom: `1px solid`,
                    // this border image allows for the tab underline indicator to only be the width of the button label
                    borderImage: `linear-gradient(to right, transparent ${tabButtonSidePadding}, black ${tabButtonSidePadding}, black calc(100% - ${tabButtonSidePadding}), transparent calc(100% - ${tabButtonSidePadding})) 1 0`,
                    borderImageSlice: 1,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
                    '& .MuiCardContent-root:last-child': {
                        // TODO: figure out why this is not working?!
                        paddingBottom: '16px',
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    width: '120px',
                    color: appColors.black,
                    fontSize: 12,
                    backgroundColor: appColors.lightGrey,
                    '&.Mui-selected, &.Mui-selected:hover': {
                        color: appColors.white,
                        backgroundColor: appColors.black
                    }
                },
            },
        }
    },
});

export default theme;
