import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

const CompletedSessionModal = ({ isOpen, onClose }) => {
    const verifiedRulesLink = 'https://www.utrsports.net/verified';

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Dialog sx={{ p: 5 }} open={isOpen} onClose={handleOnClose}>
            <DialogTitle align="center">Verified Session Complete</DialogTitle>
            <DialogContent>
                <Typography>
                    {`Editing scores for this team match is closed as its verified session ended more than 3 days ago. For questions, please view the `}
                    <Link rel="noopener" target="_blank" href={verifiedRulesLink}>
                        verifed rules
                    </Link>
                    .
                </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                <Button variant="dark" onClick={handleOnClose}>
                    Got it
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompletedSessionModal;
