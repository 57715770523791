import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { BreadcrumbContexts } from '../../utils/constants';
import { getBreadcrumbData } from '../../redux/services/breadcrumbService';

const Breadcrumbs = ({ context, contextId, shouldUpdateBreadcrumb, onBreadcrumbUpdated, isAdmin = false }) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.breadcrumbs);
    const { CONFERENCE, SESSION, TEAM } = BreadcrumbContexts;
    const { league, conference, session, team } = data;
    const leagueUrl = `/leagues/${league?.id}`;
    const conferenceUrl = `/leagues/conference/${conference?.id}/manage`;
    const sessionUrl = isAdmin ? `/leagues/session/${session?.id}/manage` : `/leagues/session/${session?.id}`;

    useEffect(() => {
        if (context && contextId) {
            if (!data || data[context]?.id !== parseInt(contextId)) {
                dispatch(getBreadcrumbData({ context, contextId }));
            }
        }
    }, []);

    useEffect(() => {
        if (shouldUpdateBreadcrumb) {
            dispatch(getBreadcrumbData({ context, contextId }));
            onBreadcrumbUpdated();
        }
    }, [shouldUpdateBreadcrumb]);

    const getLastLabel = () => {
        let label;
        switch (context) {
            case CONFERENCE:
                label = conference?.name;
                break;
            case SESSION:
                label = session?.name;
                break;
            case TEAM:
                label = team?.name;
                break;
            default:
                label = '';
        }
        return label;
    };

    const renderBreadcrumb = (url, name, isCurrentContext, isBelowContext) => {
        if (isCurrentContext) {
            return <Typography>{getLastLabel()}</Typography>;
        }

        if (isBelowContext) {
            return <Link to={url}>{name}</Link>;
        }

        return null;
    };

    const isConferenceContext = conference?.id && conference?.name && context === CONFERENCE;
    const isBelowConferenceContext = conference?.id && conference?.name && (context === SESSION || context === TEAM);
    const isSessionContext = session?.id && session?.name && context === SESSION;
    const isBelowSessionContext = session?.id && session?.name && context === TEAM;
    const isTeamContext = team?.id && team?.name && context === TEAM;

    return (
        <div role="presentation">
            {loading && (
                <Box sx={{ width: '10%' }}>
                    <LinearProgress />
                </Box>
            )}
            {data && (
                <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
                    {isAdmin && league?.id && league?.name && <Link to={leagueUrl}>{league.name}</Link>}
                    {isAdmin && renderBreadcrumb(conferenceUrl, conference?.name, isConferenceContext, isBelowConferenceContext)}
                    {renderBreadcrumb(sessionUrl, session?.name, isSessionContext, isBelowSessionContext)}
                    {isTeamContext && <Typography>{team?.name}</Typography>}
                </MuiBreadcrumbs>
            )}
        </div>
    );
};

export default Breadcrumbs;
