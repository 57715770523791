import React from 'react';
import { useTheme } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import ManagerCard from '../../../components/Cards/ManagerCard';
import RegistrationInfoCard from '../../../components/RegistrationInfoCard';

import useIsMobile from '../../../hooks/useIsMobile';

import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { sessionOverviewTabPropTypes } from '../../../utils/proptypes';

const OverviewTab = ({
    description = '',
    cost = '',
    sessionDates = '',
    matchFormat = '',
    sessionData = {},
    isUserLoggedIn = false,
    managers = [],
    onRegisterButtonClick,
    onRequestToCaptain,
}) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { XX_SMALL_MEDIUM_CAP, SMALL_BOOK } = TypographySizes;
    const isMobile = useIsMobile();

    const desktopPadding = '40px';
    const mobilePadding = '24px';

    return (
        <Box mt={2} pt="25px">
            <Grid container spacing={5}>
                <Grid item xs={12} md={4.5} sx={{ '&.MuiGrid-item': { pt: 0 } }}>
                    <Box mb={1}>
                        <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.black}>
                            Registration Information
                        </Typography>
                    </Box>
                    <RegistrationInfoCard
                        cost={cost}
                        format={matchFormat}
                        sessionDates={sessionDates}
                        sessionData={sessionData}
                        onRequestToCaptain={onRequestToCaptain}
                        onRegisterButtonClick={onRegisterButtonClick}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7.5}
                    sx={{ '&.MuiGrid-item': { pt: isMobile ? desktopPadding : 0, pl: isMobile ? desktopPadding : mobilePadding } }}
                >
                    {description && (
                        <>
                            <Box mb={1}>
                                <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.black}>
                                    Description
                                </Typography>
                            </Box>
                            <Box mb={3}>
                                <Card sx={{ borderRadius: 0, boxShadow: 'none' }}>
                                    <CardContent>
                                        <Typography category={SECONDARY} size={SMALL_BOOK} color={theme.appColors.black}>
                                            {description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </>
                    )}
                    <Box mb={1}>
                        <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.black}>
                            Organizers
                        </Typography>
                    </Box>
                    <ManagerCard isUserLoggedIn={isUserLoggedIn} managers={managers} />
                </Grid>
            </Grid>
        </Box>
    );
};

OverviewTab.propTypes = {
    ...sessionOverviewTabPropTypes,
};

export default OverviewTab;
