import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import { formSubmitButtonPropTypes, defaultFormSubmitButtonPropTypes } from '../../../utils/proptypes';

const FormSubmitButton = ({ label, isSubmitting, style }) => (
    <Box display="flex" justifyContent="center">
        <LoadingButton
            sx={style}
            disabled={isSubmitting}
            type="submit"
            loading={isSubmitting}
            variant="dark"
            loadingIndicator={<CircularProgress color="primary" size={20} />}
        >
            {label}
        </LoadingButton>
    </Box>
);

FormSubmitButton.propTypes = {
    ...formSubmitButtonPropTypes
};

FormSubmitButton.defaultProps = {
    ...defaultFormSubmitButtonPropTypes
};

export default FormSubmitButton;
