import { cloneDeep } from 'lodash';

const _ = { cloneDeep };

export const ratingStatusPopUpData = {
    rated: {
        title: 'Established Rating',
        description:
            'This player has played enough matches for their rating to be reliable. It usually takes 6-10 matches to get a reliable rating, depending on the reliability of your opponent and how close the score was.',
    },
    unrated: {
        title: 'Unrated',
        description: 'This player has not played any matches or had a rating in the past year.',
    },
    inactive: {
        title: 'Inactive Rating',
        description:
            'This player has not played any matches in the past 12 months. Their rating is the last time they had a rating on UTR.',
    },
    projected: {
        title: 'Projected Rating',
        description:
            'This player hasn’t played enough matches in the last 12 months for their rating to be reliable.  It usually takes 6-10 matches to get a reliable rating, depending on the reliability of your opponent and how close the score was.  As the player plays more matches, the grey reliability bar will fill up around the projected rating.  Once it is full, the rating will become reliable and turn from grey to a colored circle.',
    },
};

const ratedStrings = ['Projected', 'Inactive', 'Rated'];

export const getEventDateString = (eventSchedule, showYear) => {
    const { eventStartDate, eventEndDate, eventStartMonth, eventEndMonth, eventStartYear, eventEndYear } = eventSchedule;
    if (eventStartMonth === eventEndMonth) {
        if (eventStartDate === eventEndDate) {
            return `${eventStartMonth} ${eventStartDate}${showYear ? `, ${eventStartYear}` : ''}`;
        }
        return `${eventStartMonth} ${eventStartDate} - ${eventEndDate}${showYear ? `, ${eventStartYear}` : ''}`;
    }
    return `${eventStartMonth} ${eventStartDate}${showYear ? `, ${eventStartYear}` : ''} - ${eventEndMonth} ${eventEndDate}${
        showYear ? `, ${eventEndYear}` : ''
    }`;
};

const getRatingStatus = (ratingStatus, ratingValue) => {
    if (ratingValue && ratingValue > 0 && ratedStrings.includes(ratingStatus)) {
        return ratingStatus;
    }
    /*
         If rating is 0 even though the rating status is available make it unrated
         and rating status is unrated consider it as unrated and then check for projected status if rating exists
         */
    if (ratingValue && ratingValue > 0) {
        // If unrated and there is a rating condider the rating to be projected
        return 'Projected';
    }
    return 'Unrated';
};

const getRatingProgress = (ratingStatus, ratingValue, ratingProgress) => {
    if (ratingValue && ratingValue > 0 && ratedStrings.includes(ratingStatus)) {
        return ratingProgress;
    }
    if (ratingValue && ratingValue > 0) {
        return 0;
    }
    return ratingProgress;
};

export const getRatingDetails = (rating, userIsPowered, showDecimals) => {
    const object = {};
    let utrValue;
    let verifiedRating = false;

    switch (rating.ratingChoice) {
        case 'SinglesUTR':
            utrValue = rating.myUtrSingles;
            object.status = getRatingStatus(rating.myUtrStatusSingles, rating.myUtrSingles);
            object.matchType = 'singles';
            object.sortValue = object.status === 'Rated' ? 3 : 2;
            object.progress = getRatingProgress(rating.myUtrStatusSingles, rating.myUtrSingles, rating.myUtrProgressSingles);
            break;
        case 'DoublesUTR':
            utrValue = rating.myUtrDoubles;
            object.status = getRatingStatus(rating.myUtrStatusDoubles, rating.myUtrDoubles);
            object.matchType = 'doubles';
            object.sortValue = object.status === 'Rated' ? 3 : 2;
            object.progress = getRatingProgress(rating.myUtrStatusDoubles, rating.myUtrDoubles, rating.myUtrProgressDoubles);
            break;
        case 'VerifiedDoublesUTR':
            utrValue = rating.doublesUtr;
            object.status = getRatingStatus(rating.ratingStatusDoubles, rating.doublesUtr);
            verifiedRating = true;
            object.matchType = 'doubles';
            object.sortValue = object.status === 'Rated' ? 5 : 4;
            object.progress = getRatingProgress(rating.ratingStatusDoubles, rating.doublesUtr, rating.ratingProgressDoubles);
            break;
        default:
            utrValue = rating.singlesUtr;
            object.status = getRatingStatus(rating.ratingStatusSingles, rating.singlesUtr);
            verifiedRating = true;
            object.matchType = 'singles';
            object.sortValue = object.status === 'Rated' ? 5 : 4;
            object.progress = getRatingProgress(rating.ratingStatusSingles, rating.singlesUtr, rating.ratingProgressSingles);
            break;
    }
    if (utrValue && typeof utrValue === 'string') {
        utrValue = parseFloat(utrValue);
    }

    // Text to display what kind of a Rating it is
    if (object.status === 'Projected') {
        object.utrType = 'UTR (P)';
    } else {
        object.utrType = 'UTR';
    }

    // Title text i.e text displayed on Hover
    if (object.status === 'Projected') {
        object.title = 'Projected';
    }

    object.actualValue = utrValue;
    // Display UR for all Unrated conditions
    if (!utrValue || utrValue === 0 || object.status === 'Unrated') {
        object.value = 'UR';
        object.sortValue = 1;
    } else {
        object.value = `${utrValue.toFixed(2)}`;
        if (userIsPowered || showDecimals) {
            object.value = `${utrValue.toFixed(2)}`;
        } else {
            object.value = `${Math.round(utrValue)}`;
        }
    }

    // Get number value of rating
    if (object.value !== 'UR') {
        object.ratingNumber = `${parseInt(object.value)}`;
    } else {
        object.ratingNumber = object.value;
    }

    // Get Decimal value of rating
    const ratingArray = object.value.split('.');
    if (ratingArray.length > 1) {
        [, object.ratingDecimal] = ratingArray;
    } else {
        object.ratingDecimal = '';
    }

    // Logic to show verified match icon
    object.verified = verifiedRating;

    // Info PopUp Data
    object.infoTitle = ratingStatusPopUpData[object.status.toLowerCase()].title;
    object.infoDescription = ratingStatusPopUpData[object.status.toLowerCase()].description;

    return object;
};

export const updateRatingObject = ({ player, ratingType, utrType }) => {
    const unDisplayedStatusStatus = ['Inactive', 'Unrated'];
    const newRatingObject = player ? _.cloneDeep(player) : {};

    // force singles or doubles return
    if (ratingType && utrType) {
        if (ratingType === 'singles') {
            if (utrType === 'sanctioned') {
                newRatingObject.ratingChoice = 'VerifiedSinglesUTR';
            } else {
                newRatingObject.ratingChoice = 'SinglesUTR';
            }
        } else if (utrType === 'sanctioned') {
            newRatingObject.ratingChoice = 'VerifiedDoublesUTR';
        } else {
            newRatingObject.ratingChoice = 'DoublesUTR';
        }
    } else if (ratingType) {
        // only return singles value
        if (ratingType === 'singles') {
            if (player.singlesUtr && player.singlesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusSingles)) {
                newRatingObject.ratingChoice = 'VerifiedSinglesUTR';
            } else {
                newRatingObject.ratingChoice = 'SinglesUTR';
            }
            // only return doubles value
        } else if (player.doublesUtr && player.doublesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusDoubles)) {
            newRatingObject.ratingChoice = 'VerifiedDoublesUTR';
        } else {
            newRatingObject.ratingChoice = 'DoublesUTR';
        }
    } else if (utrType) {
        // only return verified rating
        if (utrType === 'sanctioned') {
            if (player.singlesUtr && player.singlesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusSingles)) {
                newRatingObject.ratingChoice = 'VerifiedSinglesUTR';
            } else if (player.doublesUtr && player.doublesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusDoubles)) {
                newRatingObject.ratingChoice = 'VerifiedDoublesUTR';
            } else {
                newRatingObject.ratingChoice = 'VerifiedSinglesUTR';
                if (player.singlesUtr && player.singlesUtr > 0 && player.ratingStatusSingles === 'Inactive') {
                    // Do nothing since we need to show inactive rating for verified singles
                } else {
                    // SHow Unrated
                    newRatingObject.ratingStatusSingles = 'Unrated';
                    newRatingObject.singlesUtr = 0;
                }
            }
            // only return unverified rating
        } else if (player.myUtrSingles && player.myUtrSingles > 0 && !unDisplayedStatusStatus.includes(player.myUtrStatusSingles)) {
            newRatingObject.ratingChoice = 'SinglesUTR';
        } else if (player.myUtrDoubles && player.myUtrDoubles > 0 && !unDisplayedStatusStatus.includes(player.myUtrStatusDoubles)) {
            newRatingObject.ratingChoice = 'DoublesUTR';
        } else {
            newRatingObject.ratingChoice = 'SinglesUTR';
            if (player.myUtrSingles && player.myUtrSingles > 0 && player.myUtrStatusSingles === 'Inactive') {
                // Do nothing since we need to show inactive rating for singles
            } else {
                // SHow Unrated
                newRatingObject.myUtrStatusSingles = 'Unrated';
                newRatingObject.myUtrSingles = 0;
            }
        }

        // return the most relevant rating (any)
        // if there is no forced display, use player perference
    } else if (player.ratingChoice) {
        // If there is a reating choice no updation needed
        // otherwise show Rated or Projected rating if exists in the priority order of verifiedSingles, verifiedDoubles, myutrSingles and myUtrDoubles
        /*
            Checking with statuses of 'Inactive' and 'Unrated' since status null condition with a rating should be treated as Projected rating in getRatingDetails Method
            */
    } else if (player.singlesUtr && player.singlesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusSingles)) {
        newRatingObject.ratingChoice = 'VerifiedSinglesUTR';
    } else if (player.doublesUtr && player.doublesUtr > 0 && !unDisplayedStatusStatus.includes(player.ratingStatusDoubles)) {
        newRatingObject.ratingChoice = 'VerifiedDoublesUTR';
    } else if (player.myUtrSingles && player.myUtrSingles > 0 && !unDisplayedStatusStatus.includes(player.myUtrStatusSingles)) {
        newRatingObject.ratingChoice = 'SinglesUTR';
    } else if (player.myUtrDoubles && player.myUtrDoubles > 0 && !unDisplayedStatusStatus.includes(player.myUtrStatusDoubles)) {
        newRatingObject.ratingChoice = 'DoublesUTR';
    } else {
        newRatingObject.ratingChoice = 'SinglesUTR';
        if (player.myUtrSingles && player.myUtrSingles > 0 && player.myUtrStatusSingles === 'Inactive') {
            // Do nothing since we need to show inactive rating for singles
        } else {
            // SHow Unrated
            newRatingObject.myUtrStatusSingles = 'Unrated';
            newRatingObject.myUtrSingles = 0;
        }
    }
    return newRatingObject;
};
