import React, { useMemo } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { AddIcon, Button, Typography, DeleteIcon } from '@universal-tennis/ui-shared';

import ScheduleMatchCard from '../Cards/ScheduleMatchCard';

import { TypographySizes, SharedUICategories } from '../../utils/constants';
import { multiWeekByRoundPropTypes } from '../../utils/proptypes';

const MultiWeekRound = ({ round, onEditMatch, onDeleteMatch, isLeagueDeskSchedule, onDeleteRound, onAddTeamMatch, teams, isManaging }) => {
    const theme = useTheme();
    const { SMALL_BOOK } = TypographySizes;
    const { SECONDARY, LINK } = SharedUICategories;
    const roundMatches = [...(round?.matches || {})];
    const sortedRoundMatches = useMemo(() => {
        const sortMatches = (matches) => matches?.sort((a, b) => new Date(a.matchDate) - new Date(b.matchDate));
        return sortMatches([...roundMatches]);
    }, [roundMatches]);
    const roundHasNoPostedScores = !round?.hasPostedScores;

    const handleOnEditClick = (match) => {
        onEditMatch(match);
    };

    return (
        <Box mb={3}>
            <Box display="flex" justifyContent="space-between">
                <Typography
                    mb={1}
                    category="secondary"
                    size="small-medium"
                    color={theme.appColors.black}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {round?.name}
                </Typography>
                {roundHasNoPostedScores && isManaging && (
                    <IconButton onClick={() => onDeleteRound(round?.id, round?.name)}>
                        <DeleteIcon size={18} color={theme.appColors.interactionBlue} />
                    </IconButton>
                )}
            </Box>
            {sortedRoundMatches.map((match, index) => {
                const matchNumber = index + 1;
                return (
                    <ScheduleMatchCard
                        key={match?.teamMatchId}
                        match={match}
                        matchNumber={matchNumber}
                        onEditMatch={handleOnEditClick}
                        onDeleteMatch={onDeleteMatch}
                        isLeagueDeskSchedule={isLeagueDeskSchedule}
                        round={round}
                    />
                );
            })}
            {isManaging && (
                <Button
                    onClick={() => onAddTeamMatch(round?.id)}
                    startIcon={<AddIcon size={16} color={theme.appColors.interactionBlue} />}
                    category={LINK}
                    disabled={!teams?.length}
                >
                    <Typography
                        sx={{ color: theme.appColors.interactionBlue }}
                        category={SECONDARY}
                        size={SMALL_BOOK}
                        color={theme.appColors.black}
                    >
                        Add a team match
                    </Typography>
                </Button>
            )}
        </Box>
    );
};

MultiWeekRound.propTypes = {
    ...multiWeekByRoundPropTypes,
};

export default MultiWeekRound;
