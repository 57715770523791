import { createSlice } from '@reduxjs/toolkit';
import { getAuthUser } from './services/authServices';

const initialState = {
    user: null,
    isLoadingUser: false,
    isUserLoaded: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearUser(state) {
            state.user = null;
        },
    },
    extraReducers: {
        [getAuthUser.pending]: (state) => {
            state.isLoadingUser = true;
        },
        [getAuthUser.fulfilled]: (state, action) => {
            state.user = action.payload;
            state.isLoadingUser = false;
            state.isUserLoaded = true;
        },
        [getAuthUser.rejected]: (state) => {
            state.user = null;
            state.isLoadingUser = false;
            state.isUserLoaded = true;
        },
    },
});

export const { clearUser, updateProfilePicture } = authSlice.actions;
export default authSlice.reducer;
