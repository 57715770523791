import React from 'react';

import { useTheme, IconButton } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Typography, DrawCardTable } from '@universal-tennis/ui-shared';
import { useGetTableRowsData } from './useGetTableRowsData';
import LoadingIndicator from '../LoadingIndicator';
import TeamPageDrawTable from './TeamPageDrawTable';
import { drawTablePropTypes } from '../../utils/proptypes';

const DrawTable = ({
    onEditMatch,
    onViewMatch,
    onChangeClick,
    draw,
    selectableTeams,
    hasPublishedSchedule,
    isUpdatingTeamMatch,
    isManaging,
    isLoading,
    isMainDraw,
    totalRounds,
    isMobile,
    displayTournamentBracketStyle,
    showMainDraw,
}) => {
    const theme = useTheme();
    const { name, drawId } = draw;
    const { tableRows, headerRows, handleOnBackwardRoundClick, handleOnForwardRoundClick, isShowingForward, isShowingBackward } =
        useGetTableRowsData({
            draw,
            totalRounds,
            isUpdatingTeamMatch,
            selectableTeams,
            onEditMatch,
            onViewMatch,
            onChangeClick,
            drawId,
            hasPublishedSchedule,
            isManaging,
            isMainDraw,
        });

    return (
        <>
            {displayTournamentBracketStyle ? (
                <TeamPageDrawTable
                    draw={draw}
                    isMainDraw={isMainDraw}
                    isMobile={isMobile}
                    totalRounds={totalRounds}
                    hasPublishedSchedule={hasPublishedSchedule}
                    showMainDraw={showMainDraw}
                />
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {isLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <Box mt={5} sx={{ backgroundColor: theme.appColors.white }}>
                            <Grid container pt={2} pb={4}>
                                <Grid item sx={{ position: 'relative', top: '82px' }} pl={4} xs={2}>
                                    {isShowingBackward ? (
                                        <IconButton onClick={handleOnBackwardRoundClick}>
                                            <ArrowBackIosIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ color: theme.appColors.black, textAlign: 'center' }}
                                        category="secondary"
                                        size="large-medium"
                                    >
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ position: 'relative', top: '82px', display: 'flex', justifyContent: 'end' }} pr={4} xs={2}>
                                    {isShowingForward ? (
                                        <IconButton onClick={handleOnForwardRoundClick}>
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <DrawCardTable isMobile={isMobile} headerRows={headerRows} tableRows={tableRows} />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

DrawTable.propTypes = {
    ...drawTablePropTypes,
};

export default DrawTable;
