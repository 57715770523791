import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Alert from '../../components/Alert';
import DetailsTab from './DetailsTab';
import LoadingIndicator from '../../components/LoadingIndicator';
import MessagePlayersModal from '../../components/Modals/MessagePlayersModal';
import OverviewTab from './OverviewTab';
import PlayersTab from '../../components/PlayerTab/PlayersTab';
import Popup from '../../components/Popups/Popup';
import RegistrationDrawer from '../../components/RegistrationDrawer';
import ScheduleTab from './ScheduleTab';
import ShareTeamModal from '../../components/Modals/ShareTeamModal';
import TeamPageHeader from './TeamPageHeader';

import { LeagueTabs } from '../../utils/constants';
import { LeagueSessionTypes, SportTypeIds, LeagueSanctionTypes } from '../../utils/types';
import { gtag } from '../../utils/analytics';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';
import {
    useGetTeamQuery,
    useGetTeamSummaryQuery,
    useGetRegistrationFeesQuery,
    useMessageTeamPlayersMutation,
} from '../../api/leaguesApi';

const TeamPage = () => {
    const { user } = useSelector((state) => state.auth);
    const { teamId } = useParams();
    const parsedTeamId = parseInt(teamId);
    const { OVERVIEW, PLAYERS, SCHEDULE, DRAW, DETAILS } = LeagueTabs;
    const dispatch = useDispatch();
    const isUserLoggedIn = !!user;

    const [isCaptainView, setIsCaptainView] = useState(false);
    const [tab, setTab] = useState(OVERVIEW);
    const [showRegistrationDrawer, setShowRegistrationDrawer] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showRegistrationSuccessPopup, setShowRegistrationSuccessPopup] = useState(false);
    const [showMessagePlayersPopup, setShowMessagePlayersPopup] = useState(false);
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
    const noSidePadding = { pl: 0, pr: 0 };

    const { data: teamData, isFetching: isLoadingTeam, error: teamDataError, refetch: refetchTeam } = useGetTeamSummaryQuery(parsedTeamId);
    const {
        data: teamDetails,
        isLoading: isLoadingTeamDetails,
        error: teamDetailsError,
    } = useGetTeamQuery(parsedTeamId, { skip: !isCaptainView });
    const { data: registrationFees, error: feesError } = useGetRegistrationFeesQuery(parsedTeamId);
    const [messageAllPlayers, { isSuccess: hasMessagedPlayers }] = useMessageTeamPlayersMutation();

    if (teamDetailsError) {
        dispatch(showErrorPopup(teamDetailsError));
    }

    if (teamDataError) {
        dispatch(showErrorPopup(teamDataError));
    }

    if (feesError) {
        dispatch(showErrorPopup(feesError));
    }

    useEffect(() => {
        if (teamData && user) {
            const { captains, conference } = teamData;
            const captainIds = captains.map((captain) => captain.memberId);
            const isCaptain = captainIds.includes(user?.id);
            setIsCaptainView(isCaptain);
            dispatch(setSportTypeId(conference?.sportTypeId));
        }
    }, [teamData, user]);

    useEffect(() => {
        if (hasMessagedPlayers) {
            setIsSuccessAlertOpen(true);
        }
    }, [hasMessagedPlayers]);

    const handleOnTabChange = (_, newValue) => {
        setTab(newValue);
    };

    const handleOnRegisterClick = async () => {
        gtag('event', {
            category: 'Checkout Started',
            context: `Leagues Team Page - teamId: ${teamId}`,
        });

        setShowRegistrationDrawer(true);
    };

    const handleOnCloseDrawerRequest = () => {
        setShowRegistrationDrawer(false);
    };

    const handleOnRegistrationSuccess = () => {
        const { sessionEntryFee, leagueFee } = registrationFees || '';
        const totalFee = sessionEntryFee + leagueFee;
        const isTennis = teamData?.conference?.sportTypeId === SportTypeIds.TENNIS;
        gtag('purchase', {
            category: 'Event Registration',
            context: `Team ${isTennis ? 'Tennis' : 'Pickleball'}`,
            value: totalFee,
        });
        setShowRegistrationDrawer(false);
        setShowRegistrationSuccessPopup(true);
    };

    const handleOnCloseRegistrationSuccessPopup = () => {
        refetchTeam();
        setShowRegistrationDrawer(false);
        setShowRegistrationSuccessPopup(false);
    };

    const handleOnShareClick = () => {
        setShowShareModal(true);
    };

    const handleOnShareCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setShowShareModal(false);
    };

    const handleOnCloseShareModal = () => {
        setShowShareModal(false);
    };

    const handleOnMessagePlayersClick = () => {
        setShowMessagePlayersPopup(true);
    };

    const handleOnMessagePlayers = async (message, includeCaptains) => {
        setShowMessagePlayersPopup(false);

        try {
            await messageAllPlayers({ teamId, message, includeCaptains }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnCloseMessagePlayerModal = () => {
        setShowMessagePlayersPopup(false);
    };

    const handleOnCloseSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSuccessAlertOpen(false);
    };

    const handleOnFreeSessionRegistration = () => {
        setShowRegistrationDrawer(false);
        setShowRegistrationSuccessPopup(true);
    };

    const renderTeamContent = (teamSummary, fees) => {
        const { isRegistrationAvailable, sessionSummary } = teamSummary;
        const isTournament = sessionSummary?.typeId === LeagueSessionTypes.TOURNAMENT;
        const MATCH_TAB = isTournament ? DRAW : SCHEDULE;

        return (
            <>
                <TabContext value={tab}>
                    <TabPanel value={OVERVIEW} sx={{ ...noSidePadding }}>
                        <OverviewTab
                            fees={fees}
                            captains={teamData?.captains}
                            isUserLoggedIn={isUserLoggedIn}
                            isCaptainView={isCaptainView}
                            teamSummary={teamSummary}
                            isRegistrationAvailable={isRegistrationAvailable}
                            onShareClick={handleOnShareClick}
                            onRegisterClick={handleOnRegisterClick}
                        />
                    </TabPanel>
                    <TabPanel value={MATCH_TAB} sx={{ ...noSidePadding }}>
                        <Box>
                            <ScheduleTab teamId={teamId} session={sessionSummary} isTournament={isTournament} />
                        </Box>
                    </TabPanel>
                    <TabPanel value={PLAYERS} sx={{ ...noSidePadding }}>
                        <PlayersTab teamId={teamId} isCaptainView={isCaptainView} onMessageAllPlayers={handleOnMessagePlayersClick} />
                    </TabPanel>
                    {isCaptainView && (
                        <TabPanel value={DETAILS} sx={{ ...noSidePadding }}>
                            <DetailsTab teamDetails={teamDetails} isLoading={isLoadingTeamDetails} />
                        </TabPanel>
                    )}
                </TabContext>
            </>
        );
    };

    const isUsapSanctionedSession = teamData?.sessionSummary?.sanctionTypeId === LeagueSanctionTypes?.USAP;

    return (
        <Container disableGutters maxWidth={false}>
            {isLoadingTeam && <LoadingIndicator backgroundColor="common.white" />}
            {teamData && registrationFees && (
                <>
                    <TeamPageHeader
                        sessionName={teamData?.sessionSummary?.name}
                        sessionId={teamData?.sessionSummary?.id}
                        teamName={teamData?.teamName}
                        teamId={teamData?.teamId}
                        wins={teamData?.numOfWins}
                        losses={teamData?.numOfLosses}
                        teamData={teamData}
                        isCaptainView={isCaptainView}
                        onTabChange={handleOnTabChange}
                        tab={tab}
                        isTournament={teamData?.sessionSummary?.typeId === LeagueSessionTypes.TOURNAMENT}
                        teamPowerIndex={teamData?.teamPowerIndex}
                    />
                    <Divider />
                    <Container maxWidth="lg">{renderTeamContent(teamData, registrationFees)}</Container>
                    {showRegistrationDrawer && (
                        <RegistrationDrawer
                            teamData={teamData}
                            registrationFees={registrationFees}
                            onClose={handleOnCloseDrawerRequest}
                            onRegistrationSuccess={handleOnRegistrationSuccess}
                            isOpen={showRegistrationDrawer}
                            isUsapSanctionedSession={isUsapSanctionedSession}
                            onFreeSessionRegistration={handleOnFreeSessionRegistration}
                        />
                    )}
                    <ShareTeamModal
                        open={showShareModal}
                        url={window.location.href}
                        onClose={handleOnCloseShareModal}
                        onCopyClick={handleOnShareCopyClick}
                    />
                    <Popup
                        onAction={handleOnCloseRegistrationSuccessPopup}
                        actionLabel="Ok"
                        open={showRegistrationSuccessPopup}
                        onClose={handleOnCloseRegistrationSuccessPopup}
                        title="Congratulations"
                        message={`You are now registered to play for ${teamData.teamName}`}
                        isSuccessful
                    />
                    <MessagePlayersModal
                        isOpen={showMessagePlayersPopup}
                        onMessageSubmitted={handleOnMessagePlayers}
                        onRequestClose={handleOnCloseMessagePlayerModal}
                    />
                    <Snackbar open={isSuccessAlertOpen} autoHideDuration={5000} onClose={handleOnCloseSuccessAlert}>
                        <Alert onClose={handleOnCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            Message sent!
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Container>
    );
};

export default TeamPage;
