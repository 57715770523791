import React from 'react';
import { Controller } from 'react-hook-form';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiTimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';

const TimePicker = (props) => {
    const { control, name, label, isRequired = false } = props;

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Controller
                name={name}
                control={control}
                rule={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                    <MuiTimePicker
                        label={label}
                        value={value}
                        onChange={onChange}
                        minutesStep={5}
                        renderInput={(inputProps) => <TextField fullWidth {...inputProps} />}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimePicker;
