import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme, Container } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { SharedUICategories, TypographySizes, ManageSessionTabs } from '../../../utils/constants';
import { manageSessionPageHeaderPropTypes } from '../../../utils/proptypes';
import { getBreadcrumbData } from '../../../redux/services/breadcrumbService';

const ManageSessionPageHeader = ({ context, contextId, tab, sessionName = '', onTabChange, isMultiRoundSession, shouldUpdateBreadcrumb, onBreadcrumbUpdated }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { DISPLAY, SECONDARY } = SharedUICategories;
    const { LARGE_LIGHT, X_SMALL_MEDIUM_CAP } = TypographySizes;
    const { TEAMS, SCHEDULE, PLAYERS, DETAILS, DRAW } = ManageSessionTabs;
    const { data } = useSelector((state) => state.breadcrumbs);
    const { league, conference } = data;
    const leagueUrl = `/leagues/${league?.id}`;
    const conferenceUrl = `/leagues/conference/${conference?.id}/manage`;
    const scheduleDrawLabel = isMultiRoundSession ? SCHEDULE : DRAW;

    useEffect(() => {
        if (context && contextId) {
            if (!data || data[context]?.id !== parseInt(contextId)) {
                dispatch(getBreadcrumbData({ context, contextId }));
            }
        }
    }, []);

    useEffect(() => {
        if (shouldUpdateBreadcrumb) {
            dispatch(getBreadcrumbData({ context, contextId }));
            onBreadcrumbUpdated();
        }
    }, [shouldUpdateBreadcrumb]);

    return (
        <>
            <Box bgcolor="black">
                <Container maxWidth="lg">
                    <Box py={2} flexWrap="wrap" display="flex" justifyContent="space-between">
                        <Box>
                            <Box display="flex">
                                <Link href={leagueUrl}>
                                    <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                        {league?.name}
                                    </Typography>
                                </Link>
                                <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                    &nbsp;  / &nbsp;
                                </Typography>
                                <Link href={conferenceUrl}>
                                    <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                        {conference?.name}
                                    </Typography>
                                </Link>
                            </Box>
                            <Typography color={theme.appColors.white} category={DISPLAY} size={LARGE_LIGHT} lineHeight={1}>
                                {sessionName}
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box bgcolor={theme.appColors.white}>
                <Container maxWidth="lg">
                    <Tabs variant="scrollable" value={tab} onChange={onTabChange} aria-label="manage session tabs">
                        <Tab label={TEAMS} value={TEAMS} />
                        <Tab label={scheduleDrawLabel} value={scheduleDrawLabel} />
                        <Tab label={PLAYERS} value={PLAYERS} />
                        <Tab label={DETAILS} value={DETAILS} />
                    </Tabs>
                </Container>
            </Box>
        </>
    );
};

ManageSessionPageHeader.propTypes = {
    ...manageSessionPageHeaderPropTypes
};

export default ManageSessionPageHeader;
