/* global analytics */
/* eslint-disable */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'cookies-js';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import withFeatureFlags from '../hocs/withFeatureFlags';
import { getClubTypeString, getClubsVisibleToUser, sortClubMembershipsByPaidHit, ClubRoles, clubTiers } from '../../utils/clubs';
import { getApiAxiosInstance } from '../../utils/network';
import config from '../../config';
import MenuLink from './MenuLink';
import useIsMobile from '../../hooks/useIsMobile';
import { setSideMenu } from '../../redux/uiSlice';
import { SportTypeIds } from '../../utils/types';
import { getClickAttrs } from '../../utils/analytics';

import styles from './menu.module.scss';

const { marketingAppHost, links, appHost } = config;
const { TENNIS, PICKLEBALL } = SportTypeIds


const SlideMenu = ({ featureFlags }) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { sideMenuOpen } = useSelector((state) => state.ui);
    const { memberTeams, isLoadingMemberTeams } = useSelector((state) => state.user);
    const [collapsed, setCollapsed] = useState(false);
    const [toggleMyProfile, setToggleMyProfile] = useState(false);
    const [toggleMyAccount, setToggleMyAccount] = useState(false);

    const { createPlayerGroupMainMenu, personalClub, ratingsListFlag } = featureFlags;
    const { pathname } = location;
    const MAX_TEAM_TENNIS_TEAMS_TO_DISPLAY = 3;
    const MAX_CLUBS_TO_DISPLAY = 2;
    const isMobile = useIsMobile();

    const handleLogOut = async (ev) => {
        if (ev) {
            ev.preventDefault();
        }

        const axiosInstance = getApiAxiosInstance();
        const res = await axiosInstance.get('/v1/auth/logout');

        if (res.status === 200) {
            if (window && window.analytics) {
                analytics.reset();
            }
            Cookies.expire('jwt');
            window.location.href = `${appHost}/`;
        }
    };

    const handleClose = () => {
        dispatch(setSideMenu(false));
    };

    const handleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleToggleMyProfile = () => {
        setToggleMyProfile(!toggleMyProfile);
    };

    const handleToggleMyAccount = () => {
        setToggleMyAccount(!toggleMyAccount);
    };

    const { ADMIN, OWNER, MEMBER } = ClubRoles;
    const visibleClubs = user?.clubMemberships ? getClubsVisibleToUser(user?.clubMemberships) : [];
    const clubsWhereUserIsAdminOrOwner = user ? visibleClubs.filter((club) => club.roleValue === ADMIN || club.roleValue === OWNER) : [];
    const isPremiumClubsOrganizer = clubsWhereUserIsAdminOrOwner.findIndex((club) => clubTiers.includes(club.tierTypeName)) !== -1;
    const clubsWhereUserIsMember = user ? visibleClubs.filter((club) => club.roleValue === MEMBER) : [];
    const clubsOrderedByAdminOwner = [...clubsWhereUserIsAdminOrOwner, ...clubsWhereUserIsMember];
    const sortedClubMemberships = sortClubMembershipsByPaidHit(clubsOrderedByAdminOwner);

    let clubMembershipsToDisplay =
        sortedClubMemberships?.length > MAX_CLUBS_TO_DISPLAY
            ? [...sortedClubMemberships].splice(0, MAX_CLUBS_TO_DISPLAY)
            : sortedClubMemberships;

    return (
        <SwipeableDrawer
            anchor="right"
            open={sideMenuOpen}
            onClose={() => dispatch(setSideMenu(false))}
            onOpen={() => dispatch(setSideMenu(true))}
        >
            <Box sx={{ width: isMobile ? '100%' : '300px' }}>
                <div className={styles.closeArea}>
                    <button type="submit" className={styles.closeBtn} onClick={handleClose}>
                        <span />
                        <span />
                    </button>
                </div>
                <div className={styles.menuSection_options}>
                    {!!user?.firstName ? (
                        <h6>
                            {user?.firstName} {user?.lastName}
                        </h6>
                    ) : (
                        <h6>Welcome to UTR Sports!</h6>
                    )}
                    {!user?.onboardingCompleted && user?.onboardingRequired && pathname === `/` && (
                        <div onClick={handleClose}>
                            <a
                                className={`${styles.menuItem}`}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                                href={`${appHost}/home`}
                                {...getClickAttrs({
                                    category: 'Menu',
                                    context: 'Menu Item - Home',
                                    location: 'Menu',
                                })}
                            >
                                Home
                            </a>
                        </div>
                    )}
                </div>
                {(user?.onboardingCompleted || !user?.onboardingRequired) && (
                    <div className={`${styles.playerDetails} d-none d-lg-block`}>
                        <div onClick={handleClose}>
                            {user?.claimedPlayer && <MenuLink path={`${appHost}/players/${user?.claimedPlayer?.playerId}`} text="Profile" />}
                            <MenuLink path={`${appHost}/settings/account`} text="Settings" />
                            {(user?.isPowered || user?.isProClubHeadOrganizer) && (
                                <MenuLink path={`${appHost}/subscriptions`} text="Subscriptions" />
                            )}
                        </div>
                    </div>
                )}
                {(user?.onboardingCompleted || !user?.onboardingRequired) && (
                    <>
                        <div className={`${styles.menuSection} d-block d-lg-none`}>
                            <div onClick={() => handleToggleMyProfile()} className={styles.menuTitle}>
                                My Profile <span>{toggleMyProfile ? '-' : '+'}</span>
                            </div>
                            {toggleMyProfile && (
                                <div className={styles.collapsible} onClick={handleClose}>
                                    {user?.claimedPlayer && (
                                        <MenuLink path={`${appHost}/players/${user?.claimedPlayer.playerId}`} text="Profile" />
                                    )}
                                    <MenuLink path={`${appHost}/clubs`} text="My Clubs & Teams" trackedContent="My Clubs" />
                                    <MenuLink path={`${appHost}/my-group`} text="My Player Group" />
                                    {user?.isPowered && (
                                        <div className="">
                                            <MenuLink
                                                path={`${appHost}/saved/players`}
                                                text="My Saved Players"
                                            />
                                            <MenuLink
                                                path={`${appHost}/saved/schools`}
                                                text="My Saved Schools"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}

                {user ? (
                    <div>
                        <div onClick={handleClose}>
                            <div className="d-none d-lg-block">
                                {(user?.isPowered || user?.enableBetaFeatures) &&
                                    (user?.onboardingCompleted || !user?.onboardingRequired) && (
                                        <div className={styles.menuSection}>
                                            <div className={styles.menuTitle}>Clubs, Teams & Groups</div>
                                            {clubMembershipsToDisplay.map((clubMembership) => (
                                                <MenuLink
                                                    key={`${clubMembership.clubId}`}
                                                    path={`${appHost}/${getClubTypeString(clubMembership.tierTypeId)}/${
                                                        clubMembership.clubId
                                                    }`}
                                                    text={clubMembership.name}
                                                    trackedContent={`Individual clubs - ${clubMembership.name}`}
                                                />
                                            ))}
                                            {(visibleClubs && visibleClubs.length) > 0 && (
                                                <MenuLink path={`${appHost}/clubs`} text="See All" trackedContent="See All Clubs" />
                                            )}
                                            {personalClub?.isEnabled(user) &&
                                                createPlayerGroupMainMenu?.isEnabled(user) &&
                                                !user?.isPersonalClubHeadOrganizer && (
                                                    <a className={`${styles.menuItem}`} href={`${appHost}/create-players-group`}>
                                                        + Create my Players Group
                                                    </a>
                                                )}
                                            {!user?.canCreateEvent && isPremiumClubsOrganizer && (
                                                <a className={`${styles.menuItem}`} href={`${appHost}/create-event`}>
                                                    Create an Event
                                                </a>
                                            )}
                                        </div>
                                )}
                                {user && !isLoadingMemberTeams && !!memberTeams?.tennis?.length && (
                                    <div className={styles.menuSection}>
                                        <div className={styles.menuTitle}>Team Tennis</div>
                                        {memberTeams.tennis.slice(0, MAX_TEAM_TENNIS_TEAMS_TO_DISPLAY).map((team) => (
                                            <MenuLink
                                                key={team?.teamId}
                                                path={`/teams/${team?.teamId}`}
                                                text={team?.teamName}
                                                trackedContent={`Team Tennis - ${team?.teamName}`}
                                            />
                                            ))}
                                        <MenuLink path={`/member-teams/sport/${TENNIS}`} text="See All" trackedContent="See All Tennis League Teams" />
                                    </div>
                                )}
                                {user && !isLoadingMemberTeams && !!memberTeams?.pickleball?.length && (
                                <div className={styles.menuSection}>
                                    <div className={styles.menuTitle}>Team Pickleball</div>
                                    {memberTeams.pickleball.slice(0, MAX_TEAM_TENNIS_TEAMS_TO_DISPLAY).map((team) => (
                                        <MenuLink
                                            key={team?.teamId}
                                            path={`/teams/${team?.teamId}`}
                                            text={team?.teamName}
                                            trackedContent={`Team Tennis - ${team?.teamName}`}
                                        />
                                    ))}
                                    <MenuLink path={`/member-teams/sport/${PICKLEBALL}`} text="See All" trackedContent="See All Pickleball League Teams" />
                                </div>
                                )}
                                {user?.isClubOrganizer && (
                                    <div className={`${styles.menuSection} d-block`}>
                                        <a
                                            className={`${styles.menuTitle}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://support.${config.siteBaseUrl}/en/support/solutions/9000115381`}
                                        >
                                            Club Resources
                                        </a>
                                    </div>
                                )}
                                <div className={styles.menuSection}>
                                    <MenuLink path={`${appHost}/my-group`} text="My Player Group" />
                                </div>
                                {user?.isPowered && (user?.onboardingCompleted || !user?.onboardingRequired) && (
                                    <div className={styles.menuSection}>
                                        <div className={styles.menuTitle}>Saved Lists</div>
                                        <MenuLink path={`${appHost}/saved/players`} text="Players" section="Menu - Saved Lists" />
                                        <MenuLink path={`${appHost}/saved/schools`} text="Schools" section="Menu - Saved Lists" />
                                    </div>
                                )}
                                {user?.isAdmin && (
                                    <div className={styles.menuSection}>
                                        <>
                                            <a className={styles.menuItem} href={`${appHost}/tournament-content-editor-list`}>
                                                Tournament Editor List
                                            </a>
                                            <a className={styles.menuItem} href={`${appHost}/impersonate`}>
                                                Impersonate
                                            </a>
                                            <a className={styles.menuItem} href={`${appHost}/manage-event-templates`}>
                                                Manage Templates
                                            </a>
                                        </>
                                    </div>
                                )}
                                {ratingsListFlag?.isEnabled(user) && (
                                    <div className={styles.menuSection}>
                                        <a className={`${styles.menuItem}`} href={`${appHost}/ratingslist`}>
                                            Ratings List
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={`${styles.menuSection} d-block d-lg-none`}>
                            <a
                                className={`${styles.menuTitle}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://support.${config.siteBaseUrl}/en/support/solutions/9000115381`}
                            >
                                Club Resources
                            </a>
                        </div>
                        <div className={`${styles.menuSection} d-block d-lg-none`}>
                            <a className={`${styles.menuTitle}`} href={`${marketingAppHost}/education`}>
                                Education
                            </a>
                        </div>
                        <div className={`${styles.menuSection} d-block d-lg-none`}>
                            <div className={styles.menuTitle} onClick={handleCollapse}>
                                Players & Organizers <span>{collapsed ? '-' : '+'}</span>
                            </div>
                            {collapsed && (
                                <div className={styles.collapsible} onClick={handleClose}>
                                    <a
                                        className={`${styles.menuItem}`}
                                        href={`${marketingAppHost}/for-players`}
                                        {...getClickAttrs({
                                            category: 'Menu',
                                            context: `Players`,
                                            location: 'Menu',
                                        })}
                                    >
                                        Players
                                    </a>
                                    <a
                                        className={`${styles.menuItem}`}
                                        href={`${marketingAppHost}/for-organizations`}
                                        {...getClickAttrs({
                                            category: 'Menu',
                                            context: `Organizations`,
                                            location: 'Menu',
                                        })}
                                    >
                                        Organizations
                                    </a>
                                    <a
                                        className={`${styles.menuItem}`}
                                        href={`${marketingAppHost}/for-college-coaches`}
                                        {...getClickAttrs({
                                            category: 'Menu',
                                            context: `College`,
                                            location: 'Menu',
                                        })}
                                    >
                                        College
                                    </a>
                                    <a
                                        className={`${styles.menuItem}`}
                                        href={`${marketingAppHost}/for-high-school-coaches`}
                                        {...getClickAttrs({
                                            category: 'Menu',
                                            context: `High School`,
                                            location: 'Menu',
                                        })}
                                    >
                                        High School
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className={`${styles.menuSection} d-block d-lg-none`}>
                            <div>
                                <div onClick={() => handleToggleMyAccount()} className={styles.menuTitle}>
                                    My Account <span>{toggleMyAccount ? '-' : '+'}</span>
                                </div>
                                {toggleMyAccount && (
                                    <div className={styles.collapsible} onClick={handleClose}>
                                        <MenuLink path={`${appHost}/settings/account`} text="Settings" />
                                        {(user?.isPowered || user?.isProClubHeadOrganizer) && (
                                            <MenuLink path={`${appHost}/subscriptions`} text="Subscriptions" />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div onClick={handleClose} onKeyDown={handleClose}>
                            <div className={`${styles.menuSection} pb32`}>
                                {(user?.onboardingCompleted || !user?.onboardingRequired) && (
                                    <a
                                        className={`${isMobile ? styles.menuTitle : styles.menuItem}`}
                                        href={`${marketingAppHost}/`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        UTR Sports
                                    </a>
                                )}
                                <a
                                    className={`${isMobile ? styles.menuTitle : styles.menuItem}`}
                                    href={`${links.supportHome}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    {...getClickAttrs({
                                        category: 'Menu',
                                        context: `Support`,
                                        location: 'Menu',
                                    })}
                                >
                                    Support
                                </a>
                                <a
                                    className={isMobile ? styles.menuTitle : styles.menuItem}
                                    href="#" onClick={handleLogOut}
                                    {...getClickAttrs({
                                        category: 'Menu',
                                        context: `Sign Out`,
                                        location: 'Menu',
                                    })}
                                >
                                    Sign Out
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div onClick={handleClose}>
                        <div className={styles.menuSection}>
                            <a className={`${styles.menuItem}`} href={`${marketingAppHost}/education`}>
                                Education
                            </a>
                        </div>
                        <div className={styles.menuSection}>
                            <div className={styles.menuTitle}>Organizers / Coaches</div>
                            <a className={`${styles.menuItem}`} href={`${marketingAppHost}/for-organizations`}>
                                Organizations
                            </a>
                            <a className={`${styles.menuItem}`} href={`${marketingAppHost}/for-college-coaches`}>
                                College
                            </a>
                            <a className={`${styles.menuItem}`} href={`${marketingAppHost}/for-high-school-coaches`}>
                                High School
                            </a>
                        </div>
                        <div className={styles.menuSection}>
                            <a
                                className={`${styles.menuItem} ${pathname === '/login' ? styles.active : ''}`}
                                href={`${marketingAppHost}/login`}
                            >
                                Sign In
                            </a>
                            <a
                                className={`${styles.menuItem} ${pathname === '/join' ? styles.active : ''} d-lg-none`}
                                href={`${marketingAppHost}/join`}
                            >
                                Join
                            </a>
                        </div>
                    </div>
                )}
            </Box>
        </SwipeableDrawer>
    );
};

export default withFeatureFlags(SlideMenu);
