import React from 'react';
import { useTheme } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';

import SessionStandingsTable from '../../../components/Tables/SessionStandingsTable';

import { standingsTabPropTypes } from '../../../utils/proptypes';

const StandingsTab = ({ sportTypeId = '', teamStandings = [] }) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { MEDIUM_MEDIUM } = TypographySizes;

    return (
        <Box mt={4}>
            {!teamStandings?.length ? (
                <Box py={4} textAlign="center">
                    <Typography category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                        No team standings are available for this session.
                    </Typography>
                </Box>
            ) : (
                <SessionStandingsTable sportTypeId={sportTypeId} sessionStandings={teamStandings} />
            )}
        </Box>
    );
};

StandingsTab.propTypes = {
    ...standingsTabPropTypes,
};

export default StandingsTab;
