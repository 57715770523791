import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import LoadingIndicator from '../../../components/LoadingIndicator';
import MultiRoundScheduleToggle from '../../../components/Form/MultiRoundScheduleToggle';
import Schedule from '../../../components/Schedule';
import { ResponseStatuses } from '../../../utils/constants';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { ScheduleStatuses, MultiRoundScheduleTypes } from '../../../utils/types';
import { useGetTeamScheduleQuery, useLazyGetTeamScheduleByDateQuery } from '../../../api/leaguesApi';

import { teamPageScheduleTabPropTypes } from '../../../utils/proptypes';

import useSessionSchedule from '../../../hooks/useSessionSchedule';

const ScheduleTab = ({ teamId, isTournament, session }) => {
    const dispatch = useDispatch();
    const { NOT_FOUND } = ResponseStatuses;
    const [rounds, setRounds] = useState([]);
    const [multiRoundScheduleType, setMultiRoundScheduleType] = useState(0);

    const [getTeamScheduleByDate, { data: teamScheduleByDate, isFetching: isLoadingTeamScheduleByDate, error: teamScheduleByDateError }] =
        useLazyGetTeamScheduleByDateQuery();
    const { data: teamSchedule, isLoading, error } = useGetTeamScheduleQuery(teamId, { skip: isTournament });
    const { drawSchedule, isLoadingSchedule } = useSessionSchedule({
        sessionId: session?.id,
        showDraftSchedule: false,
        isMultiRoundSession: !isTournament,
        skipFetch: !isTournament,
    });

    if (teamScheduleByDateError) {
        const hasError = teamScheduleByDateError?.status !== NOT_FOUND;
        if (hasError) {
            dispatch(showErrorPopup(teamScheduleByDateError));
        }
    }

    if (error) {
        const hasError = error?.status !== NOT_FOUND;
        if (hasError) {
            dispatch(showErrorPopup(error));
        }
    }

    const handleOnScheduleChange = async (event) => {
        const scheduleType = parseInt(event?.target?.value);
        if (!teamScheduleByDate && scheduleType === MultiRoundScheduleTypes.BY_DATE) {
            await getTeamScheduleByDate(teamId);
        }

        setMultiRoundScheduleType(scheduleType);
    };

    useEffect(() => {
        if (teamSchedule) {
            const mappedRoundMatches = teamSchedule.rounds.map((round) => {
                const filteredTeamMatches = teamSchedule.teamMatches.filter((match) => match.roundId === round.roundId);
                return {
                    ...round,
                    matches: filteredTeamMatches,
                };
            });
            setRounds(mappedRoundMatches);
        }
    }, [teamSchedule]);

    const isSchedulePublished =
        drawSchedule?.statusId === ScheduleStatuses.PUBLISHED || teamSchedule?.statusId === ScheduleStatuses.PUBLISHED;

    return (
        <Box>
            {isLoading || isLoadingSchedule || isLoadingTeamScheduleByDate ? (
                <LoadingIndicator />
            ) : (
                <>
                    {teamSchedule && isSchedulePublished && (
                        <Box mb={2}>
                            <MultiRoundScheduleToggle
                                scheduleType={multiRoundScheduleType}
                                disabled={isLoadingTeamScheduleByDate}
                                onChange={handleOnScheduleChange}
                            />
                        </Box>
                    )}
                    <Schedule
                        isTournament={isTournament}
                        session={session}
                        drawSchedule={drawSchedule}
                        scheduleByDate={teamScheduleByDate}
                        multiRoundScheduleType={multiRoundScheduleType}
                        isSchedulePublished={isSchedulePublished}
                        seasonRounds={rounds}
                        displayTournamentBracketStyle
                        sessionId={session?.id}
                    />
                </>
            )}
        </Box>
    );
};

ScheduleTab.propTypes = {
    ...teamPageScheduleTabPropTypes,
};

export default ScheduleTab;
