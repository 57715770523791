import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sportTypeId: null,
};

const conferenceDetailsSlice = createSlice({
    name: 'conferenceDetails',
    initialState,
    reducers: {
        setSportTypeId(state, action) {
            state.sportTypeId = action.payload;
        },
    },
});

export const { setSportTypeId } = conferenceDetailsSlice.actions;
export default conferenceDetailsSlice.reducer;
