import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import ModalBaseSimple from './ModalBaseSimple';

import { shareTeamModalPropTypes, defaultModalPropTypes } from '../../utils/proptypes';

const ShareTeamModal = ({ open, url, onClose, onCopyClick }) => {
    return (
        <ModalBaseSimple open={open} onClose={onClose} title="Invite a Player to your Team">
            <DialogContent>
                <Typography align="center" sx={{ mb: 2 }}>
                    To add a player to your team share this link to your team page.
                </Typography>
                <Typography align="center">
                    When they click the link they can register for your team. If you have a team PIN, make sure to send it with
                    your invitation.
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography align="center" fontWeight="bold" sx={{ mb: 1 }}>
                    Team Page Link
                </Typography>
                <Typography align="center">{url}</Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2, mt: 1 }}>
                <Button onClick={onClose} variant="light">
                    Close
                </Button>
                <Button onClick={onCopyClick} variant="dark">
                    Copy Link
                </Button>
            </DialogActions>
        </ModalBaseSimple>
    );
};

ShareTeamModal.propTypes = {
    ...shareTeamModalPropTypes
};

ShareTeamModal.defaultProps = {
    ...defaultModalPropTypes
};

export default ShareTeamModal;
