import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';

import { Typography, Button, AddIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ManagerCard from '../../../components/Cards/ManagerCard';
import FullPageModal from '../../../components/Modals/FullPageModal';
import PlayerSearch from '../../../components/Form/PlayerSearch';
import Popup from '../../../components/Popups/Popup';
import TeamProfileInfoCard from '../../../components/Cards/TeamProfileInfoCard';

import { mapMembersForPlayerSearch } from '../../../utils/helpers';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { showPopup } from '../../../redux/genericPopupSlice';
import { useAddCaptainMutation, useRemoveCaptainMutation } from '../../../api/leaguesApi';
import LoadingIndicator from '../../../components/LoadingIndicator';
import useIsMobile from '../../../hooks/useIsMobile';
import { teamPageOverviewTabPropTypes, defaultTeamPageOverviewTabPropTypes } from '../../../utils/proptypes';

const OverviewTab = ({
    teamSummary,
    isCaptainView,
    isRegistrationAvailable,
    isLoadingRegistration,
    onRegisterClick,
    onShareClick,
    fees,
    captains,
    isUserLoggedIn,
}) => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const { teamId } = useParams();
    const dispatch = useDispatch();

    const [showAddCaptainModal, setShowAddCaptainModal] = useState(false);
    const [originalCaptains, setOriginalCaptains] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [captainToBeUpdated, setCaptainToBeUpdated] = useState();

    const [addCaptain, { isLoading: isAddingCaptain }] = useAddCaptainMutation();
    const [removeCaptain, { isLoading: isRemovingCaptain }] = useRemoveCaptainMutation();
    const isUpdatingCaptains = isAddingCaptain || isRemovingCaptain;
    const popupMessage = `Are you sure you want to remove ${captainToBeUpdated?.displayName} as a Captain?`;
    const minimumAmountOfMembersNeeded = 1;
    const maximumAmountOfCaptains = 3;
    const desktopPadding = '40px';
    const mobilePadding = '24px';

    useEffect(() => {
        if (captains?.length) {
            const mappedCaptains = mapMembersForPlayerSearch(captains);
            setOriginalCaptains(mappedCaptains);
        }
    }, [captains]);

    const isPermittedToRemoveMember = () => {
        if (captains?.length === minimumAmountOfMembersNeeded) {
            dispatch(
                showPopup({
                    isError: true,
                    title: 'Sorry',
                    message: `There must always be at least one Captain`,
                })
            );
            return false;
        }

        return true;
    };

    const isPermittedToAddMember = () => {
        if (captains?.length === maximumAmountOfCaptains) {
            dispatch(
                showPopup({
                    isError: true,
                    title: 'Sorry',
                    message: `Only 3 Captains are permitted per team.`,
                })
            );
            return false;
        }

        return true;
    };

    const handleOnConfirmRemoval = async () => {
        setShowConfirmationPopup(false);

        const isAbleToRemoveMember = isPermittedToRemoveMember();
        if (isAbleToRemoveMember) {
            try {
                await removeCaptain({ teamId, captainId: captainToBeUpdated.captainId }).unwrap();
                setCaptainToBeUpdated(null);
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }
    };

    const handleOnConfirmAddition = async () => {
        setShowAddCaptainModal(false);

        const isAbleToAddMember = isPermittedToAddMember();
        if (isAbleToAddMember) {
            try {
                await addCaptain({ teamId, captain: { memberId: captainToBeUpdated.memberId } }).unwrap();
                setCaptainToBeUpdated(null);
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }
    };

    const handleOnClosePopup = () => {
        setShowConfirmationPopup(false);
    };

    const handleOnAddCaptainClick = () => {
        setShowAddCaptainModal(true);
    };

    const handleOnPlayerSelected = (player) => {
        setCaptainToBeUpdated(player);
    };

    const handleOnCloseAddCaptainModal = async () => {
        setShowAddCaptainModal(false);
    };

    const handleOnRemoveClick = (captainToBeRemoved) => {
        setCaptainToBeUpdated(captainToBeRemoved);
        setShowConfirmationPopup(true);
    };

    return (
        <Box mt={2} pt="25px">
            <Grid container spacing={5}>
                <Grid item xs={12} md={4.5} sx={{ '&.MuiGrid-item': { pt: 0 } }}>
                    <Box mb={1}>
                        <Typography category="secondary" size="x-small-medium-cap" color={theme.appColors.black}>
                            Registration Information
                        </Typography>
                    </Box>
                    <TeamProfileInfoCard
                        teamData={teamSummary}
                        isLoadingRegistration={isLoadingRegistration}
                        registrationFees={fees}
                        isRegistrationAvailable={isRegistrationAvailable}
                        onJoinClick={onRegisterClick}
                        onShareClick={onShareClick}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7.5}
                    sx={{ '&.MuiGrid-item': { pt: isMobile ? desktopPadding : 0, pl: isMobile ? desktopPadding : mobilePadding } }}
                >
                    <Box mb={1}>
                        <Typography category="secondary" size="x-small-medium-cap" color={theme.appColors.black}>
                            Captains
                        </Typography>
                    </Box>
                    {isUpdatingCaptains ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            <ManagerCard
                                isUserLoggedIn={isUserLoggedIn}
                                managers={captains}
                                isCaptainView={isCaptainView}
                                onRemoveClick={handleOnRemoveClick}
                            />
                            {isCaptainView && (
                                <Button
                                    onClick={handleOnAddCaptainClick}
                                    sx={{ textTransform: 'none' }}
                                    startIcon={<AddIcon size={16} color={theme.appColors.interactionBlue} />}
                                >
                                    <Typography
                                        sx={{ color: theme.appColors.interactionBlue }}
                                        category="secondary"
                                        size="small-book"
                                        color={theme.appColors.black}
                                    >
                                        Add a team captain
                                    </Typography>
                                </Button>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            <FullPageModal
                title="Add a team captain"
                onClose={handleOnCloseAddCaptainModal}
                isOpen={showAddCaptainModal}
                onCtaClick={handleOnConfirmAddition}
                ctaTitle="Save"
            >
                <Paper sx={{ mt: 5, p: 2 }}>
                    <PlayerSearch
                        onPlayerSelected={handleOnPlayerSelected}
                        selectedPlayers={originalCaptains}
                        searchMembersOnly
                        label="Find a Team Captain"
                    />
                </Paper>
            </FullPageModal>
            <Popup
                onAction={handleOnConfirmRemoval}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showConfirmationPopup}
                onClose={handleOnClosePopup}
                title="Remove Captain"
                message={popupMessage}
            />
        </Box>
    );
};

OverviewTab.propTypes = {
    ...teamPageOverviewTabPropTypes,
};
OverviewTab.defaultProps = {
    ...defaultTeamPageOverviewTabPropTypes
};

export default OverviewTab;
