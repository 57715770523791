import React, { useState, useEffect, useMemo } from 'react';
import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import LineupDropdownItem from '../../LineupDropdownItem';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { MatchPositions, MatchPositionKeys } from '../../../utils/postScoreShared';
import { sortPlayersByAvailability } from '../../../utils/helpers';

const LineupCard = ({
    isDoubles = false,
    position1Team,
    position2Team,
    lineup,
    onPlayerChange,
    canEditPosition1,
    canEditPosition2,
}) => {
    const { POSITION_1, POSITION_2 } = MatchPositions;
    const { MATCH_POSITION_1, MATCH_POSITION_2 } = MatchPositionKeys;
    const [currentLineup, setCurrentLineup] = useState();
    const [selectedPlayer1Position1, setSelectedPlayer1Position1] = useState();
    const [selectedPlayer2Position1, setSelectedPlayer2Position1] = useState();
    const [selectedPlayer1Position2, setSelectedPlayer1Position2] = useState();
    const [selectedPlayer2Position2, setSelectedPlayer2Position2] = useState();

    const cardTitle = `${isDoubles ? 'Doubles' : 'Singles'} line match ${lineup?.lineupPosition}`;
    const showBothPositions = canEditPosition1 && canEditPosition2;
    const position1Players = position1Team?.teamMembers;
    const position2Players = position2Team?.teamMembers;
    const position1TeamName = position1Team?.name;
    const position2TeamName = position2Team?.name;
    const teamName = canEditPosition1 && !canEditPosition2 ? position1TeamName : position2TeamName;

    const position = !showBothPositions && canEditPosition1 && !canEditPosition2 ? POSITION_1 : POSITION_2;

    useEffect(() => {
        if (lineup) {
            setCurrentLineup(lineup);
            setSelectedPlayer1Position1(lineup?.position1?.player1TeamMemberId);
            setSelectedPlayer2Position1(lineup?.position1?.player2TeamMemberId);
            setSelectedPlayer1Position2(lineup?.position2?.player1TeamMemberId);
            setSelectedPlayer2Position2(lineup?.position2?.player2TeamMemberId);
        }
    }, [lineup]);

    const handleOnPlayerChange = (event, teamPosition, isPlayerTwo = false) => {
        const isPosition1 = teamPosition === POSITION_1;
        const positionKey = isPosition1 ? MATCH_POSITION_1 : MATCH_POSITION_2;
        const positionTeamId = isPosition1 ? position1Team?.teamId : position2Team?.teamId;
        const playerTeamMemberId = event.target.value;

        if (isPosition1 && !isPlayerTwo) {
            setSelectedPlayer1Position1(event.target.value);
        } else if (isPosition1 && isPlayerTwo) {
            setSelectedPlayer2Position1(event.target.value);
        } else if (!isPosition1 && !isPlayerTwo) {
            setSelectedPlayer1Position2(event.target.value);
        } else if (!isPosition1 && isPlayerTwo) {
            setSelectedPlayer2Position2(event.target.value);
        }

        const updatedLineup = {
            ...currentLineup,
            [positionKey]: {
                teamId: positionTeamId,
                player1TeamMemberId: isPlayerTwo ? currentLineup[positionKey]?.player1TeamMemberId : playerTeamMemberId,
                player2TeamMemberId: isPlayerTwo ? playerTeamMemberId : currentLineup[positionKey]?.player2TeamMemberId
            }
        };

        setCurrentLineup(updatedLineup);
        onPlayerChange({ updatedLineup, playerTeamMemberId, teamId: positionTeamId });
    };

    const getPlayerNameForSelection = (teamMemberId, teamPosition) => {
        const isPosition1 = teamPosition === POSITION_1;
        const team = isPosition1 ? position1Players : position2Players;
        const player = team.find((teamPlayer) => teamPlayer?.teamMemberId === teamMemberId);

        return `${player?.firstName} ${player?.lastName}`;
    };

    const sortedPosition2Players = useMemo(() => {
        return sortPlayersByAvailability(position2Players) || [];
    }, [position2Players]);

    const sortedPosition1Players = useMemo(() => {
        return sortPlayersByAvailability(position1Players) || [];
    }, [position1Players]);

    const doublesPlayer2Value = position === POSITION_1 ? selectedPlayer2Position1 : selectedPlayer2Position2;
    const doublesPlayer1Value = position === POSITION_1 ? selectedPlayer1Position1 : selectedPlayer1Position2;
    const singlesPlayerValue = position === POSITION_1 ? selectedPlayer1Position1 : selectedPlayer1Position2;
    const players = canEditPosition1 && !canEditPosition2 ? sortedPosition1Players : sortedPosition2Players;

    return (
        <Box>
            <Typography mb={1} category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_MEDIUM_CAP}>
                {cardTitle}
            </Typography>
            <Card variant="outlined">
                {isDoubles ? (
                    <CardContent>
                        {showBothPositions ? (
                            <Box display="flex" flexDirection="column">
                                <FormControl fullWidth>
                                    <InputLabel id="doubles-position2-player1-label">{position2TeamName}</InputLabel>
                                    <Select
                                        labelId="doubles-position2-player1-label"
                                        id="doubles-position2-player1-dropdown"
                                        value={selectedPlayer1Position2 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_2)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_2)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition2Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="doubles-position2-player2-label">{position2TeamName}</InputLabel>
                                    <Select
                                        labelId="doubles-position2-player2-label"
                                        id="doubles-position2-player2-dropdown"
                                        value={selectedPlayer2Position2 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_2, true)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_2)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition2Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 4 }}>
                                    <InputLabel id="doubles-position1-player1-label">{position1TeamName}</InputLabel>
                                    <Select
                                        labelId="doubles-position1-player1-label"
                                        id="doubles-position1-player1-dropdown"
                                        value={selectedPlayer1Position1 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_1)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_1)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition1Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="doubles-position1-player2-label">{position1TeamName}</InputLabel>
                                    <Select
                                        labelId="doubles-position1-player2-label"
                                        id="doubles-position1-player2-dropdown"
                                        value={selectedPlayer2Position1 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_1, true)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_1)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition1Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Box display="flex" flexDirection="column">
                                <FormControl fullWidth>
                                    <InputLabel id={`doubles-${teamName}-${position}-player1-label`}>{teamName}</InputLabel>
                                    <Select
                                        labelId={`doubles-${teamName}-${position}-player1-label`}
                                        id={`doubles-${teamName}-${position}-player1-dropdown`}
                                        value={doublesPlayer1Value || ''}
                                        onChange={(event) => handleOnPlayerChange(event, position)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, position)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id={`doubles-${teamName}-${position}-player2-label`}>{teamName}</InputLabel>
                                    <Select
                                        labelId={`doubles-${teamName}-${position}-player2-label`}
                                        id={`doubles-${teamName}-${position}-player2-dropdown`}
                                        value={doublesPlayer2Value || ''}
                                        onChange={(event) => handleOnPlayerChange(event, position, true)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, position)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </CardContent>
                ) : (
                    <CardContent>
                        {showBothPositions ? (
                            <Box display="flex" flexDirection="column">
                                <FormControl fullWidth>
                                    <InputLabel id="singles-position2-player-label">{position2TeamName}</InputLabel>
                                    <Select
                                        labelId="singles-position2-player-label"
                                        id="singles-position2-player-dropdown"
                                        value={selectedPlayer1Position2 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_2)}
                                        label={position2TeamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_2)}
                                    >
                                        <MenuItem key="none" value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition2Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 4 }}>
                                    <InputLabel id="singles-position1-player-label">{position1TeamName}</InputLabel>
                                    <Select
                                        labelId="singles-position1-player-label"
                                        id="singles-position1-player-dropdown"
                                        value={selectedPlayer1Position1 || ''}
                                        onChange={(event) => handleOnPlayerChange(event, POSITION_1)}
                                        label={position1TeamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, POSITION_1)}
                                    >
                                        <MenuItem key="none" value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {sortedPosition1Players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Box display="flex" flexDirection="column">
                                <FormControl fullWidth>
                                    <InputLabel id={`singles-${teamName}-player-label`}>{teamName}</InputLabel>
                                    <Select
                                        labelId={`singles-${teamName}-player-label`}
                                        id={`singles-${teamName}-player-dropdown`}
                                        value={singlesPlayerValue || ''}
                                        onChange={(event) => handleOnPlayerChange(event, position)}
                                        label={teamName}
                                        renderValue={(value) => getPlayerNameForSelection(value, position)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {players.map((player) => (
                                            <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                                <LineupDropdownItem player={player} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </CardContent>
                )}

            </Card>
        </Box>
    );
};

export default LineupCard;
