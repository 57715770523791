import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AvatarWithName, Typography } from '@universal-tennis/ui-shared';

import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import GenericTable from '../GenericTable';
import MessageIcon from '../../Icons/MessageIcon';
import PlayerUtr from '../../PlayerUtr';
import MoreMenu from '../../MoreMenu';
import { getPlayerDisplayUtr, getDisplayUTRPRating } from '../../../utils/display';
import { getPlayerProfileUrl, getPlayerAvatarImage } from '../../../utils/helpers';
import { PlayerManageOptions, PlayerActionTypes, SportTypeIds } from '../../../utils/types';
import {
    managingPickleballHeadCells,
    managingTennisHeadCells,
    tennisHeadCells,
    pickleballHeadCells,
    SharedUICategories,
    TypographySizes,
} from '../../../utils/constants';

import { playersTablePropTypes, playersTableDefaultPropTypes, playerTableRowPropTypes } from '../../../utils/proptypes';

const PlayersTable = ({
    teamPlayers = [],
    isLoading,
    onWithdrawPlayer,
    onMovePlayer,
    onCopyPlayer,
    onTogglePlayerStatus,
    isManaging = false,
    hasSessionEnded,
}) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_BOOK } = TypographySizes;
    const { WITHDRAW, MOVE_TO_TEAM, COPY_TO_TEAM, MARK_AS_ROSTERED, MARK_AS_SUBSTITUTE } = PlayerActionTypes;
    const [players, setPlayers] = useState([]);
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;

    useEffect(() => {
        const formattedPlayers = teamPlayers.map((player) => {
            const { isVerified, rating } = getPlayerDisplayUtr(player);
            const { isVerified: isVerifiedDoubles, rating: doublesUtr } = getPlayerDisplayUtr(player, true);
            const {
                memberId,
                teamId,
                firstName,
                lastName,
                wins,
                losses,
                location,
                gender,
                playerId,
                hasPostedScores,
                canBeMovedOrCopied,
                canBeRefunded,
                isSubstitute,
            } = player || {};
            return {
                id: memberId,
                gender,
                playerId,
                teamId,
                name: `${firstName} ${lastName}`,
                matchesPlayed: wins + losses,
                location: location?.placeName || location?.formattedAddress || '',
                record: `${wins} - ${losses}`,
                doublesUtr,
                isVerifiedDoubles,
                singlesUtr: rating,
                isVerifiedSingles: isVerified,
                hasPostedScores,
                canBeMovedOrCopied,
                isSubstitute,
                canBeRefunded,
                singlesUTRP: getDisplayUTRPRating(player),
                doublesUTRP: getDisplayUTRPRating(player, true),
            };
        });
        setPlayers(formattedPlayers);
    }, [teamPlayers]);

    const PlayerTableRow = ({ rowData, labelId }) => {
        const {
            doublesUtr,
            id,
            isVerifiedDoubles,
            isVerifiedSingles,
            matchesPlayed,
            name,
            playerId,
            profileImage,
            record,
            singlesUtr,
            teamId,
            hasPostedScores,
            canBeMovedOrCopied,
            isSubstitute,
            canBeRefunded,
            singlesUTRP,
            doublesUTRP,
        } = rowData;

        PlayerTableRow.propTypes = {
            ...playerTableRowPropTypes,
        };

        const handleOnMenuOptionSelected = (selectedOption) => {
            switch (selectedOption) {
                case WITHDRAW:
                    onWithdrawPlayer(id, teamId, canBeRefunded);
                    break;
                case MOVE_TO_TEAM:
                    onMovePlayer(id, teamId);
                    break;
                case COPY_TO_TEAM:
                    onCopyPlayer(id, teamId);
                    break;
                case MARK_AS_ROSTERED:
                case MARK_AS_SUBSTITUTE:
                    onTogglePlayerStatus(id, teamId);
                    break;
                default:
                    break;
            }
        };

        const avatarImage = getPlayerAvatarImage(profileImage);
        const playerUrl = getPlayerProfileUrl(playerId);

        PlayerTableRow.propTypes = {
            ...playerTableRowPropTypes,
        };

        return (
            <TableRow tabIndex={-1} key={id} sx={{ backgroundColor: 'white' }}>
                <TableCell sx={{ color: 'black', border: 'none' }} component="th" id={labelId} scope="row" padding="normal">
                    <AvatarWithName href={playerUrl} imgSrc={avatarImage} playerName={name} />
                </TableCell>
                {isTennisMatch ? (
                    <>
                        <TableCell align="center" sx={{ border: 'none' }}>
                            <PlayerUtr utr={singlesUtr} isVerified={isVerifiedSingles} isUpdatedDesign />
                        </TableCell>
                        <TableCell align="center" sx={{ border: 'none' }}>
                            <PlayerUtr utr={doublesUtr} isVerified={isVerifiedDoubles} isUpdatedDesign />
                        </TableCell>
                    </>
                ) : (
                    <>
                        <TableCell sx={{ border: 'none' }} align="center">
                            <Typography category={SECONDARY} size={X_SMALL_BOOK} mr={0.5}>
                                {singlesUTRP?.rating}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }} align="center">
                            <Typography color={theme.appColors.black} category={SECONDARY} size={X_SMALL_BOOK} mr={0.5}>
                                {doublesUTRP?.rating}
                            </Typography>
                        </TableCell>
                    </>
                )}

                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography category={SECONDARY} size={X_SMALL_BOOK}>
                        {isManaging ? matchesPlayed : record}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: 'none' }}>
                    <Stack direction="row" spacing={3}>
                        <IconButton title="Message Captain" aria-label="message captain">
                            <MessageIcon memberId={id} displayName={name} style={{ marginLeft: 'auto' }} />
                        </IconButton>
                        {isManaging && (
                            <MoreMenu
                                disableMove={hasPostedScores || !canBeMovedOrCopied}
                                disableCopy={hasSessionEnded || !canBeMovedOrCopied}
                                options={PlayerManageOptions}
                                isSubstitute={isSubstitute}
                                onOptionSelected={handleOnMenuOptionSelected}
                            />
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
        );
    };

    const pickleballHeaderCells = isManaging ? managingPickleballHeadCells : pickleballHeadCells;
    const tennisHeaderCells = isManaging ? managingTennisHeadCells : tennisHeadCells;
    const headCells = isTennisMatch ? tennisHeaderCells : pickleballHeaderCells;

    return <GenericTable sortBy="name" tableData={players} headCells={headCells} isLoading={isLoading} rowComponent={PlayerTableRow} />;
};

PlayersTable.propTypes = {
    ...playersTablePropTypes,
};

PlayersTable.defaultProps = {
    ...playersTableDefaultPropTypes,
};

export default PlayersTable;
