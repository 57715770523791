import React, { useState } from 'react';
import { Typography, EditIcon } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { getAdjustedDate } from '../../../utils/display';
import {
    drawTypes,
    sessionTypes,
    LeagueSessionTypes,
    sessionMatchFormatTypes,
    MatchFormatTypes,
    StatusTypes,
    getTypeForId,
} from '../../../utils/types';

import MatchFormat from '../../../components/MatchFormat';
import ManageSessionModal from '../../../components/Modals/ManageSessionModal';
import { manageSessionDetailsTabPropTypes } from '../../../utils/proptypes';

const DetailsTab = ({ sessionDetails = {} }) => {
    const theme = useTheme();
    const {
        name,
        sessionTypeId,
        drawTypeId,
        location,
        description,
        sessionMatchTypeId,
        singlesMatchCount,
        singlesPointsPerMatch,
        singlesScoringFormatTypeId,
        singlesTimeAllowedTypeId,
        sportTypeId,
        doublesMatchCount,
        doublesPointsPerMatch,
        doublesScoringFormatTypeId,
        doublesTimeAllowedTypeId,
        sessionStatusTypeId,
        teamsMax,
        registrationFee,
        registrationVerificationFee,
        startDateUtc,
        endDateUtc,
        teamRegistrationStartDateUtc,
        teamRegistrationEndDateUtc,
    } = sessionDetails || {};
    const { SECONDARY } = SharedUICategories;
    const { TOURNAMENT } = LeagueSessionTypes;
    const { BOTH, DOUBLES, SINGLES } = MatchFormatTypes;
    const { X_SMALL_MEDIUM_CAP, SMALL_MEDIUM, SMALL_BOOK } = TypographySizes;
    const { OPEN } = StatusTypes;
    const [showEditModal, setShowEditModal] = useState(false);
    const sessionStatusMessage = sessionStatusTypeId === OPEN ? 'Open. Teams can be added.' : 'Closed. Teams can not be added.';
    const isTournament = sessionTypeId === TOURNAMENT;
    const totalPlayerCost = parseInt(registrationFee) + parseInt(registrationVerificationFee);
    const dateFormat = `MMM D, YYYY \u2022 h:mm A`;
    const sessionStartDate = getAdjustedDate({ dateUtc: startDateUtc, format: dateFormat });
    const sessionEndDate = getAdjustedDate({ dateUtc: endDateUtc, format: dateFormat });
    const registrationStartDate = getAdjustedDate({ dateUtc: teamRegistrationStartDateUtc, format: dateFormat });
    const registrationEndDate = getAdjustedDate({ dateUtc: teamRegistrationEndDateUtc, format: dateFormat });
    const formatTypeDescription = getTypeForId(sessionMatchTypeId, sessionMatchFormatTypes)?.description;
    const drawTypeDescription = getTypeForId(drawTypeId, drawTypes)?.description;
    const typeDescription = getTypeForId(sessionTypeId, sessionTypes)?.description;

    const handleOnEditClick = () => {
        setShowEditModal(true);
    };

    const handleOnCloseEditModal = () => {
        setShowEditModal(false);
    };

    return (
        <Box maxWidth={800}>
            <Box mb={0.5} display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="black" category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                    General
                </Typography>
                <IconButton onClick={handleOnEditClick} aria-label="Edit session details">
                    <EditIcon color={theme.appColors.interactionBlue} size={16} />
                </IconButton>
            </Box>
            <Paper sx={{ px: 2, pt: 2, pb: 0.5, boxShadow: 'none', borderRadius: 0, mb: 3 }}>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Name
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {name}
                    </Typography>
                </Box>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Type
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {typeDescription}
                    </Typography>
                </Box>
                {isTournament && (
                    <Box mb={1.5}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Draw Type
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {drawTypeDescription}
                        </Typography>
                    </Box>
                )}
                {location && (
                    <Box mb={1.5}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Location
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {location?.formattedAddress}
                        </Typography>
                    </Box>
                )}
                <Box mb={1.5} display="flex" justifyContent="space-between">
                    <Box flex={3}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Start Date
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {sessionStartDate}
                        </Typography>
                    </Box>
                    <Box flex={2}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Session End Date
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {sessionEndDate}
                        </Typography>
                    </Box>
                </Box>
                {description && (
                    <Box mb={1.5}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Description
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {description}
                        </Typography>
                    </Box>
                )}
            </Paper>
            <Box mb={1.5}>
                <Typography color="black" category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                    Team Match Format
                </Typography>
            </Box>
            <Paper sx={{ px: 2, pt: 2, pb: 0.5, boxShadow: 'none', borderRadius: 0, mb: 3 }}>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Format Type
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {formatTypeDescription}
                    </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                {sessionMatchTypeId === SINGLES && (
                    <MatchFormat
                        matchCount={singlesMatchCount}
                        pointsPerMatch={singlesPointsPerMatch}
                        scoringFormatTypeId={singlesScoringFormatTypeId}
                        timeAllowedTypeId={singlesTimeAllowedTypeId}
                        sportTypeId={sportTypeId}
                    />
                )}
                {sessionMatchTypeId === DOUBLES && (
                    <MatchFormat
                        matchCount={doublesMatchCount}
                        pointsPerMatch={doublesPointsPerMatch}
                        scoringFormatTypeId={doublesScoringFormatTypeId}
                        timeAllowedTypeId={doublesTimeAllowedTypeId}
                        sportTypeId={sportTypeId}
                        isDoubles
                    />
                )}
                {sessionMatchTypeId === BOTH && (
                    <>
                        <MatchFormat
                            matchCount={singlesMatchCount}
                            pointsPerMatch={singlesPointsPerMatch}
                            scoringFormatTypeId={singlesScoringFormatTypeId}
                            timeAllowedTypeId={singlesTimeAllowedTypeId}
                            sportTypeId={sportTypeId}
                        />
                        <Divider sx={{ mb: 2 }} />
                        <MatchFormat
                            matchCount={doublesMatchCount}
                            pointsPerMatch={doublesPointsPerMatch}
                            scoringFormatTypeId={doublesScoringFormatTypeId}
                            timeAllowedTypeId={doublesTimeAllowedTypeId}
                            sportTypeId={sportTypeId}
                            isDoubles
                        />
                    </>
                )}
            </Paper>
            <Box mb={1.5}>
                <Typography color="black" category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                    Team Registration
                </Typography>
            </Box>
            <Paper sx={{ p: 2, boxShadow: 'none', borderRadius: 0, mb: 3 }}>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Session Status
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {sessionStatusMessage}
                    </Typography>
                </Box>
                <Box mb={1.5} display="flex" justifyContent="space-between">
                    <Box flex={3}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Registration Start Date
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {registrationStartDate}
                        </Typography>
                    </Box>
                    <Box flex={2}>
                        <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                            Registration End Date
                        </Typography>
                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                            {registrationEndDate}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Max Teams
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {teamsMax}
                    </Typography>
                </Box>
            </Paper>
            <Box mb={1.5}>
                <Typography color="black" category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                    Player Fees
                </Typography>
            </Box>
            <Paper sx={{ p: 2, boxShadow: 'none', borderRadius: 0, mb: 3 }}>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Session player entry fee
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {`$${registrationFee}`}
                    </Typography>
                </Box>
                <Box mb={1.5}>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        League Fee
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {`$${registrationVerificationFee}`}
                    </Typography>
                </Box>
                <Box>
                    <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>
                        Total per player
                    </Typography>
                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                        {`$${totalPlayerCost}`}
                    </Typography>
                </Box>
            </Paper>
            <ManageSessionModal
                title="Update session details"
                sessionDetails={sessionDetails}
                isOpen={showEditModal}
                onClose={handleOnCloseEditModal}
                onSaveClick={handleOnCloseEditModal}
            />
        </Box>
    );
};

DetailsTab.propTypes = {
    ...manageSessionDetailsTabPropTypes,
};

export default DetailsTab;
