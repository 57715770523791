import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';

import ShareTeamModal from '../../components/Modals/ShareTeamModal';
import MemberTeamsTable from '../../components/Tables/MemberTeamsTable';
import { useGetMemberTeamsQuery } from '../../api/leaguesApi';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { SportTypeIds } from '../../utils/types';

const MemberTeamsPage = () => {
    const dispatch = useDispatch();
    const { sportId } = useParams();
    const [showShareModal, setShowShareModal] = useState(false);
    const [sharedTeamId, setSharedTeamId] = useState(null);
    const { data, isFetching, error } = useGetMemberTeamsQuery();
    const teamUrl = `https://leagues.utrsports.net/teams/${sharedTeamId}`;

    if (error) {
        dispatch(showErrorPopup(error));
    }

    const handleOnShareClick = (teamId) => {
        setSharedTeamId(teamId);
        setShowShareModal(true);
    };

    const handleOnCloseShareModal = () => {
        setShowShareModal(false);
    };

    const handleOnShareCopyClick = () => {
        navigator.clipboard.writeText(teamUrl);
        setShowShareModal(false);
    };

    const teamsToDisplay = parseInt(sportId) === SportTypeIds.TENNIS ? data?.memberTeams?.tennis : data?.memberTeams?.pickleball || [];

    return (
        <Container sx={{ mt: 10 }} maxWidth="lg">
            <MemberTeamsTable memberTeams={teamsToDisplay} isLoading={isFetching} onShareClicked={handleOnShareClick} />
            <ShareTeamModal open={showShareModal} url={teamUrl} onClose={handleOnCloseShareModal} onCopyClick={handleOnShareCopyClick} />
        </Container>
    );
};

export default MemberTeamsPage;
