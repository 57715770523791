import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, TeamCard, Button } from '@universal-tennis/ui-shared';
import { teamSelectionProptypes } from '../../../utils/proptypes';

const formatCaptainsString = (captains) => {
    if (!captains) return '';
    return captains.map((captain) => `${captain.firstName} ${captain.lastName}`)
        .map((item, i, array) => {
            if (i === 0) {
                return item;
            }
            if (i === array.length - 1) {
                return ` and ${item}`;
            }
            return ` ${item}`;
        })
        .toString();
};
const TeamSelection = ({ onTeamCardClick, onNotifyOrgClick, teams, canSendSessionJoinRequest }) => {
    if (!teams) {
        return (
            <Box pt={8} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Box>
            <>
                {teams.map((item) => {
                    const getCaptains = formatCaptainsString(item?.captains);
                    const formatDescription = `Captained by ${getCaptains}. ${item.hasRegistrationPin ? 'Requires a passcode for registration.' : ''}`;
                    return (
                        <div key={item.id}>
                            <Box pt={2} />
                            <TeamCard onClick={() => onTeamCardClick(item.id)} title={item.name} description={formatDescription} />
                        </div>
                    );
                })}
                {teams.length === 0 && (
                    <Typography category="secondary" size="small-book">
                        Teams have not been added.  Check back later to register for a team
                    </Typography>
                )}
            </>
            <Box pt={4}>
                <Box pb={2}>
                    <Typography category="secondary" size="large-medium">
                        No Team? No Problem.
                    </Typography>
                </Box>
                <Typography category="secondary" size="small-book">
                    We&apos;ll send a message to the organizer that you are interested in finding a team.
                </Typography>
                <Button disabled={!canSendSessionJoinRequest} onClick={onNotifyOrgClick} category="link">
                    <Typography category="secondary" size="small-book">
                        Notify organizer
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};

TeamSelection.propTypes = {
    ...teamSelectionProptypes
};

export default TeamSelection;
