import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import Location from '../../Form/Location';
import Slider from '../../Form/Slider';
import Select from '../../Form/Select';
import FormSubmitButton from '../../Form/FormSubmitButton';
import PlayerSearch from '../../Form/PlayerSearch';
import PlayerSearchResultCard from '../../Cards/PlayerSearchResultCard';
import { DefaultSliderValues, ageSliderDictionary, getAgeKeyByValue, defaultGridSpacing, formPaperStyles } from '../../../utils/constants';
import { getLocationData } from '../../../utils/location';
import { genderTypes, SportTypeIds, SliderTypes } from '../../../utils/types';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { createConferenceFormPropTypes, defaultCreateConferenceFormPropTypes } from '../../../utils/proptypes';

import { useCreateConferenceMutation, useUpdateConferenceMutation } from '../../../api/leaguesApi';
import { setSportTypeId } from '../../../redux/conferenceDetailsSlice';

const CreateConferenceForm = ({ isManaging, conferenceDetails = null, onConferenceUpdated, isLoadingConference }) => {
    const { utrRange, ageRange, utrpRange } = DefaultSliderValues;
    const { TENNIS } = SportTypeIds;
    const { UTR, UTRP, AGE } = SliderTypes;
    const [createConference] = useCreateConferenceMutation();
    const [updateConference, { isSuccess: isUpdated }] = useUpdateConferenceMutation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const conferenceId = params?.conferenceId;

    const [originalLocation, setOriginalLocation] = useState();
    const [conferenceOrganizer, setConferenceOrganizer] = useState();
    const [leagueId, setLeagueId] = useState();
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const buttonText = isManaging ? 'Save Changes' : 'Create Conference';
    const defaultValues = {
        name: '',
        location: '',
        leagueId: parseInt(params?.leagueId),
        genderTypeId: '',
        details: '',
        ageRange,
        conferenceRatingRange: [],
    };

    const {
        handleSubmit,
        reset,
        formState: { errors, dirtyFields, isSubmitting },
        control,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (sportTypeId) {
            const confRange = sportTypeId === TENNIS ? utrRange : utrpRange;
            reset({ ...defaultValues, conferenceRatingRange: confRange });
        }
    }, []);

    useEffect(() => {
        if (isManaging && conferenceDetails) {
            const {
                maxAge,
                minAge,
                location,
                leagueId: conferenceLeagueId,
                sportTypeId: sportId,
                conferenceRatings,
                ...fetchedConference
            } = conferenceDetails;

            const conferenceLocation = location?.placeName || location?.formattedAddress || '';
            const confAgeRange = [parseInt(getAgeKeyByValue(minAge)), parseInt(getAgeKeyByValue(maxAge))];
            const { ratingMin, ratingMax } = conferenceRatings || {};
            const confRatingRange = [ratingMin, ratingMax];

            const formData = {
                ...fetchedConference,
                location: conferenceLocation,
                ageRange: confAgeRange,
                conferenceRatingRange: confRatingRange,
            };

            setOriginalLocation(location);
            setLeagueId(conferenceLeagueId);
            dispatch(setSportTypeId(sportId));
            reset(formData);
        }
    }, [conferenceDetails]);

    useEffect(() => {
        if (isUpdated) {
            onConferenceUpdated();
        }
    }, [isUpdated]);

    const handleFormSubmit = async (formValues) => {
        const conference = { ...formValues };
        const [minAge, maxAge] = conference.ageRange;
        const [ratingMin, ratingMax] = conference.conferenceRatingRange;

        if (dirtyFields.location) {
            const formattedLocation = await getLocationData(conference.location);
            conference.location = formattedLocation;
        } else {
            conference.location = originalLocation;
        }

        conference.minAge = ageSliderDictionary[minAge];
        conference.maxAge = ageSliderDictionary[maxAge];
        conference.conferenceRatings = {};

        conference.conferenceRatings.ratingMin = ratingMin;
        conference.conferenceRatings.ratingMax = ratingMax;

        delete conference.ageRange;
        delete conference.conferenceRatingRange;

        if (isManaging) {
            try {
                await updateConference({ conferenceId, conference, leagueId }).unwrap();
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        } else {
            conference.conferenceOrganizerMemberId = conferenceOrganizer.memberId;
            try {
                await createConference(conference).unwrap();
                navigate(`/leagues/${params?.leagueId}`);
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }
    };

    const handleOnAddConferenceOrganizer = (organizer) => {
        setConferenceOrganizer(organizer);
    };

    const handleOnRemoveConferenceOrganizer = () => {
        setConferenceOrganizer(null);
    };

    const isTennisConference = sportTypeId === TENNIS;

    return (
        <Container>
            <Grid justifyContent="center" mt={3}>
                {!isManaging && (
                    <Box mb={4}>
                        <Typography variant="h3">Create Conference</Typography>
                        <Divider />
                    </Box>
                )}
                {isManaging && isLoadingConference ? (
                    <LoadingIndicator />
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Paper sx={formPaperStyles}>
                            <Typography variant="h5">Conference Details</Typography>
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <Input
                                        name="name"
                                        label="Conference Name"
                                        hasError={!!errors.name}
                                        isRequired
                                        control={control}
                                        helperText="Required"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Location name="location" label="Add a location" error={!!errors.location} control={control} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        name="details"
                                        label="About the conference"
                                        hasError={!!errors.details}
                                        control={control}
                                        multiline
                                        helperText="This message will appear publicly on your conference page"
                                        minRows={3}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        {!isManaging && (
                            <Paper sx={formPaperStyles}>
                                <Typography variant="h5">Conference Organizer</Typography>
                                <Grid my={2} container spacing={defaultGridSpacing}>
                                    <Grid item xs={12}>
                                        {conferenceOrganizer ? (
                                            <PlayerSearchResultCard
                                                player={conferenceOrganizer}
                                                onRemovePlayer={handleOnRemoveConferenceOrganizer}
                                                showRemoveButton
                                                variant="outlined"
                                                elevation={0}
                                            />
                                        ) : (
                                            <PlayerSearch
                                                onPlayerSelected={handleOnAddConferenceOrganizer}
                                                searchMembersOnly
                                                label="Add Conference Organizer"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        <Paper sx={formPaperStyles}>
                            <Typography variant="h5">Conference Audience</Typography>
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12}>
                                    <Select
                                        name="genderTypeId"
                                        label="Gender"
                                        options={genderTypes}
                                        labelId="gender-label"
                                        isFullWidth
                                        control={control}
                                        hasError={!!errors.genderTypeId}
                                        isRequired
                                        helperText="Required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box px={1}>
                                        <Slider
                                            field="conferenceRatingRange"
                                            sliderType={isTennisConference ? UTR : UTRP}
                                            label={isTennisConference ? 'UTR Rating' : 'UTR-P Rating'}
                                            error={errors.conferenceRatingRange}
                                            control={control}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box px={1}>
                                        <Slider field="ageRange" sliderType={AGE} label="Age" error={errors.ageRange} control={control} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                        <FormSubmitButton label={buttonText} isSubmitting={isSubmitting} />
                    </form>
                )}
            </Grid>
        </Container>
    );
};

CreateConferenceForm.propTypes = {
    ...createConferenceFormPropTypes
};

CreateConferenceForm.defaultProps = {
    ...defaultCreateConferenceFormPropTypes
};

export default CreateConferenceForm;
