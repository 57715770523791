import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';

import { SportTypeIds } from '../../utils/types';
import PlayerUtr from '../PlayerUtr';
import { getPlayerDisplayPickleballRating, getPlayerDisplayUtr } from '../../utils/display';

const PlayerRating = ({ player, isDoubles = false, isAppended = false }) => {
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;

    const { isVerified, rating } = isTennisMatch ? getPlayerDisplayUtr(player, isDoubles) : getPlayerDisplayPickleballRating(player);

    return isTennisMatch ? (
        <PlayerUtr utr={rating} isVerified={isVerified} isAppended={isAppended} />
    ) : (
        <Typography variant={isAppended ? 'cardSubtitle' : 'body1'}>{rating}</Typography>
    );
};

export default PlayerRating;
