/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Icon from '@mui/material/Icon';

import { utrIconPropTypes, deafultUtrIconPropTypes } from '../../../utils/proptypes';

const UtrIcon = (props) => {
    const { icon, size, alt, style, clickable, onClick } = props;
    if (clickable) {
        style.cursor = 'pointer';
    }
    return (
        <Icon style={{ width: size, height: size, overflow: 'unset', fontSize: 'unset', display: 'flex', ...style }} onClick={onClick}>
            <img src={icon} alt={alt} />
        </Icon>
    );
};

UtrIcon.propTypes = {
    ...utrIconPropTypes
};

UtrIcon.defaultProps = {
    ...deafultUtrIconPropTypes
};

export default UtrIcon;
