import moment from 'moment';
import { sessionTypes, openClosedStatusTypes, getTypeForId, LeagueSessionTypes, FrequencyTypes } from './types';
import { getFormattedDate } from './display';

const { TOURNAMENT } = LeagueSessionTypes;

export const getSessionRounds = (schedule) => {
    const { draws } = schedule;

    const mappedDraws = draws?.reduce((acc, cur) => {
        if (cur?.rounds) {
            return [
                ...acc,
                ...cur.rounds
            ];
        }
        return acc;
    }, [])?.map((round) => ({ ...round, matches: round.teamMatches }));

    return mappedDraws;
};

export const getRoundCount = (teamCount, matchesPerTeam) => {
    if (teamCount % 2 === 0) {
        return matchesPerTeam;
    }
    return Math.floor(matchesPerTeam + matchesPerTeam / (teamCount - 1));
};

export const mapSession = (data) => {
    const { startDateUtc, endDateUtc, teamRegistrationEndDateUtc, teamRegistrationStartDateUtc, sessionTypeId, sessionStatusTypeId } = data;
    const dateFormat = 'MMM D';
    const sessionType = getTypeForId(sessionTypeId, sessionTypes);
    const status = getTypeForId(sessionStatusTypeId, openClosedStatusTypes);
    const sessionDates = getFormattedDate(startDateUtc, endDateUtc, dateFormat);
    const registrationDates = getFormattedDate(teamRegistrationStartDateUtc, teamRegistrationEndDateUtc, dateFormat);

    return {
        status: status.description,
        sessionType: sessionType.description,
        sessionDates,
        registrationDates,
        ...data,
    };
};

export const getTooManyMatchesPerTeamError = (session) => {
    const { startDateUtc, endDateUtc, teamMatchFrequencyTypeId, sessionTypeId, matchesPerTeam } = session;
    if (sessionTypeId === TOURNAMENT) return false;
    if (!startDateUtc || !endDateUtc || !teamMatchFrequencyTypeId) return false;

    const numberOfTotalWeeks = moment(endDateUtc).diff(moment(startDateUtc), 'weeks') || 1;
    // teamMatchFrequencyTypeId dynamic in case we want to change to every 3/4 weeks.
    const totalWeeksAvailable =
        teamMatchFrequencyTypeId === FrequencyTypes.ONCE_A_WEEK
            ? numberOfTotalWeeks
            : Math.floor(numberOfTotalWeeks / teamMatchFrequencyTypeId);

    if (!totalWeeksAvailable) {
        return true;
    }
    if (+matchesPerTeam > totalWeeksAvailable) {
        return true;
    }
    return false;
};
