import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';

const parse = require('autosuggest-highlight/parse');

const autocompleteService = { current: null };

const LocationAutoCompleteControlled = ({ value, label, isRequired, onChange, variant = 'outlined' }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const getPlacePredictions = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        getPlacePredictions({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, value]);

    return (
        <Autocomplete
            id="google-map-auto-complete"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={[value, ...options]}
            autoComplete
            value={value}
            disableClearable
            includeInputInList
            filterSelectedOptions
            onChange={(_, newValue) => {
                if (newValue) {
                    onChange(newValue);
                    setOptions(newValue ? [newValue, ...options] : options);
                }
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField variant={variant} {...params} required={isRequired} label={label} fullWidth />}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default LocationAutoCompleteControlled;
