import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { Typography, Button, SwapIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Input from '../../../components/Form/Input';
import Location from '../../../components/Form/Location';
import DateTimePicker from '../../../components/Form/DateTimePicker';
import { getLocationData } from '../../../utils/location';
import { getAdjustedDate } from '../../../utils/display';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';

const MatchEditModal = ({ isOpen, onClose, onSubmit, match, allowTeamSwap = false, isMultiRoundSession, isAttemptingToPostScore = false }) => {
    const theme = useTheme();
    const { PRIMARY, SECONDARY, DISPLAY } = SharedUICategories;
    const { MEDIUM_BOOK } = TypographySizes;
    const [originalLocation, setOriginalLocation] = useState();
    const [originalDescription, setOriginalDescription] = useState();
    const [originalDate, setOriginalDate] = useState();
    const [homeTeam, setHomeTeam] = useState();
    const [awayTeam, setAwayTeam] = useState();
    const defaultValues = { location: '', matchDate: '', description: '' };
    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    const hasUpdatedTeamPositions = homeTeam !== match?.position1Team && awayTeam !== match?.position2Team;
    const isUpdated = dirtyFields?.location || dirtyFields?.matchDate || dirtyFields?.description || hasUpdatedTeamPositions;

    useEffect(() => {
        if (match) {
            let matchDate = '';
            let matchLocation = '';
            let matchDescription = '';

            if (match?.matchDate) {
                matchDate = getAdjustedDate({ dateUtc: match.matchDate });
                setOriginalDate(match.matchDate);
            }

            if (match?.location) {
                matchLocation = match.location?.placeName || match.location?.formattedAddress || '';
                setOriginalLocation(match.location);
            }

            if (match?.description) {
                matchDescription = match.description || '';
                setOriginalDescription(match.description);
            }

            if (match?.position1Team) {
                setHomeTeam(match?.position1Team);
            }

            if (match?.position2Team) {
                setAwayTeam(match?.position2Team);
            }

            reset({ matchDate, location: matchLocation, description: matchDescription });
        }
    }, [match]);

    const handleOnSubmit = async (formValues) => {
        const teamMatch = { ...formValues, teamMatchId: match?.teamMatchId };

        if (dirtyFields.location) {
            teamMatch.location = await getLocationData(formValues.location);
        } else {
            teamMatch.location = originalLocation;
        }

        if (dirtyFields.description) {
            teamMatch.description = formValues.description;
        } else {
            teamMatch.description = originalDescription;
        }

        if (dirtyFields.matchDate) {
            teamMatch.matchDate = teamMatch.matchDate.toISOString();
        } else {
            teamMatch.matchDate = originalDate;
        }

        teamMatch.position1TeamId = homeTeam?.teamId;
        teamMatch.position2TeamId = awayTeam?.teamId;

        onSubmit(teamMatch);
    };

    const handleOnClose = () => {
        onClose();
        if (isUpdated) {
            reset({ matchDate: originalDate, location: originalLocation?.placeName, description: originalDescription });
            setHomeTeam(match?.position1Team);
            setAwayTeam(match?.position2Team);
        }
    };

    const handleOnSwap = () => {
        setHomeTeam(awayTeam);
        setAwayTeam(homeTeam);
    };

    return (
        <Dialog maxWidth="xl" open={isOpen} onClose={handleOnClose}>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <DialogContent>
                    <Typography sx={{ textTransform: 'capitalize', mb: 2 }} category={DISPLAY} size={MEDIUM_BOOK}>
                        {isAttemptingToPostScore ? 'Enter match date' : 'Edit team match'}
                    </Typography>
                    <Box width={500}>
                        {isAttemptingToPostScore && (
                            <Typography mb={2} category={SECONDARY} size={MEDIUM_BOOK}>
                                To enter a score you must first enter a valid match date
                            </Typography>
                        )}
                        <Stack spacing={3}>
                            <DateTimePicker fullwidth name="matchDate" label="Match Date" minutesStep={5} control={control} />
                            {!isAttemptingToPostScore && <Location name="location" label="Match Location" error={!!errors.location} control={control} />}
                            {!isAttemptingToPostScore && <Input name="description" label="Match Location Description" multiline minRows={3} hasError={!!errors.description} control={control} />}
                        </Stack>
                    </Box>
                    {allowTeamSwap && isMultiRoundSession && !isAttemptingToPostScore && (
                        <>
                            <Typography my={2} category={SECONDARY} size={MEDIUM_BOOK}>
                                Teams
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Stack spacing={3} sx={{ flex: 1 }}>
                                    <TextField fullwidth disabled label="Away Team" value={awayTeam?.name} />
                                    <TextField fullwidth disabled label="Home Team" value={homeTeam?.name} />
                                </Stack>
                                <IconButton sx={{ ml: 2 }} onClick={handleOnSwap}>
                                    <SwapIcon size={40} color={theme.appColors.interactionBlue} />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <Button category={SECONDARY} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button category={PRIMARY} disabled={!isUpdated} type="submit">
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default MatchEditModal;
