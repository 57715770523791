import React from 'react';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { multiRoundScheduleTypes } from '../../../utils/types';

const MultiRoundScheduleSelect = (props) => {
    const { scheduleType, disabled, onChange } = props;

    return (
        <FormControl sx={{ width: 300 }}>
            <InputLabel id="schedule-select">Select Schedule View</InputLabel>
            <Select value={scheduleType} disabled={disabled} label="Select Schedule Type" labelId="schedule-select" onChange={onChange}>
                {multiRoundScheduleTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.description}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiRoundScheduleSelect;
